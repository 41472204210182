export const monthsNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const shortWeekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const isFutureOrPast = (dateString) =>
  new Date(dateString) <= new Date().setHours(0, 0, 0, 0) - 1;

export const getCurrentTime = (dateTimeString) => {
  const dateTime = dateTimeString ? new Date(dateTimeString) : new Date();
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const formatDateTime = (dateTimeString, onlyDate) => {
  const dateTime = dateTimeString ? new Date(dateTimeString) : new Date();

  const year = dateTime.getFullYear();
  const month = monthsNames[dateTime.getMonth()];
  const day = dateTime.getDate();
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  const seconds = dateTime.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour time
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${month} ${day}, ${year}`;
  const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

  return onlyDate ? formattedDate : `${formattedDate} at ${formattedTime}`;
};

export function getTimeDifference(startDate, endDate) {
  // Convert both date strings to Date objects.
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds.
  const differenceInMilliseconds = end.getTime() - start.getTime();

  // Convert the difference into minutes, seconds, and hours.
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  let resultStr = "";
  if (differenceInHours > 0) {
    resultStr += `${differenceInHours} hr, `;
  }
  if (differenceInMinutes % 60 > 0) {
    resultStr += `${differenceInMinutes % 60} min, `;
  }
  resultStr += `${differenceInSeconds % 60} sec`;

  return resultStr.includes("NaN") ? null : resultStr;
}

export function elapseFormatter(elapse) {
  let hours = Math.floor(elapse / (1000 * 60 * 60)) + "";
  let min = Math.floor((elapse % (1000 * 60 * 60)) / (1000 * 60)) + "";
  let sec = Math.floor(((elapse % (1000 * 60 * 60)) % (1000 * 60)) / 1000) + "";
  return `${hours.padStart(2, 0)}:${min.padStart(2, 0)}:${sec.padStart(2, 0)}`;
}

export function elapseFormatterClean(elapse) {
  const hours = Math.floor(elapse / (1000 * 60 * 60));
  const min = Math.floor((elapse % (1000 * 60 * 60)) / (1000 * 60));
  const sec = Math.floor(((elapse % (1000 * 60 * 60)) % (1000 * 60)) / 1000);

  let resultStr = "";
  if (hours > 0) {
    resultStr += `${hours} hr, `;
  }
  if (min > 0) {
    resultStr += `${min} min, `;
  }
  if (sec > 0 || (hours === 0 && min === 0)) {
    resultStr += `${sec} sec`;
  }

  return resultStr;
}

import {deduplicateSymptomsToTriage} from "./deduplicateSymptomsToTriage";

export function updateSymptomsToTriage(
  context,
  predictedSymptoms,
  potentialSymptoms
) {
  let symptomsToTriage = [
    ...(context.symptomsToTriage || []),
    ...predictedSymptoms.map((s) => ({symptom: s, status: "qualified"})),
    ...potentialSymptoms.map((s) => ({symptom: s, status: "nonQualified"})),
  ];

  //Deduplicate & prioritize qualified symptoms
  symptomsToTriage = deduplicateSymptomsToTriage(symptomsToTriage);

  //Remove symptoms that are already triaged
  context.triaged?.forEach((t) => {
    const existingIndex = symptomsToTriage.findIndex(
      (item) => item?.symptom === t?.symptom
    );
    if (existingIndex > -1) {
      symptomsToTriage.splice(existingIndex, 1);
    }
  });

  return symptomsToTriage;
}

import React from 'react';

import "./options.css";

const Options = ({ options }) => {
  const markup = options.map((option) => {
    return (
      <button key={option.id} className="option-button" onClick={() => {
        option.handler(option.text);
      }}>
        {option.text}
      </button>
    );
  });

  return <div className="options-container">{markup}</div>;
};

export default Options;

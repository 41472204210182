import React, {useState, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

import {jwtDecode, verifyUser} from "../authRequests";
import {crud} from "../crudRequests";

import Navbar from "../components/Patient.Navbar";
import DateSelect from "../components/DateSelect";

import "react-chatbot-kit/build/main.css";
import "../components/journal_bot/bot.css";

import Chatbot, {
  createChatBotMessage,
  createClientMessage,
} from "react-chatbot-kit";

import ActionProvider from "../components/journal_bot/ActionProvider";
import MessageParser from "../components/journal_bot/MessageParser";
import config from "../components/journal_bot/config";
import Layout from "../components/Layout";
import {Fade} from "@chakra-ui/react";

const Journal = () => {
  const {state} = useLocation();
  const loadedRef = useRef(null);
  const id = state && jwtDecode(state.jwt).id;

  const [oldMessages, setOldMessages] = useState(undefined);
  const [validDates, setValidDates] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);

  const dateToString = (date) => {
    return date.toISOString().slice(0, 10);
  };

  const queryValidDates = (id) => {
    crud(state, [
      {
        db: "blh",
        collection: "journals",
        parameters: [{pid: id}],
        method: "find",
      },
    ]).then((res) => {
      const dates = res.data[0]
        .filter((entry) => {
          return (
            entry.journalEntries.length > 0 ||
            entry.date === dateToString(new Date())
          );
        })
        .map((entry) => entry.date);
      setValidDates(dates);
    });
  };

  const upsertEmptyJournal = async (id, date) => {
    await crud(state, [
      {
        db: "blh",
        collection: "journals",
        parameters: [
          {pid: id, date: date},
          {$setOnInsert: {journalEntries: []}},
          {upsert: true},
        ],
        method: "updateOne",
      },
    ]);
  };

  const loadChatHistory = async (id, date) => {
    const res = await crud(state, [
      {
        db: "blh",
        collection: "journals",
        parameters: [{pid: id, date: date}],
        method: "findOne",
      },
    ]);
    return res.data[0].journalEntries;
  };

  useEffect(() => {
    verifyUser(state ? state.jwt : "", loadedRef);

    const date = new Date();

    upsertEmptyJournal(id, dateToString(date)).then(() => {
      setSelectedDate(date);
      queryValidDates(id);
    });
  }, []);

  useEffect(() => {
    if (selectedDate === undefined) return;

    loadChatHistory(id, dateToString(selectedDate)).then((entries) => {
      if (entries.length === 0) {
        setOldMessages(null);
        return;
      }

      setOldMessages(
        entries.map((entry) => {
          if (entry.author === "bot") return createChatBotMessage(entry.text);
          else return createClientMessage(entry.text);
        })
      );
    });
  }, [selectedDate]);

  // ----- CHATBOT CONFIG -----
  const welcomeMessage = `Hi ${state.patient.fName}, welcome back 
                          to the Brightlight smart journal. 
                          Pick a prompt to start writing!`;
  config.initialMessages =
    selectedDate === undefined ||
    dateToString(selectedDate) !== dateToString(new Date())
      ? []
      : [
          createChatBotMessage(welcomeMessage, {
            widget: "welcomeOptions",
          }),
        ];
  config.state = {
    pid: id,
    state: state,
  };

  // use of localStorage is a bit scuffed but I don't think the validator can access state
  localStorage.setItem("journal_input_lock", "false");
  const validator = (input) => {
    return (
      input.replace(/\s/g, "").length &&
      input.length > 0 &&
      dateToString(selectedDate) === dateToString(new Date()) &&
      localStorage.getItem("journal_input_lock") === "false"
    );
  };

  const loadMessages = () => {
    if (oldMessages === null)
      localStorage.setItem("journal_input_lock", "true");
    return oldMessages;
  };
  // --------------------------

  return (
    <>
      <Layout state={{...state}}>
        <Fade in={state} transition={{enter: {duration: 0.5}}}>
          <div
            ref={loadedRef}
            className="container h-screen w-screen mx-auto"
            style={{opacity: 0}}
          >
            {/* Don't render components unless validDates and oldMessages are defined */}
            {validDates === undefined || oldMessages === undefined ? (
              <></>
            ) : (
              <>
                <DateSelect
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  validDates={validDates}
                />
                <Chatbot
                  config={config}
                  headerText="Brightlight Smart Journal"
                  actionProvider={ActionProvider}
                  messageParser={MessageParser}
                  validator={validator}
                  messageHistory={loadMessages()}
                  key={oldMessages}
                />
              </>
            )}
          </div>
        </Fade>
      </Layout>
    </>
  );
};

export default Journal;

import {Textarea, Box, Text, Stack, Flex} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {CheckIcon} from "@chakra-ui/icons";
import {keysToLetters} from "./helpers/timeFormatters";

export default function Input({context, setContext}) {
  const options = context?.currentMultipleChoice?.options;
  const allowMultiple = context?.currentMultipleChoice?.allowMultiple;
  const [selectedOption, setSelectedOption] = useState();

  const handleCheckBoxClick = (option) => {
    let currentValues = context.currentResponse
      ? context.currentResponse.split(", ")
      : [];
    const isAlreadySelected = currentValues.includes(option);

    if (isAlreadySelected) {
      // Remove the unchecked value
      currentValues = currentValues.filter((val) => val !== option);
    } else {
      // Add the checked value
      currentValues.push(option);
    }

    setContext({
      ...context,
      currentResponse: currentValues.join(", "),
    });
  };

  if (!context?.currentMultipleChoice) {
    return (
      <Textarea
        isRequired
        variant="flushed"
        placeholder="Enter Answer"
        color="gray.500"
        my="5"
        size="lg"
        value={context?.currentResponse || ""}
        onChange={(e) =>
          setContext({...context, currentResponse: e.target.value})
        }
      />
    );
  } else {
    if (!allowMultiple) {
      return (
        <Stack direction="column" my="5" maxH={"220px"} overflowY={"scroll"}>
          {options?.map((option, key) => {
            const optionValue = option?.name || option;
            const isSelected = selectedOption === optionValue;

            return (
              <Box
                key={key}
                py={2}
                px="3"
                border="2px"
                borderColor={isSelected ? "blue.500" : "gray.300"}
                bg={isSelected ? "blue.100" : "gray.50"}
                color={isSelected ? "blue.600" : "gray.500"}
                borderRadius="md"
                cursor="pointer"
                fontSize={"16px"}
                _hover={{bg: isSelected ? "blue.100" : "gray.100"}}
                onClick={() => {
                  setSelectedOption(optionValue);
                  setContext({
                    ...context,
                    currentResponse: optionValue,
                  });
                }}
              >
                {optionValue}
              </Box>
            );
          })}
        </Stack>
      );
    } else {
      return (
        <Stack direction="column" my="5" maxH={"220px"} overflowY={"scroll"}>
          <Text fontSize={"13px"}> Select all that apply: </Text>
          {options?.map((option, key) => {
            const optionValue = option?.name || option;

            const isSelected = context.currentResponse
              ?.split(", ")
              .includes(optionValue);

            return (
              <Box
                position="relative"
                key={key}
                py={2}
                px="3"
                pr="10"
                bg={isSelected ? "blue.500" : "gray.100"}
                color={isSelected ? "white" : "gray.600"}
                borderRadius="md"
                cursor="pointer"
                fontSize={"15px"}
                _hover={{bg: isSelected ? "blue.500" : "gray.200"}}
                onClick={() => handleCheckBoxClick(option)}
              >
                {optionValue}
                {isSelected && (
                  <CheckIcon position="absolute" right="3" top="3" />
                )}
              </Box>
            );
          })}
        </Stack>
      );
    }
  }
}

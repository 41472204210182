import {useRef, useState} from "react";
import StripeCardInfoForm from "../StripeCardInfoForm";
import {MdClose} from "react-icons/md";
import {loadStripe} from "@stripe/stripe-js";
import {pdf} from "@react-pdf/renderer";
import InvRecpDF from "../InvRecPDF";
import {isoToApptDate} from "../../additional_files/helpers";
import {sendInvRecEmail, executePayment, crud} from "../../crudRequests";
import Swal from "sweetalert2";
export default function ({
  dashState,
  dispatch,
  transactionData,
  setExecutePayment,
  setPayments,
  socket,
}) {
  const cardsRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState("");

  console.log(socket);
  let patient = dashState?.patient;
  async function generateCharge() {
    let selectedCard;
    setLoading(true);

    if (Array.isArray(cardsRef.current)) {
      selectedCard =
        cardsRef.current?.find((c) => c?.default) || cardsRef.current?.[0];
    }

    try {
      const stripe = await loadStripe(
        dashState.organization?.stpPublicKey || "Invalid key"
      );

      if (stripe && patient && selectedCard) {
        setLoading(true);

        let updateTransactionBody = {
          ...transactionData,
        };

        let sendInvEmail = async (schState, transaction) => {
          let doctor = null;

          let index = dashState?.doctorsIndexMap?.[transaction.did];
          doctor = dashState?.doctors[index];

          let blob = await pdf(
            <InvRecpDF
              state={schState}
              transaction={transaction}
              doctor={doctor}
              returnDoc
            />
          ).toBlob();
          let arrBuf = await blob.arrayBuffer();
          var base64String = btoa(
            String.fromCharCode.apply(null, new Uint8Array(arrBuf))
          );
          var dataURI = "data:" + blob.type + ";base64," + base64String;

          let {date, time} = isoToApptDate(updateTransactionBody.serviceDate);
          sendInvRecEmail(dashState, {
            invoice: false,
            date,
            time,
            pEmail: updateTransactionBody.pEmail,
            pName: updateTransactionBody.pName,
            dName: updateTransactionBody.dName,
            sender: dashState?.organization?.name,
            attachments: [
              {
                filename: "Appointment Receipt.pdf",
                path: dataURI,
              },
            ],
          });
        };

        const handleServerResponse = async (response) => {
          try {
            if (response.error) {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: response.error + "!",
              });
            } else if (response.requires_action) {
              const {error: errorAction, paymentIntent} =
                await stripe.handleCardAction(
                  response.payment_intent_client_secret
                );
              /// console.log(paymentIntent)
              if (errorAction) {
                setLoading(false);
                //  console.log(errorAction)
                Swal.fire({
                  icon: "error",
                  title: errorAction.message + "!",
                });
              } else {
                const paymentResponset = await executePayment(
                  dashState,
                  updateTransactionBody,
                  patient.pid,
                  null,
                  paymentIntent.id
                );

                handleServerResponse(paymentResponset.data);
              }
            } else {
              if (response?.billing && response?.success) {
                sendInvEmail(dashState, response?.billing);
                dispatch({
                  type: "UPDATE_APPOINTMENT",
                  payload: response?.appointment,
                });
                socket?.current?.emit?.(
                  "update_appt",
                  response?.appointment,
                  {}
                );
                if (dashState.relationship) {
                  await crud(dashState, [
                    {
                      db: dashState.db,
                      collection: "billing",
                      parameters: [
                        {tid: response?.billing.tid},
                        {$set: {rid: dashState.relationship.rid}},
                      ],
                      method: "updateOne",
                    },
                  ]);
                }
                setLoading(false);
                setPayments((prev) =>
                  prev.map((b) => {
                    if (b.tid === response?.billing.tid)
                      return response?.billing;
                    else return b;
                  })
                );
                Swal.fire({
                  title: "Successful payment!",
                  icon: "success",
                }).then((value) => {
                  if (value.isConfirmed) setExecutePayment(null);
                });
              }
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: error.response?.data || error.message,
            });
          }
        };

        const paymentResponset = await executePayment(
          dashState,
          updateTransactionBody,
          patient.pid,
          selectedCard
        );
        handleServerResponse(paymentResponset.data);
      } else {
        setWarning(
          "Please make sure you have a default card to make the payment!"
        );
        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response?.data || error.message,
      });
      setLoading(false);
    }
  }

  return (
    <div
      className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
      style={{backgroundColor: "rgb(0,0,0,0.5)"}}
    >
      <div className="relative h-full overflow-scroll sbar2 p-2  flex justify-center items-start">
        {warning && (
          <Warning
            warning={warning}
            setWarning={setWarning}
            className="show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
          />
        )}
        <MdClose
          className="text-black cursor-pointer text-lg absolute right-4 top-4 "
          onClick={() => {
            setExecutePayment(null);
          }}
        />
        <div className="bg-[#FFF] rounded-t-lg space-y-3">
          <StripeCardInfoForm
            dashState={dashState}
            dashDispatch={dispatch}
            cardsRef={cardsRef}
          />
          <button
            className=" w-full bg-green-600 flex justify-center items-center cursor-pointer text-white text-[1rem]  py-6 px-2 text-center font-semibold disabled:bg-gray-500 disabled:cursor-not-allowed"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              if (!loading) {
                generateCharge();
              }
            }}
          >
            {`Pay ${
              (transactionData.patientAmount ?? transactionData.amount) -
              (transactionData.amountPaidByPatient ?? 0)
            } CAD`}
            {loading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show absolute left-10 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={() => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}

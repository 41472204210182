import { supplementaryCrud } from "../../../crudRequests";

export async function fetchSymptoms(setSymptoms, userId, toast) {
  try {
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [
          {
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "find",
      },
    ]);
    setSymptoms(res.data[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch symptom data",
      description: err?.message || "An error occurred while fetching symptom data.",
      status: "error",
      isClosable: true,
    });
  }
}

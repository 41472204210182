import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {jwtDecode, verifyUser} from "../authRequests";
import {IntakeFunctions} from "../additional_files/intakeFunctions";
import {promptGPT35Turbo} from "../aiRequests";
import {BsInfoCircleFill} from "react-icons/bs";
import Swal from "sweetalert2";
import swal from "sweetalert";

export default function Chatbot(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const loadedRef = useRef(null);
  const loader = <img alt="" src="/images/preloader.gif" className="w-24" />;
  const id = state && jwtDecode(state.jwt).id;

  const [mainQ, setMainQ] = useState(loader);
  const [questionMeaning, setQuestionMeaning] = useState(loader);
  const [acknowledgement, setAcknowledgement] = useState();
  const [runningSymptoms, setRunningSymptoms] = useState();
  const [symptomsQualified, setSymptomsQualified] = useState([]);
  const patientInputRef = useRef(null);
  const [patientInput, setPatientInput] = useState();
  const [patientInputCheckbox, setPatientInputCheckbox] = useState({});
  const [options, setOptions] = useState([]);
  const [chatbotConversation, setChatbotConversation] = useState([]);
  const [textareaDisabled, setTextareaDisabled] = useState();
  const [highRiskAsked, setHighRiskAsked] = useState(false);
  const chatEndRef = useRef(null);

  const [
    logout,
    suicidalRisk,
    populateSymptomsAndDisorders,
    populateQuestionsAndDisorderQuestions,
    allDisorders,
    allSymptoms,
    allDisorderQuestions,
    allQuestions,
    endInstance,
  ] = IntakeFunctions();

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
    chatbotLoad();
    populateSymptomsAndDisorders(state);
    populateQuestionsAndDisorderQuestions(state);
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"}); // Scroll to bottom of chat history
    if (
      chatbotConversation.length >= 3 &&
      chatbotConversation.slice(-3).every((element) => element[1] === "No") // If the last 3 patient inputs are No
    ) {
      let disordersFromLast3Questions = [];
      chatbotConversation.slice(-3).map((cc) => {
        let disorderQuestion = allDisorderQuestions.find(
          (dq) => dq.question == cc[0]
        );
        if (disorderQuestion) {
          // If the question is a qualifier question
          allSymptoms
            .find((s) => s.symId == disorderQuestion.symptom)
            .disorders.forEach((d) => {
              disordersFromLast3Questions.push(d); // Push the disorder associated with the qualifier question to the disordersFromLast3Questions array
            });
        }
      });
      let mode = disordersFromLast3Questions.reduce(
        (acc, val) => (acc[val] = (acc[val] || 0) + 1) && acc,
        {}
      );
      let disorderToInvalidate = Object.keys(mode).filter(
        (key) => mode[key] === Math.max(...Object.values(mode))
      )[0]; // Get the most common disorder out of the disordersFromLast3Questions array as there can be multiple disorders associated with one symptom
      let updatedRunningSymptoms = runningSymptoms.filter((list) => {
        if (list.disId == disorderToInvalidate && list.status == "pending") {
          list.status = "asked-unconfirmed"; // Invalidate the disorder by setting the status of all its pending symptoms to asked-unconfirmed
          return true;
        }
        return true;
      });
      setRunningSymptoms(updatedRunningSymptoms);
      console.log(
        "Invalidated disorder: " +
          disorderToInvalidate +
          " due to No's 3 times previously"
      );
    }
  }, [chatbotConversation]);

  function chatbotLoad() {
    Swal.fire(
      "Welcome",
      "Welcome to this smart assessment as part of your intake. Here, you will be asked to answer questions regarding your main psychosocial problems which your practicitioner will look at before your appointment to get a preliminary understanding of your mental health. <br/><br/>Please make sure to answer in as much detail as possible while being concise as it helps your doctor achieve a fuller understanding. <br/><br/>This chatbot is not meant to trigger, offend or hurt your feelings, rather to understand and relay your condition to your doctor, so please pardon any linguistic blunders.",
      "info"
    ).then((result) => {
      Swal.fire(
        "Disclaimer",
        "The mental health chatbot assessment in this app asks general questions that have been trained by expert practitioners. It does not replace a professional consult. <br/><br/>The creators of this application and the clinic staff whom you are doing this intake for are not liable at all for any consequences to your engagement in this intake and do not guarantee the accuracy of the assessment nor the language it contains. <br/><br/>In case of an emergency, seek immediate assistance from local emergency services or a helpline. Please be aware that the mental health chatbot assessment is not designed or intended to provide crisis intervention or emergency support. <br/><br/><b>By going through this smart AI intake, you agree to these terms.</b>",
        "warning"
      );
    });
    setMainQ(
      "Welcome to the Brightlight medical intake " +
        state.patient.fName +
        "!<br/>Our intelligent algorithms and workflows are designed to collect information on the issues you're facing to better assist your clinician with an evaluation.<br/>To start, please let us know your chief complaint."
    );
    setQuestionMeaning(
      "This question is asking for you to describe your main mental health issue(s) in detail."
    );
    setPatientInput("");
    setTextareaDisabled(false);
  }

  function endQuestions(layer) {
    if (layer == 1) {
      setTextareaDisabled(true);
      setMainQ(
        "Thank you so much for going through this process as we understand it could bring up difficult emotions.<br/>Do you have anything else you'd like to mention before we send this conversation to your doctor and end this interaction?"
      );
      setQuestionMeaning(
        "This question is asking if there is anything else you'd like to bring up before ending the conversation."
      );
      setOptions(["Yes", "No"]);
      setAcknowledgement("");
      setPatientInputCheckbox({});
    } else {
      setPatientInput("");
      setTextareaDisabled(false);
      setMainQ("Okay, please feel free to expand on anything else you'd like.");
      setQuestionMeaning(
        "This question is asking you to expand on anything else you'd like to bring up before ending the conversation."
      );
      setOptions([]);
      setAcknowledgement("");
      setPatientInputCheckbox({});
    }
  }

  function setNextQuestion(
    runningSymptomsLocal,
    symptomAsked,
    acknowledgement
  ) {
    if (runningSymptomsLocal) {
      setPatientInputCheckbox({});
      // If symptoms in running list
      if (
        symptomAsked &&
        !symptomsQualified.find((s) => s.symId == symptomAsked)
      ) {
        let subQuestion = allQuestions.find(
          (q) => q.pathways == "(" + String(symptomAsked) + ")=1"
        ); // Find the symptom-specific question that corresponds to the symptom being asked & set as the main question
        setSymptomsQualified((prevState) => [
          ...prevState,
          {
            symId: symptomAsked,
            description: allSymptoms.find((s) => s.symId == symptomAsked)
              .shortDesc,
            disorders: allSymptoms.find((s) => s.symId == symptomAsked)
              .disorders,
            question: subQuestion
              ? subQuestion.question
              : "No in-depth question present in DB",
          }, // Add the symptom to the symptoms qualified list
        ]);
        if (subQuestion) {
          // If symptom-specific question found
          setMainQ(subQuestion.question);
          setQuestionMeaning(subQuestion.meaning);
          // Make input free response
          setPatientInputCheckbox({});
          setOptions([]);
          setTextareaDisabled(false);
          setPatientInput("");
        } else {
          setNextQuestion(
            runningSymptomsLocal.filter((rsl) => rsl.symId !== symptomAsked)
          ); // If no symptom-specific question found, set the next question generally (next in line qualifier question)
        }
      } else {
        // If symptom has not been qualified yet ask the next qualifier or in depth question
        let questionSet = false;
        runningSymptomsLocal.forEach(function (rs) {
          if (
            questionSet == false &&
            rs.status == "predicted-confirmed" &&
            !symptomsQualified.find((s) => s.symId == rs.symId)
          ) {
            // If symptom has been qualified already skip the qualifier question and go directly into the in depth symptom specific questions
            questionSet = true;
            console.log(rs.symId);
            let subQuestion = allQuestions.find(
              (q) => q.pathways == "(" + String(rs.symId) + ")=1"
            ); // Find the symptom-specific question that corresponds to the symptom being asked & set as the main question
            setSymptomsQualified((prevState) => [
              ...prevState,
              {
                symId: rs.symId,
                description: allSymptoms.find((s) => s.symId == rs.symId)
                  .shortDesc,
                disorders: allSymptoms.find((s) => s.symId == rs.symId)
                  .disorders,
                question: subQuestion
                  ? subQuestion.question
                  : "No in-depth question present in DB",
              }, // Add the symptom to the symptoms qualified list (adds it after it has been predicted and in-depth is being asked as opposed to the above which adds it after just the qualifier has been asked, just so we know if the in-depth for it has been asked or not by doing the !runningsymptoms.find above)
            ]);
            console.log(symptomsQualified);
            if (subQuestion) {
              // If symptom-specific question found
              setAcknowledgement(acknowledgement);
              setMainQ(subQuestion.question);
              setQuestionMeaning(subQuestion.meaning);
              // Make input free response
              setPatientInputCheckbox({});
              setOptions([]);
              setTextareaDisabled(false);
              setPatientInput("");
            } else {
              setNextQuestion(
                runningSymptomsLocal.filter((rsl) => rsl.symId !== rs.symId)
              ); // If no symptom-specific question found, set the next question generally (next in line qualifier question)
            }
          }
        });
        if (questionSet == false) {
          // If no pre-qualified symptoms detected are left ask the next qualifier question
          runningSymptomsLocal.forEach(function (rs) {
            if (questionSet == false && rs.status == "pending") {
              // Find the next symptom that has not been qualified yet and ask the qualifier question for it
              questionSet = true;
              let subQuestion = allDisorderQuestions.find(
                (dq) => dq.symptom == rs.symId
              );
              setAcknowledgement(acknowledgement);
              setMainQ(subQuestion.question); // Find the qualifier question that corresponds to the symptom being asked & set as the main question
              setQuestionMeaning(subQuestion.meaning);
              // Make input Yes/No/Sometimes
              setOptions(["Yes", "No", "Sometimes"]);
              setPatientInputCheckbox({});
              setTextareaDisabled(true);
              return;
            }
          });
          if (questionSet == false) {
            // All symptoms have been qualified
            endQuestions(1);
          }
        }
      }
    } else {
      // No symptoms detected in the past or now
      setMainQ(
        "Apologies, no mental health issues were detected from your response. Is it possible for you to reword your issues in a more direct way?"
      ); // Giving a reprompt for the patient to reword their issues
      setQuestionMeaning(
        "This question is asking for you to reword your issues in a more direct way."
      );
      setTextareaDisabled(false);
    }
  }

  async function processPatientInput() {
    if (mainQ.includes("Thank you so much for going through this process")) {
      if (patientInputCheckbox[Object.keys(patientInputCheckbox)[0]] == "Yes") {
        endQuestions(2);
      } else {
        endInstance(
          swal,
          state,
          navigate,
          chatbotConversation,
          symptomsQualified
        );
      }
    } else {
      // Setting question for Vicuna & replacing the chatbot one to be the load icon
      let oldMainQ = mainQ;
      /* let questionForPrompt =
    mainQ.includes("Welcome to the Brightlight medical intake") ||
    mainQ.includes(
      "Apologies, no mental health issues were detected from your response"
    )
      ? "How are you feeling?"
      : mainQ; */
      let questionForPrompt = "How are you feeling?";
      let patientResponse = patientInputCheckbox[
        Object.keys(patientInputCheckbox)[0]
      ]
        ? "checkbox-" +
          patientInputCheckbox[Object.keys(patientInputCheckbox)[0]]
        : patientInput;
      if (patientResponse.trim() == "") {
        swal("No Input", "Please enter in a response.", "error");
      } else {
        // Main function that executes after valid patient input

        const d = new Date();
        // Updating chatbot conversation history
        setChatbotConversation((prevCC) => [
          ...prevCC,
          [
            mainQ,
            patientInputCheckbox[Object.keys(patientInputCheckbox)[0]]
              ? patientInputCheckbox[Object.keys(patientInputCheckbox)[0]]
              : patientInput,
            d.getHours() + ":" + String(d.getMinutes()).padStart(2, "0"),
          ],
        ]);

        setMainQ(loader);
        setQuestionMeaning("");
        setAcknowledgement("");
        setPatientInput("");
        setOptions([]);
        setPatientInputCheckbox({});
        setTextareaDisabled(true);
        if (
          patientResponse.split("-")[0] == "checkbox" // If the patient input is a checkbox - assumes its a qualifier question
        ) {
          // Gets the symptom being screened for based on the question asked
          let symptomBeingScreened = allDisorderQuestions.find(
            (dq) => dq.question == oldMainQ
          ).symptom;
          // Updates the status of the symptom being screened for based on the patient input checkbox response (Yes, No, Sometimes)
          let updatedRunningSymptoms = runningSymptoms.filter((list) => {
            if (list.symId == symptomBeingScreened) {
              list.status =
                patientResponse.split("-")[1] == "Yes" ||
                patientResponse.split("-")[1] == "Sometimes"
                  ? "asked-confirmed"
                  : "asked-unconfirmed";
              return true;
            }
            return true;
          });
          console.log(updatedRunningSymptoms);
          setRunningSymptoms(updatedRunningSymptoms);
          patientResponse.split("-")[1] != "No"
            ? setNextQuestion(updatedRunningSymptoms, symptomBeingScreened) // If the patient input checkbox response is Yes or Sometimes, set the next question specifically for the symptom being screened for (more in depth symptom-specific questions)
            : setNextQuestion(updatedRunningSymptoms); // If the patient input checkbox response is No, set the next question generally (next in line qualifier question)
        } else if (patientResponse.split("-")[0] != "checkbox") {
          let acknowledgementPrompt =
            'If a patient was asked "' +
            oldMainQ +
            '" and answered "' +
            patientResponse +
            '", generate one sentence that is an appropriate, empathetic and comforting acknowledgement for them along the lines of "I understand concentration issues must be troubling to deal with.".';
          promptGPT35Turbo(
            "You are a comforting healthcare provider.",
            acknowledgementPrompt
          ).then((acknowledgement) => {
            // If the patient input is a text input
            let disorderPrompt =
              'If a patient said "' +
              patientResponse +
              '" in response to "' +
              questionForPrompt +
              '", which of the following possible differentials would a psychologist certainly screen them for: ' +
              allDisorders
                .map((q, i) => (q.disId != "nda" ? q.name.trim() : ""))
                .filter((n) => n)
                .join(", ") +
              ', Please provide the answer in the format of "The psychologist would certainly screen them for x Disorder and y Disorder" that is a 100% certain, Only the most relevant disorder(s), If the patient has said something positive or neutral then answer "This patient doesnt need to be screened for a mental health condition".';
            // Prompting Vicuna to predict the disorder present in the patient text input
            promptGPT35Turbo(
              "You are a mental health diagnostic aid.",
              disorderPrompt
            ).then((disorderResponse) => {
              if (
                // If "Suicidal/Suicide Ideation" present in disorder string
                disorderResponse.toLowerCase().includes("suicid") &&
                !highRiskAsked
              ) {
                // Show high risk triage screen
                suicidalRisk(id, state, setHighRiskAsked);
                setHighRiskAsked(true);
              }
              let disorderSymptomsCaught = []; // Main variable for storing caught symptoms with corresponding status of confirmed (patient clearly indicates it) or pending (symptoms inferred by disorder caught)

              let disorderCounter = 0; // Number of disorders
              let disorderCounterSub = 0;
              allDisorders.map(
                // Counting disorders
                (d, i) =>
                  (disorderCounter = disorderResponse
                    .replace(/\s+/g, " ")
                    .includes(d.name)
                    ? disorderCounter + 1
                    : disorderCounter)
              );
              let perDisoderRunningSymptoms = runningSymptoms
                ? runningSymptoms
                : [];
              disorderCounter < 7 && disorderCounter != 0 // If less than 5 disorders detected (Vicuna sometimes bugs out and spits out all disorders and skips out on doing proper detection)
                ? allDisorders.forEach(function (d) {
                    if (
                      disorderResponse.replace(/\s+/g, " ").includes(d.name)
                    ) {
                      // For every disorder detected
                      console.log("Scanning symptoms for: " + d.name);

                      let symptomPrompt =
                        'If a patient said "' +
                        patientResponse +
                        '" in response to "' +
                        questionForPrompt +
                        '", which of the following symptoms is the patient is likely dealing with that directly matches what they answered?: ' +
                        allSymptoms
                          .map((q, i) =>
                            q.disorders.includes(d.disId)
                              ? "S." + q.symId + ") " + q.shortDesc.trim()
                              : ""
                          )
                          .filter((n) => n)
                          .join(", ") +
                        ', Please provide the answer in the format of "The patient states that: symptom (x), symptom (y)", Only output symptom(s) that are 100% obvious and directly match what the patient says no assumptions otherwise answer "No symptoms can be directly detected in this patient input", and Only output one sentence with the most accurate and directly obvious symptom(s).';
                      // Prompting Vicuna to predict the current-disorder specific symptoms present in the patient text input
                      promptGPT35Turbo(
                        "You are a mental health diagnostic aid.",
                        symptomPrompt
                      ).then((symptomResponse) => {
                        if (
                          // If high risk symptoms "S.23" or "S.31" are present in symptom string
                          (symptomResponse.includes("S.23") ||
                            symptomResponse.includes("S.31")) &&
                          !highRiskAsked &&
                          !disorderResponse.toLowerCase().includes("suicid")
                        ) {
                          // Show high risk triage screen
                          suicidalRisk(id, state, setHighRiskAsked);
                          setHighRiskAsked(true);
                        }
                        allSymptoms
                          .map((s, i) => {
                            let ups =
                              runningSymptoms &&
                              runningSymptoms.find(
                                (ups) =>
                                  ups.disId == d.disId &&
                                  ups.symId == s.symId &&
                                  ups.status != "pending"
                              ); // Checking if the current symptom has status "predicted-confirmed", "asked-confirmed" or "asked-unconfirmed"
                            s.disorders.includes(d.disId) && // Going through all caught symptoms and appending them to the disorderSymptomsCaught variable
                              disorderSymptomsCaught.push({
                                disId: d.disId,
                                symId: s.symId,
                                status: ups
                                  ? ups.status // Retaining the status of the symptom if it was already predicted-confirmed or asked-confirmed
                                  : symptomResponse.includes("S." + s.symId) &&
                                    (symptomResponse.match(/S./g) || [])
                                      .length < 5 // If the symptom is detected in the patient input and less than 5 symptoms detected (Vicuna sometimes bugs out and spits out all symptoms and skips out on doing proper detection)
                                  ? "predicted-confirmed" // This skips the qualifier question for the symptom and goes directly into the in depth symptom-specific questions
                                  : "pending", // This asks the qualifier question for the symptom
                              });
                            //disorderSymptomsCaught looks like [{disId: "mdd", symId: "24", status: "predicted-confirmed"}, ...]
                          })
                          .filter((n) => n);
                        if (
                          runningSymptoms ||
                          disorderSymptomsCaught.length != 0
                        ) {
                          // If symptoms detected in the past or now
                          perDisoderRunningSymptoms = runningSymptoms
                            ? [
                                ...new Map(
                                  perDisoderRunningSymptoms
                                    .concat(disorderSymptomsCaught)
                                    .sort((a, b) => {
                                      // If entry with same symptom id and disorder id but different status already exists prioritize a) asked-unconfirmed b) asked-confirmed c) predicted-confirmed & d) pending
                                      if (a.status === "pending") return -1;
                                      if (b.status === "pending") return 1;
                                      if (a.status === "asked-unconfirmed")
                                        return -1;
                                      if (b.status === "asked-unconfirmed")
                                        return 1;
                                      return 0;
                                    })
                                    .map((obj, i) => [
                                      `${obj.disId}-${obj.symId}`, // Makes sure there are no duplicates in running symptoms
                                      obj,
                                    ])
                                ).values(),
                              ]
                            : disorderSymptomsCaught; // If no symptoms detected in the past, set running symptoms to the new symptoms caught
                          setRunningSymptoms(perDisoderRunningSymptoms);
                          console.log(perDisoderRunningSymptoms);
                        }
                        disorderCounterSub = disorderCounterSub + 1;
                        disorderCounter === disorderCounterSub &&
                          setNextQuestion(
                            perDisoderRunningSymptoms,
                            null,
                            acknowledgement.replace('"', "")
                          ); // Setting the next question if this is the last disorder
                      });
                    }
                  })
                : setNextQuestion(runningSymptoms); // If more than 5 disorders detected (bugged output), skip the symptom detection and set the next question generally (next in line qualifier question)
            });
          });
        }
      }
    }
  }

  return (
    <div
      ref={loadedRef}
      className="container w-max h-screen mx-auto"
      style={{opacity: 0}}
    >
      <div
        id="no_phone"
        className="hidden text-med m-7 bg-light bg-opacity-50 p-7 rounded-md font-medium text-xl w-screen"
      >
        Please make sure to download the Brightlight Health mobile app for the
        best chatbot experience for this screen size. <br />
        <br />
        Alternatively, you can access the chatbot intake from this page on a
        larger screen such as a laptop or desktop.
      </div>
      <div className="float-left top-4">
        {/* <div
          onClick={() => {
            endQuestions(1);
          }}
          className="cursor-pointer text-med text-[12px] -ml-24 mt-6"
        >
          End
          <br />
          Session
        </div>*/}
      </div>
      <div
        className="absolute top-4 right-4 bg-light bg-opacity-25 opacity-75 p-2.5 rounded-md cursor-pointer"
        onClick={() => logout(chatbotConversation)}
      >
        <img src="/images/login.png" alt="" className="w-7 " />
      </div>
      <div id="upper" className="bg-light bg-opacity-20 rounded-b-3xl">
        <div className="h-2 bg-dark"></div>
        <div className="p-4">
          <img src="/images/ai_chatbot.png" className="h-12 py-2 mb-6" />
          <div
            id="chat_history"
            className="responsive-height overflow-y-scroll"
          >
            {chatbotConversation.length != 0 ? (
              chatbotConversation.map(function (cc, i) {
                return (
                  <div className="past_interactions" key={i}>
                    <div className="mb-4 px-2">
                      <div className="flex">
                        <img
                          src="/images/chat_logo.png"
                          className="h-7 mt-2 mr-3 drop-shadow inline-block float-left"
                        />
                        <div className="mt-2 inline-block py-2 px-3 bg-light text-background rounded-md text-left mr-24 float-left chat_q">
                          {typeof cc[0] === "string" || cc[0] instanceof String
                            ? cc[0].split("<br/>").map((qLine, i) => {
                                return <div className="mt-1 mb-1">{qLine}</div>;
                              })
                            : cc[0]}
                        </div>
                      </div>
                      <div className="mt-2 py-2 px-3 bg-med text-background rounded-md text-right float-right inline-block mb-7 chat_a">
                        <div className="text-off -mt-7 text-right you">
                          You <span className="you_time">{cc[2]}</span>
                        </div>
                        <div className="mt-2.5">
                          {cc[1].length
                            ? cc[1]
                            : Object.keys(cc[1]).map((k, i) => {
                                return Object.keys(cc[1]).indexOf(k) !=
                                  Object.keys(cc[1]).length - 1
                                  ? cc[1][k] + ", "
                                  : cc[1][k];
                              })}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />{" "}
                    {cc[1].length && cc[1].split("").length > 125 && (
                      <>
                        <br />
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="mb-4 px-2 text-center text-sm text-med">
                Enter in a response to see a record of your chat here.
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
        </div>
      </div>
      <div
        id="lower"
        className="absolute bg-med bg-opacity-40 rounded-t-3xl"
        style={{bottom: "0"}}
      >
        <div className="h-64 align-middle grid place-items-center">
          {mainQ.toString().split("").length < 160 && (
            <img
              alt=""
              src="/images/main_logo.png"
              className={`mb-2 chat_logo ${acknowledgement && `w-12 h-7 mb-2`}`}
            />
          )}
          <div
            className={`mx-12 ${
              mainQ.toString().split("").length < 160 && `-mt-16`
            } text-dark opacity-50 font-semibold main_q`}
          >
            {typeof mainQ === "string" || mainQ instanceof String ? (
              <>
                {acknowledgement && (
                  <span className={`mt-1 mb-1 inline-block`}>
                    {acknowledgement} &nbsp;
                  </span>
                )}
                {mainQ.split("<br/>").map((qLine, i) => {
                  return (
                    <span
                      className={`mt-1 mb-1 ${
                        mainQ.split("<br/>").length != i + 1 && `inline-block`
                      }`}
                    >
                      {qLine}{" "}
                    </span>
                  );
                })}
                <BsInfoCircleFill
                  className="inline ml-2 -mt-0.5 text-dark text-opacity-100 cursor-pointer"
                  size={14}
                  onClick={() =>
                    Swal.fire(
                      "What does this mean?",
                      questionMeaning
                        ? questionMeaning
                        : "Sorry this information is unavailable at this time."
                    )
                  }
                />
              </>
            ) : (
              mainQ
            )}
          </div>
        </div>
        {options.length === 0 ? (
          <span>
            <div className="bg-dark pt-4 pb-1">
              <textarea
                ref={patientInputRef}
                value={patientInput}
                onChange={() => setPatientInput(patientInputRef.current.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && processPatientInput();
                }}
                className="w-4/5 inline-block bg-dark text-light p-0 patient_input"
                style={{
                  border: "0",
                  outline: "0",
                  borderBottom: "1px solid #FCFCFF",
                  resize: "none",
                }}
                disabled={textareaDisabled}
              />
              <img
                alt="Send"
                src="/images/send.png"
                className="float-right cursor-pointer send_button_ff"
                onClick={processPatientInput}
              />
            </div>
          </span>
        ) : (
          <span>
            <div className="bg-dark pt-7 pb-3 px-8 width-full text-center">
              {options.map(function (op, j) {
                return (
                  <div className="mr-4 text-light h-10 inline-block" key={j}>
                    <input
                      type="checkbox"
                      className="hidden"
                      id={`option-${j + 1}`}
                      value={op}
                      name="options"
                      onChange={(e) =>
                        setPatientInputCheckbox({[e.target.id]: e.target.value})
                      }
                    />
                    <label
                      htmlFor={`option-${j + 1}`}
                      className={`option ${
                        patientInputCheckbox[
                          Object.keys(patientInputCheckbox)[0]
                        ] == op && `option_selected`
                      }`}
                    >
                      &nbsp;
                      {op}
                    </label>
                  </div>
                );
              })}
              <img
                alt=""
                src="/images/send.png"
                className="absolute text-right cursor-pointer send_button_i"
                onClick={processPatientInput}
              />
            </div>
          </span>
        )}
      </div>
    </div>
  );
}

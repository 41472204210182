import React, { useState, useEffect } from "react";

import { getBotResponse, saveUserMsg, saveBotMsg } from "../../journalRequests.js";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [pageState, setPageState] = useState(null);

  useEffect(() => {
    setState(prev => {
      // the following line causes a React warning "Cannot update a component while rendering a different component",
      // but I cannot think of another workaround since I don't think I can access state directly here
      setPageState(prev.state);
      return prev;
    });
  }, [setState]);

  const saveUserMessage = async (text, timestamp) => {
    await saveUserMsg(pageState, new Date().toISOString().slice(0, 10), text, timestamp);
  };

  const saveBotMessage = (text) => {
    saveBotMsg(pageState, new Date().toISOString().slice(0, 10), text, Date.now());
  };

  // handler code
  const handleWelcome = (text) => {
    const botMessage = createChatBotMessage(text);
    saveBotMessage(text);

    setState(prev => {
      return {
        ...prev,
        messages: [botMessage]
      };
    });

    localStorage.setItem('journal_input_lock', 'false');
  };

  const awaitResponse = () => {
    setState(prev => {
      return {
        ...prev,
        messages: [...prev.messages, createChatBotMessage("Thinking...")],
      };
    });
  };

  const processResponse = (text) => {
    saveBotMessage(text);

    setState(prev => {
      return {
        ...prev,
        messages: [...prev.messages, createChatBotMessage(text)].filter(message => {
          return !(
            (message.type === "bot" && message.message === "Thinking...")
          );
        }),
      };
    });
  };

  const triggerResponse = () => {
    getBotResponse(pageState, new Date().toISOString().slice(0, 10)).then((res) => {
      console.log(res);
      processResponse(res);
    });
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: {
            handleWelcome,
            saveUserMessage,
            awaitResponse,
            triggerResponse,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;

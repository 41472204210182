import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";
import Table from "./Table";
import Signature from "./Signature";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Summary from "./Summary";
export default function ({
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  state,
  type,
  returnDoc,
  receiptAmount,
}) {
  return (
    <View style={{paddingHorizontal: 0}}>
      <Section1 type={type} transaction={transaction} />
      <Section2 type={type} tpp={tpp} patient={patient} />
      {/*<Section3 doctor={doctor} patient={patient} />*/}

      <Table
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
      />
      <Summary
        transaction={transaction}
        type={type}
        receiptAmount={receiptAmount}
      />
      <Signature doctor={doctor} supervisor={supervisor} />
    </View>
  );
}

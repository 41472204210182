import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  const p = window.location.pathname;
  const state = props.state;
  const navigate = useNavigate();

  return (
    <div className="fixed z-[30] float-left h-screen w-28 bg-[#9390FF] rounded-br-2xl" style={{ borderTopRightRadius: "75px" }}>
      <div className="mt-16 justify-center grid">
        <img
          alt=""
          src="/images/logo_small.png"
          className="w-20 mb-12 cursor-pointer"
          onClick={() => {
            navigate("../", {
              state: {
                ...state,
              },
            });
          }}
        />
        {p === "/intake" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../chatbot", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/intake_selected.png" className="w-20" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../chatbot", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/intake.png" className="w-12" />
            <span className="text-light font-medium text-[11px] text-center mt-2">Intake</span>
          </div>
        )}

        {p === "/forms" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../forms", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/forms_selected.png" className="w-20 ml-1" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../forms", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/forms.png" className="w-10" />
            <span className="text-light font-medium text-[11px] text-center mt-2">Forms</span>
          </div>
        )}

        {p === "/journal" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../journal", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/journal_selected.png" className="w-20 ml-1" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../journal", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/journal.png" className="w-10" />
            <span className="text-light font-medium text-[11px] text-center mt-2">Journal</span>
          </div>
        )}

        {p === "/inbox" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../inbox", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/inbox_selected.png" className="w-20 ml-1" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../inbox", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/inbox.png" className="w-10" />
            <span className="text-light font-medium text-[11px] text-center mt-3">Inbox</span>
          </div>
        )}

        {p === "/user" ? (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../user", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/user_selected.png" className="w-20 ml-1.5" />
          </div>
        ) : (
          <div
            className="mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../user", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/user.png" className="w-10 ml-1" />
            <span className="text-light font-medium text-[11px] text-center">
              User <span style={{ fontSize: "9px" }}>(you)</span>
            </span>
          </div>
        )}

        {p === "/sleepTracker" ? (
          <div
            className="hidden mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../sleepTracker", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/sleep_selected.png" className="w-[4.5rem]" />
          </div>
        ) : (
          <div
            className="hidden mb-6 justify-center grid cursor-pointer"
            onClick={() => {
              navigate("../sleepTracker", {
                state: {
                  ...state,
                },
              });
            }}
          >
            <img src="/images/sleep.png" className="w-16 -mt-2" />
            <span className="text-light font-medium text-[11px] text-center ">Sleep Diary</span>
          </div>
        )}
      </div>
    </div>
  );
}

import { v4 as uuidv4 } from "uuid";

export function apptStatus(appt) {
  let statusMap = {
    noShow: "No Show",
    cancelled: "Cancelled",
    pConfirmed: "Confirmed",
    rescheduled: "Rescheduled",
  };

  for (let [key, val] of Object.entries(statusMap)) {
    if (appt[key]) return val;
  }
  return "Unconfirmed";
}

export function notificationsData(userType, ntfType, payload) {
  let ntfId = uuidv4();

  const ntfFormater = (ntfType) => {
    switch (ntfType) {
      case "Updated appointment":
      case "New appointment": {
        return {
          ntfId,
          class: "appointment",
          type: ntfType,
          pName: payload.pName,
          dName: payload.dName,
          status: apptStatus(payload),
          creationDate: new Date(),
          apptDate: payload.ISOdate,
          read: false,
        };
      }
      case "New chatbot intake": {
        return {
          class: "chatbot",
          type: "New chatbot intake",
          pName: payload.pName,
          creationDate: new Date(),
          read: false,
        };
      }
      
      case "New Message": {
        return {
          ntfId,
          class: "message",
          type: "New Message",
          creationDate: new Date(),
          read: false,
          ...payload
        };
      }

      case "Form Completed": {
        return {
          ntfId,
          class: "Form",
          type: ntfType,
          pName: payload.pName,
          completedDate: new Date(),
          formTitle:payload.formTitle,
          read: false,    
        };}

      default:
        {
        }
        break;
    }
  };


  return ntfFormater(ntfType);
  
 
}

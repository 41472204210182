export default function clientInfo(){
    var userAgent = navigator.userAgent;


var browserInfo = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
var osInfo = userAgent.match(/(android|webos|iphone|ipad|ipod|blackberry|windows phone|windows|mac|linux)/i) || [];


var browserName = browserInfo[1];
var browserVersion = browserInfo[2];

var os = osInfo[0];
var device = '';

if (/(ipod|ipad|iphone)/i.test(userAgent)) {
  device = 'Apple device';
} else if (/android/i.test(userAgent)) {
  device = 'Android device';
} else if (/(windows phone|windows nt|win64|win32)/i.test(userAgent)) {
  device = 'Windows device';
} else if (/linux/i.test(userAgent)) {
  device = 'Linux device';
} else if (/mac/i.test(userAgent)) {
  device = 'Mac device';
}


return `${browserName} (${os})`
}
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  forwardRef,
} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {AiOutlinePlus} from "react-icons/ai";
import {FaTimes} from "react-icons/fa";
import Navbar from "../components/Patient.Navbar";
import {verifyUser, logoutUser} from "../authRequests";
/* import InstanceBar from "../components/Clinic.InstanceBar"; */
import {patientReducer, init} from "../additional_files/reducer";
import {loadDataForPatientLogin, crud} from "../crudRequests";

import {GiProgression} from "react-icons/gi";
import {HiOutlineClock} from "react-icons/hi";
import {FaBed, FaStopwatch} from "react-icons/fa";
import Notifications from "../components/Patient.Notifications";

import {addDays} from "date-fns";
import {
  calculateTimeDifference,
  convertMinutesToHM,
} from "../additional_files/helpers";
import {
  RangeTypeSelector,
  RangeSelector,
  SleepTrackerCard,
  SleepTimeChart,
  SleepAwakeningsChart,
  SleepOnsetChart,
  SleepEfficiencyChart,
  SleepHorizontalStackedBarChart,
  SleepTrackerInput,
} from "../components/Patient.SleepTracker";

export default function SleepTrackerByPatient(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const loadedRef = useRef(null);
  const [loading, setLoading] = useState();
  const [schState, dispatch] = useReducer(patientReducer, state, init);
  const sessionStart = useRef(true);

  let {pid} = state.patient;
  const inputModalRef = useRef(null);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
    if (state.db)
      crud({jwt: state.jwt}, [
        {
          db: state.db,
          collection: "sleep_diary",
          parameters: [{pid}],
          method: "find",
        },
      ]).then((res) => {
        setSleepData(res.data[0]);
      });
  }, []);

  useEffect(() => {
    if (!sessionStart.current) dispatch({type: "UPDATE_STATE", payload: state});
  }, [state, dispatch]);

  useEffect(() => {
    ///console.log(state, 'EN FETCH')
    const abortController = new AbortController();

    if (!sessionStart.current) {
      dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});

      loadDataForPatientLogin(
        {
          ...schState,
          destination: "../sleepTrackerByPatient/",
        },
        navigate,
        null,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
      dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
    };
  }, [schState.selectedLocation, dispatch]);

  const [rangeType, setRangeType] = React.useState("week");
  const [startDate, setStartDate] = React.useState(
    rangeType === "week" ? addDays(new Date(), -6) : addDays(new Date(), -29)
  );
  const [endDate, setEndDate] = React.useState(new Date());

  const [sleepEfficiency, setSleepEfficiency] = React.useState({
    data: {},
    average: 0,
  });
  const [sleepOnsets, setSleepOnsets] = React.useState({
    data: {},
    total: 0,
    average: 0,
  });
  const [sleepTimeInMins, setSleepTimeInMins] = React.useState({
    data: {},
    total: 0,
    average: 0,
  });
  const [sleepAwakeningsInMins, setSleepAwakeningsInMins] = React.useState({
    data: {},
    total: 0,
    average: 0,
  });

  const [sleepData, setSleepData] = React.useState([]);
  const [sleepDataByRange, setSleepDataByRange] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const sleepEfficiency = {data: {}, average: 0};
    const sleepOnsets = {data: {}, total: 0, average: 0};
    const sleepTimeInMins = {data: {}, total: 0, average: 0};
    const sleepAwakeningsInMins = {data: {}, total: 0, average: 0};

    // Filter the sleep data based on the selected range and sort it by date (ascending)
    const filteredSleepData = sleepData
      .filter((data) => {
        const date = new Date(data.day);
        return date >= startDate && date <= endDate;
      })
      .sort((a, b) => new Date(a.day) - new Date(b.day));

    for (let i = 0; i < filteredSleepData.length; i++) {
      const sleepReport = sleepData[i];

      // Calculate the total time for all activities (in mins)
      // ====================================================
      let totalTimeForActivities = 0;
      for (let y = 0; y < sleepReport.activities.length; y++) {
        const activity = sleepReport.activities[y];

        // Calculate the time taken for the activity (in mins)
        const timeDifferenceForActivity = calculateTimeDifference(
          activity.start_time,
          activity.end_time
        );
        const totalActivityTimeInMins =
          timeDifferenceForActivity[0] * 60 + timeDifferenceForActivity[1];

        // Add the time taken for the activity to the total time for all activities
        totalTimeForActivities += totalActivityTimeInMins;
      }

      // ====================================================

      // Calculate the total time for sleep (in mins) —— (time_asleep - total time for all activities)
      // ====================================================
      const timeDifferenceForSleep = calculateTimeDifference(
        sleepReport.time_asleep,
        sleepReport.time_awake
      );
      const totalSleepTimeInMins =
        timeDifferenceForSleep[0] * 60 + timeDifferenceForSleep[1];

      // ====================================================

      // Calculate the sleep onset time (in mins) —— (time_asleep - time_inbed) + (time_outofbed - time_awake)
      // ====================================================
      const timeDifferenceForSleepOnsetToSleep = calculateTimeDifference(
        sleepReport.time_inbed,
        sleepReport.time_asleep
      );
      const totalSleepOnsetToSleepTimeInMins =
        timeDifferenceForSleepOnsetToSleep[0] * 60 +
        timeDifferenceForSleepOnsetToSleep[1];

      const timeDifferenceForSleepOnsetToAwake = calculateTimeDifference(
        sleepReport.time_awake,
        sleepReport.time_outofbed
      );
      const totalSleepOnsetToAwakeTimeInMins =
        timeDifferenceForSleepOnsetToAwake[0] * 60 +
        timeDifferenceForSleepOnsetToAwake[1];

      const sleepOnsetTimeInMins =
        totalSleepOnsetToSleepTimeInMins + totalSleepOnsetToAwakeTimeInMins;
      // ====================================================

      // Calculate the sleep efficiency percentage (%) —— ((( time asleep + time onset ) - activities) / (time asleep + time onset)) * 100
      // ====================================================
      const sleepEfficiencyPercentage =
        ((totalSleepTimeInMins - totalTimeForActivities) /
          (totalSleepTimeInMins + sleepOnsetTimeInMins)) *
        100;
      // ====================================================

      // Store the day data for graphs
      sleepOnsets.data[sleepReport.day] = sleepOnsetTimeInMins;
      sleepEfficiency.data[sleepReport.day] = sleepEfficiencyPercentage;
      sleepAwakeningsInMins.data[sleepReport.day] = totalTimeForActivities;
      sleepTimeInMins.data[sleepReport.day] =
        totalSleepTimeInMins - totalTimeForActivities;

      // Store the total times
      sleepOnsets.total += sleepOnsetTimeInMins;
      sleepTimeInMins.total += totalSleepTimeInMins - totalTimeForActivities;
      sleepAwakeningsInMins.total += totalTimeForActivities;

      // Calculate and store the averages
      sleepOnsets.average = sleepOnsets.total / (i + 1);
      sleepTimeInMins.average = sleepTimeInMins.total / (i + 1);
      sleepAwakeningsInMins.average = sleepAwakeningsInMins.total / (i + 1);
      sleepEfficiency.average =
        Object.entries(sleepEfficiency.data).reduce(
          (acc, curr) => acc + curr[1],
          0
        ) /
        (i + 1);
    }

    setSleepOnsets(sleepOnsets);
    setSleepEfficiency(sleepEfficiency);
    setSleepTimeInMins(sleepTimeInMins);
    setSleepDataByRange(filteredSleepData);
    setSleepAwakeningsInMins(sleepAwakeningsInMins);

    setIsLoaded(true);
  }, [startDate, endDate, sleepData]);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  return (
    <>
      <Navbar state={{...schState}} />
      {!isLoaded && (
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}
      <div
        id="fixed-nav"
        className="flex items-center pt-5 justify-end mr-10 gap-3"
      >
        <Notifications
          {...{
            dispatch,
            state,
          }}
          absolute={false}
        />
        <div
          className="w-fit top-3.5 z-[60] right-[4.5rem] bg-light opacity-75 p-2 rounded-full cursor-pointer"
          onClick={() => {
            loadDataForPatientLogin(
              {
                ...schState,
                destination: "/sleepTracker",
              },
              navigate,
              setLoading
            );
          }}
        >
          <img src="/images/reload.png" alt="" className={"w-6 " + loading} />
        </div>
        <div
          className="w-fit top-3.5 z-[60] right-4 bg-light opacity-75 p-2 rounded-full cursor-pointer"
          onClick={logoutUser}
        >
          <img src="/images/login.png" alt="" className="w-7 " />
        </div>
      </div>

      {/* <InstanceBar {...{dispatch, schState}} /> */}

      <div
        ref={loadedRef}
        className="my-10 relative mr-5 pr-5 ml-40 pb-5 px-2 h-[calc(100vh-12rem)] board"
      >
        {schState.changingLocation && (
          <div className="h-full w-full  inset-0 bg-white/80 z-[99999] absolute flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
          </div>
        )}

        {isLoaded && (
          <>
            <header className="flex flex-col md:flex-row items-center md:space-x-10 gap-5 pb-4">
              <RangeTypeSelector
                rangeType={rangeType}
                setRangeType={setRangeType}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <RangeSelector
                rangeType={rangeType}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />

              <button
                className="btn rounded no-animation text-white bg-dark outline-transparent border-transparent hover:border-transparent hover:bg-dark/80"
                onClick={() => {
                  console.log("clicked");
                  inputModalRef.current?.showModal();
                }}
              >
                <AiOutlinePlus />
              </button>
            </header>

            <div className="grid lg:grid-cols-8 grid-cols-1 gap-8 py-4">
              <SleepTrackerCard
                className="lg:col-span-4"
                title="Sleep"
                Icon={FaBed}
              >
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">
                        Average:{" "}
                      </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {convertMinutesToHM(sleepTimeInMins.average).hours}h{" "}
                        {convertMinutesToHM(sleepTimeInMins.average).minutes}m
                      </p>
                    </div>

                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">Total: </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {convertMinutesToHM(sleepTimeInMins.total).hours}h{" "}
                        {convertMinutesToHM(sleepTimeInMins.total).minutes}m
                      </p>
                    </div>
                  </div>

                  <SleepTimeChart data={sleepTimeInMins.data} />
                </div>
              </SleepTrackerCard>

              <SleepTrackerCard
                className="lg:col-span-4"
                title="Awakenings"
                Icon={HiOutlineClock}
              >
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">
                        Average:{" "}
                      </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {
                          convertMinutesToHM(sleepAwakeningsInMins.average)
                            .hours
                        }
                        h{" "}
                        {
                          convertMinutesToHM(sleepAwakeningsInMins.average)
                            .minutes
                        }
                        m
                      </p>
                    </div>

                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">Total: </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {convertMinutesToHM(sleepAwakeningsInMins.total).hours}h{" "}
                        {
                          convertMinutesToHM(sleepAwakeningsInMins.total)
                            .minutes
                        }
                        m
                      </p>
                    </div>
                  </div>

                  <SleepAwakeningsChart data={sleepAwakeningsInMins.data} />
                </div>
              </SleepTrackerCard>

              <SleepTrackerCard
                className="lg:col-span-4"
                title="Sleep Onset"
                Icon={FaStopwatch}
              >
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">
                        Average:{" "}
                      </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {convertMinutesToHM(sleepOnsets.average).hours}h{" "}
                        {convertMinutesToHM(sleepOnsets.average).minutes}m
                      </p>
                    </div>

                    <div className="flex flex-row w-full gap-2">
                      <p className="text-lg text-gray-500 font-bold">Total: </p>
                      <p className="text-xl font-semibold text-red-500 truncate">
                        {convertMinutesToHM(sleepOnsets.total).hours}h{" "}
                        {convertMinutesToHM(sleepOnsets.total).minutes}m
                      </p>
                    </div>
                  </div>

                  <SleepOnsetChart data={sleepOnsets.data} />
                </div>
              </SleepTrackerCard>

              <SleepTrackerCard
                className="lg:col-span-4"
                title="Sleep Efficiency"
                Icon={GiProgression}
              >
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center space-x-2">
                    <p className="text-lg text-gray-500 font-bold">Average: </p>
                    <p className="text-xl font-semibold text-red-500 truncate">
                      {sleepEfficiency.average.toFixed(2)}%
                    </p>
                  </div>

                  <SleepEfficiencyChart data={sleepEfficiency.data} />
                </div>
              </SleepTrackerCard>
            </div>

            <div className="w-full space-y-5 p-4">
              <SleepHorizontalStackedBarChart data={sleepDataByRange} />
            </div>
          </>
        )}

        <InputModal ref={inputModalRef} state={state} />
      </div>
    </>
  );
}

const InputModal = forwardRef((props, ref) => {
  const state = {props};
  return (
    <dialog ref={ref} className="bg-transparent">
      <div className="flex flex-col space-y-4 bg-[#F6F5FE] p-10 rounded-md">
        <div className="flex flex-row items-center justify-end w-full ">
          <button className="text-gray-500" onClick={() => ref.current.close()}>
            <FaTimes />
          </button>
        </div>

        <SleepTrackerInput state={state} ref={ref} />
      </div>
    </dialog>
  );
});

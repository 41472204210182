import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {
  loginUser,
  resetUserEmail,
  verifyLoginCode,
  setAuthCode,
} from "../authRequests";
import {
  loadDataForPatientLogin,
  crud,
  viewOrganizationByUrl,
  viewCustomers,
} from "../crudRequests";
import clientInfo from "../additional_files/loginInfo";
import CodeForm from "../components/Login+CodeForm";
import ForgotPwdForm from "../components/ForgotPwdForm";
import ResetPwdForm from "../components/ResetPwdForm";
import axios from "axios";
import {FaHospitalUser} from "react-icons/fa";
export default function Login() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const authErrorRef = useRef(null);
  const serverErrorRef = useRef(null);
  const loadedRef = useRef(null);
  const loaderRef = useRef(null);
  const loginLoaderRef = useRef(null);

  const [codeForm, setCodeForm] = useState(false);
  const [forgotPwdForm, setForgotPwdForm] = useState(false);
  const logingData = useRef(null);
  const sendCodeData = useRef(null);
  const navigate = useNavigate();
  const [urlBasedOrg, setUrlBasedOrg] = useState();
  const [customers, setCustomers] = useState([]);
  let {db} = useParams();

  //console.log(db);
  useEffect(
    function () {
      if (!window.location.href.includes("?reset=")) {
        viewCustomers()
          .then((c) => {
            if (c.data?.[0]) {
              if (db) {
                let cust = c.data?.find((customers) => customers.db === db);
                if (cust) {
                  setUrlBasedOrg(cust);
                } else {
                  window.location.href = "../login";
                }
              } else {
                setUrlBasedOrg(c.data?.[0]);
                setCustomers(c.data);
              }
            }
          })
          .catch((e) => console.error);
      }
    },
    [db]
  );

  async function login(e) {
    if (urlBasedOrg) {
      e.preventDefault();
      authErrorRef.current.className = "hidden";
      serverErrorRef.current.className = "hidden";
      loginLoaderRef.current.className = "inline-block";

      loginUser(
        emailRef.current.value,
        passwordRef.current.value,
        "patient",
        urlBasedOrg.db
      )
        .then(async (res) => {
          logingData.current = {
            jwt: res.data.token,
            patient: res.data.patient,
            destination: "../",
            db: res.data.db,
            userType: res.data.relationship ? "relationship" : "patient",
            relationship: res.data.relationship,
          };
          if (res.data.patient.tfa || res.data.relationship?.tfa) {
            sendCodeData.current = {
              jwt: res.data.token,
              userType: logingData.current.userType,
              user:
                logingData.current.userType === "relationship"
                  ? res.data.relationship
                  : res.data.patient,
              db: res.data.db,
              organization: urlBasedOrg,
            };
            setAuthCode({
              ...sendCodeData.current,
            }).then((r) => {
              setCodeForm(true);
            });
          } else {
            loaderRef.current.className = "block";
            loadedRef.current.className = "hidden";
            loadDataForPatientLogin(
              {
                ...logingData.current,
                destination: "../",
              },
              navigate
            );

            let jsonIp = await axios({
              url: "https://api.ipify.org?format=json",
            });
            let userId =
              logingData.current.userType === "relationship"
                ? res.data.relationship?.rid
                : res.data.patient?.pid;

            crud({jwt: res.data.token}, [
              {
                db: res.data.db,
                collection: "security",
                parameters: [
                  {userId},
                  {
                    $push: {
                      logins: {
                        ip: jsonIp.data.ip,
                        client: clientInfo(),
                        timestamp: new Date(),
                      },
                    },
                  },
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
            ]);
          }
        })
        .catch((err) => {
          console.log(err.message);
          if (err.response?.status === 403) {
            loginLoaderRef.current.className = "hidden";
            authErrorRef.current.className = "inline-block";
          } else if (
            err.response?.status === 404 ||
            err.response?.status === 500
          ) {
            loginLoaderRef.current.className = "hidden";
            serverErrorRef.current.className = "inline-block";
          } else console.log(err.response);
        });
    }
  }

  return (
    <div className="h-screen grid place-items-center">
      <div ref={loaderRef} className="hidden">
        <div className="border p-[6.5rem] rounded-md">
          <div className="flex justify-center align-middle">
            <img src="/images/loader.gif" className="h-36" />
          </div>
        </div>
      </div>

      {codeForm ? (
        <CodeForm
          logingData={logingData.current}
          navigate={navigate}
          sendCodeData={sendCodeData.current}
          org={urlBasedOrg}
        />
      ) : forgotPwdForm ? (
        <ForgotPwdForm navigate={navigate} org={urlBasedOrg} />
      ) : window.location.href.includes("?reset=") ? (
        <ResetPwdForm navigate={navigate} />
      ) : (
        <div ref={loadedRef}>
          <form onSubmit={login} className="border p-12 rounded-md">
            {urlBasedOrg?.logo && (
              <img src={urlBasedOrg.logo} className="w-64 mx-auto mb-5" />
            )}
            <div className="hidden" ref={authErrorRef}>
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      Invalid user or password!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden" ref={serverErrorRef}>
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      <span className="font-semibold">
                        The server has encountered an
                        <br />
                        internal error.
                      </span>
                      <br />
                      Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {customers.length > 0 && (
              <div className="w-full">
                <label className="block  my-1" htmlFor="type">
                  <span className="text-[12px]  text-[#3871e0] ">
                    Select Clinic
                  </span>
                  <select
                    name="type"
                    id="clinic"
                    onChange={(e) => setUrlBasedOrg(customers[e.target.value])}
                    className="block  px-5 mt-1 mb-2 w-72 py-3 pl-2 text-sm text-off font-medium bg-white focus:border-[#3871e0] border-[#dcdcdc] focus:ring-1 focus:ring-[#3871e0] border  rounded-[8px]  focus:outline-none "
                  >
                    {" "}
                    {customers.map((c, i) => (
                      <option value={i} key={i}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            )}
            <div className="input-group">
              <input
                type="text"
                id="email"
                ref={emailRef}
                required
                className="input w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
              />
              <label htmlFor="email" className="input-label">
                Email/Phone
              </label>
            </div>
            <div className="input-group">
              <input
                type="text"
                id="password"
                ref={passwordRef}
                required
                autoComplete="off"
                className="input w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
              />
              <label htmlFor="password" className="input-label">
                Password
              </label>
            </div>
            <div className="flex justify-between">
              <span
                className="text-dark font-medium text-xs mt-4 cursor-pointer"
                onClick={() => {
                  setForgotPwdForm(true);
                }}
              >
                Forgot Password?
              </span>

              <button
                type="submit"
                className="p-2.5 px-5 rounded-[4px] my-2 bg-dark text-background font-medium text-medium right cursor-pointer"
              >
                Login
                <span ref={loginLoaderRef} className="hidden">
                  <svg
                    className="animate-spin ml-2 -mb-0.5 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

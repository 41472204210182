import React, { forwardRef } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays, format, isAfter } from "date-fns";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { Line, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { calculateTimeDifference } from "../additional_files/helpers";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// For Sleep Tracker Input
import { crud } from "../crudRequests";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export function RangeTypeSelector({ rangeType, setRangeType, setStartDate, setEndDate }) {
  const handleRangeByWeek = () => {
    setRangeType("week");
    setStartDate(addDays(new Date(), -6));
    setEndDate(new Date());
  };

  const handleRangeByMonth = () => {
    setRangeType("month");
    setStartDate(addDays(new Date(), -29));
    setEndDate(new Date());
  };

  return (
    <div className="flex flex-row gap-2">
      <button
        title="View weekly data"
        onClick={handleRangeByWeek}
        className={[
          "btn rounded no-animation hover:border-transparent hover:text-white hover:bg-dark/80 focus:outline-transparent",
          rangeType === "week" ? "text-white bg-dark border-transparent" : "text-dark bg-white border-dark",
        ].join(" ")}
      >
        WEEKLY
      </button>

      <button
        title="View monthly data"
        onClick={handleRangeByMonth}
        className={[
          "btn rounded no-animation hover:border-transparent hover:text-white hover:bg-dark/80 focus:outline-transparent",
          rangeType === "month" ? "text-white bg-dark border-transparent" : "text-dark bg-white border-dark",
        ].join(" ")}
      >
        MONTHLY
      </button>
    </div>
  );
}

export function RangeSelector({ rangeType, startDate, endDate, setStartDate, setEndDate }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleVisibility = () => setIsOpen(!isOpen);
  const dateRangePickerRef = React.useRef(null);

  // Close date range picker when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRangePickerRef]);

  const selectionRange = React.useMemo(
    () => ({
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    }),
    [startDate, endDate]
  );

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  const handlePrev = () => {
    if (startDate && endDate) {
      if (rangeType === "week") {
        setStartDate(addDays(startDate, -7));
        setEndDate(addDays(endDate, -7));
      } else {
        setStartDate(addDays(startDate, -30));
        setEndDate(addDays(endDate, -30));
      }
    }
  };

  const handleNext = () => {
    if (startDate && endDate) {
      if (rangeType === "week") {
        setStartDate(addDays(startDate, 7));
        setEndDate(addDays(endDate, 7));
      } else {
        setStartDate(addDays(startDate, 30));
        setEndDate(addDays(endDate, 30));
      }
    }
  };

  const isDisabled = React.useMemo(() => {
    return isAfter(new Date(endDate), addDays(new Date(), -1));
  }, [endDate]);

  return (
    <div ref={dateRangePickerRef}>
      <div className="flex items-center justify-center space-x-2 relative">
        {/* Prev */}
        <button
          onClick={handlePrev}
          title="Previous"
          className="btn rounded no-animation text-white bg-dark outline-transparent border-transparent hover:border-transparent hover:bg-dark/80"
        >
          <HiArrowLeft className="h-6 w-6" />
        </button>

        {/* Selector */}
        <button
          onClick={toggleVisibility}
          title="Change range"
          className="flex items-center p-2 border border-gray-300 text-gray-700 font-semibold space-x-1"
        >
          <p>{format(startDate, "MM/dd/yyyy")}</p>
          <span>&nbsp; &mdash; &nbsp;</span>
          <p>{format(endDate, "MM/dd/yyyy")}</p>
        </button>

        {/* Next */}
        <button
          disabled={isDisabled}
          onClick={handleNext}
          title="Next"
          className="btn rounded no-animation text-white bg-dark outline-transparent border-transparent hover:border-transparent hover:bg-dark/80"
        >
          <HiArrowRight className="h-6 w-6" />
        </button>

        {/* Date picker */}
        {isOpen && (
          <DateRangePicker
            className="absolute top-12 z-30 shadow-md"
            months={1}
            rangeColors={["#5754FF"]}
            ranges={[selectionRange]}
            onChange={handleSelect}
            maxDate={new Date()}
          />
        )}
      </div>
    </div>
  );
}

export function SleepTrackerCard({ title, Icon, className: otherClassName, children }) {
  return (
    <div className={["p-6 bg-white border shadow rounded-2xl", otherClassName].join(" ")}>
      {/* Title */}
      <div className="flex items-center space-x-2 text-gray-400 -ml-6 pl-6 border-l-4 border-dark">
        {Icon && <Icon className="w-6 h-6" />}
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>

      {/* Content */}
      <div className="flex flex-col space-y-3 mt-4">{children}</div>
    </div>
  );
}

export function SleepTimeChart({ data }) {
  const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]));
  const [labels, values] = [sortedData.map((item) => item[0]), sortedData.map((item) => item[1])];

  return (
    <div className="w-full">
      <Line
        data={{
          labels: labels.map((item) => format(new Date(item), "dd-MM")),
          datasets: [
            {
              label: "Sleep Time",
              data: values,
              fill: false,
              borderColor: "#131B48",
              tension: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          scales: {
            y: {
              grid: { display: true },
              ticks: {
                callback: (value) => {
                  if (value < 60) {
                    return `${value} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(value / 60), value % 60];
                    return `${hours}h ${mins}m`;
                  }
                },
              },
            },
            x: {
              grid: { display: false },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: ({ dataset, parsed }) => {
                  let formatedTime = "";

                  if (parsed.y < 60) {
                    formatedTime = `${parsed.y} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(parsed.y / 60), parsed.y % 60];
                    formatedTime = `${hours}h ${mins}m`;
                  }

                  return `${dataset.label || ""}: ${formatedTime}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export function SleepAwakeningsChart({ data }) {
  const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]));
  const [labels, values] = [sortedData.map((item) => item[0]), sortedData.map((item) => item[1])];

  return (
    <div className="w-full">
      <Line
        data={{
          labels: labels.map((item) => format(new Date(item), "dd-MM")),
          datasets: [
            {
              label: "Sleep Awakenings",
              data: values,
              fill: true,
              borderColor: "#269495",
              tension: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          scales: {
            y: {
              grid: { display: true },
              ticks: {
                callback: (value) => {
                  if (value < 60) {
                    return `${value} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(value / 60), value % 60];
                    return `${hours}h ${mins}m`;
                  }
                },
              },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: ({ dataset, parsed }) => {
                  let formatedTime = "";

                  if (parsed.y < 60) {
                    formatedTime = `${parsed.y} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(parsed.y / 60), parsed.y % 60];
                    formatedTime = `${hours}h ${mins}m`;
                  }

                  return `${dataset.label || ""}: ${formatedTime}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export function SleepOnsetChart({ data }) {
  const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]));
  const [labels, values] = [sortedData.map((item) => item[0]), sortedData.map((item) => item[1])];

  return (
    <div className="w-full">
      <Line
        data={{
          labels: labels.map((item) => format(new Date(item), "dd-MM")),
          datasets: [
            {
              label: "Sleep Onset",
              data: values,
              fill: false,
              borderColor: "#269495",
              tension: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          scales: {
            y: {
              grid: { display: true },
              ticks: {
                callback: (value) => {
                  if (value < 60) {
                    return `${value} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(value / 60), value % 60];
                    return `${hours}h ${mins}m`;
                  }
                },
              },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: ({ dataset, parsed }) => {
                  let formatedTime = "";

                  if (parsed.y < 60) {
                    formatedTime = `${parsed.y} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(parsed.y / 60), parsed.y % 60];
                    formatedTime = `${hours}h ${mins}m`;
                  }

                  return `${dataset.label || ""}: ${formatedTime}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export function SleepEfficiencyChart({ data }) {
  const sortedData = Object.entries(data).sort((a, b) => new Date(a[0]) - new Date(b[0]));
  const [labels, values] = [sortedData.map((item) => item[0]), sortedData.map((item) => item[1])];

  return (
    <div className="w-full">
      <Line
        data={{
          labels: labels.map((item) => format(new Date(item), "dd-MM")),
          datasets: [
            {
              label: "Sleep Efficiency",
              data: values,
              fill: false,
              borderColor: "#131B48",
              tension: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          scales: {
            y: {
              grid: { display: true },
            },
            x: {
              grid: { display: false },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: ({ dataset, parsed }) => {
                  return `${dataset.label || ""}: ${parsed.y.toFixed(2)}%`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export function SleepHorizontalStackedBarChart({ data }) {
  const sortedData = data.sort((a, b) => new Date(a.day) - new Date(b.day));

  return (
    // Model data structure to show timeline in chart
    // time_inbed 		—> time_asleep 		—> [ Get all start time, and sort activities by start_time (ascending)  ] 	—> time_awake 			—> time_outofbed
    // 21:15 (30mins)	—> 21:45 (205mins) 	—> [ [01:10 (40mins) - 01:50],  [03:30 (20 mins) - 03:50] ]					—> 06:30 (160 mins) 	—> 07:45 (75 mins)
    <div className="max-w-full">
      <Bar
        plugins={[ChartDataLabels]}
        data={{
          labels: sortedData.map((item) => format(new Date(item.day), "EEEE (dd/MM)")),
          datasets: [
            {
              label: "In Bed",
              data: sortedData.map((item) => {
                const timeInBedBeforeSleep = calculateTimeDifference(item.time_inbed, item.time_asleep);
                return timeInBedBeforeSleep[0] * 60 + timeInBedBeforeSleep[1];
              }),
              backgroundColor: "rgb(159 157 249 / 40%)",
              hoverBackgroundColor: "rgb(159 157 249 / 40%)",
            },
            {
              label: "Asleep",
              data: sortedData.map((item) => {
                const timeAsleep = calculateTimeDifference(item.time_asleep, item.time_awake);

                const totalAwakenings = item.activities.reduce((acc, curr) => {
                  const timeAwake = calculateTimeDifference(curr.start_time, curr.end_time);
                  return acc + (timeAwake[0] * 60 + timeAwake[1]);
                }, 0);
                return timeAsleep[0] * 60 + timeAsleep[1] - totalAwakenings;
              }),
              backgroundColor: "rgb(159 157 249 / 100%)",
              hoverBackgroundColor: "rgb(159 157 249 / 100%)",
            },
            {
              label: "Awakenings",
              data: sortedData.map((item) => {
                const totalAwakenings = item.activities.reduce((acc, curr) => {
                  const timeAwake = calculateTimeDifference(curr.start_time, curr.end_time);
                  return acc + (timeAwake[0] * 60 + timeAwake[1]);
                }, 0);
                return totalAwakenings;
              }),
              backgroundColor: "rgb(205 204 255 / 20%)",
              hoverBackgroundColor: "rgb(205 204 255 / 20%)",
            },
            {
              label: "Out of Bed",
              data: sortedData.map((item) => {
                const timeInBedAfterSleep = calculateTimeDifference(item.time_awake, item.time_outofbed);
                return timeInBedAfterSleep[0] * 60 + timeInBedAfterSleep[1];
              }),
              backgroundColor: "rgb(205 204 255 / 60%)",
              hoverBackgroundColor: "rgb(205 204 255 / 60%)",
            },
          ],
        }}
        options={{
          responsive: true,
          indexAxis: "y",
          scales: {
            x: { stacked: true, grid: { display: false }, ticks: { display: false } },
            y: { stacked: true },
          },
          plugins: {
            datalabels: {
              display: true,
              color: "#293FFF",
              formatter: (value) => {
                if (value === 0) return "";

                if (value > 60) {
                  const hours = Math.floor(value / 60);
                  const mins = value % 60;
                  return `${hours}h ${mins}m`;
                }

                return `${value} m`;
              },
              font: {
                size: 12,
              },
            },
            title: {
              align: "center",
            },
            legend: { display: true },
            tooltip: {
              callbacks: {
                label: ({ dataset, parsed }) => {
                  let formatedTime = "";

                  if (parsed.x < 60) {
                    formatedTime = `${parsed.x} mins`;
                  } else {
                    const [hours, mins] = [Math.floor(parsed.x / 60), parsed.x % 60];
                    formatedTime = `${hours}h ${mins}m`;
                  }

                  return `${dataset.label || ""}: ${formatedTime}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export const SleepTrackerInput = forwardRef(({ state }, ref) => {
  const a_new_activity = { type: "", start_time: "", end_time: "" };
  const [activities, setActivities] = React.useState([a_new_activity]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract input from form inputs
    const formData = new FormData(e.target);
    const formDataToJSON = Object.fromEntries(formData);

    // Add activities to formDataToJSON
    formDataToJSON.activities = activities;

    // Add some patient id to the formDataToJSON payload
    formDataToJSON.pid = "00002";

    // Send the payload to the server
    await crud(state, [
      {
        db: state.db,
        collection: "sleep_diary",
        parameters: [formDataToJSON],
        method: "insertOne",
      },
    ]);

    // Status message
    console.log("A new Sleep Diary Input has been created successfully!");
    /* swal("A new Sleep Diary Input has been created successfully!", {
      icon: "success",
      title: "Sleep Input Submitted",
    }); */

    // Reset form
    e.target.reset();
    setActivities([a_new_activity]);
    ref.current.close();
  };

  return (
    <div className="flex flex-col w-full p-2">
      {/* Sleep Tracker Input */}
      <h2 className="text-3xl font-bold text-[#C0BFFA]">Sleep Tracker Input</h2>

      <form className="my-5 space-y-2 " onSubmit={handleSubmit}>
        <div>
          <label htmlFor="day" className="label mb-1">
            <span className="label-text text-[#A1A1A1]">Day (Night of the Sleep)</span>
          </label>
          <input type="date" name="day" className="input  rounded-xl w-full max-w-lg" required />
        </div>

        <div>
          <label htmlFor="dayType" className="label mb-1">
            <span className="label-text text-[#A1A1A1]">Day Type</span>
          </label>
          <select name="dayType" defaultValue="" className="select  w-full max-w-lg" required>
            <option disabled value="">
              Select Day Type
            </option>
            <option value="work">Work</option>
            <option value="weekend">Weekend</option>
            <option value="day-off">Day-Off</option>
          </select>
        </div>

        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
          <div>
            <label htmlFor="time_inbed" className="label mb-1">
              <span className="label-text text-[#A1A1A1]">Time In Bed</span>
            </label>
            <input type="time" name="time_inbed" className="input  rounded-xl w-full max-w-lg" required />
            <label className="label">
              <span className="label-text-alt">The time you went to bed</span>
            </label>
          </div>
          <div>
            <label htmlFor="time_asleep" className="label mb-1">
              <span className="label-text text-[#A1A1A1]">Time Asleep</span>
            </label>
            <input type="time" name="time_asleep" className="input  rounded-xl w-full max-w-lg" required />
            <label className="label">
              <span className="label-text-alt">The time you fell asleep</span>
            </label>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2">
          <div>
            <label htmlFor="time_awake" className="label mb-1">
              <span className="label-text text-[#A1A1A1]">Time Awake</span>
            </label>
            <input type="time" name="time_awake" className="input  rounded-xl w-full max-w-lg" required />
            <label className="label">
              <span className="label-text-alt">The time you woke up</span>
            </label>
          </div>
          <div>
            <label htmlFor="time_outofbed" className="label mb-1">
              <span className="label-text text-[#A1A1A1]">Time Out Of Bed</span>
            </label>
            <input type="time" name="time_outofbed" className="input  rounded-xl w-full max-w-lg" required />
            <label className="label">
              <span className="label-text-alt">The time you got off the bed</span>
            </label>
          </div>
        </div>

        {/* Activities */}
        {activities.map((activity, index) => {
          return (
            <div key={index} className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
              <div>
                <label htmlFor="type" className="label mb-1">
                  <span className="label-text text-lg font-bold text-dark">Activity {index + 1}</span>
                </label>
                <select
                  onChange={(e) => {
                    setActivities((prev) => {
                      const newActivities = [...prev];
                      newActivities[index]["type"] = e.target.value;
                      return newActivities;
                    });
                  }}
                  value={activity.type}
                  className="select  w-full max-w-lg capitalize"
                  required
                >
                  <option disabled value="">
                    Select Activity
                  </option>
                  {/* More activities can be added here */}
                  {["none", "awakenings", "alcohol", "caffeine", "exercise"].map((activity) => (
                    <option key={activity} value={activity}>
                      {activity}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="start_time" className="label mb-1">
                  <span className="label-text text-[#A1A1A1]">Start Time</span>
                </label>
                <input
                  onChange={(e) => {
                    setActivities((prev) => {
                      const newActivities = [...prev];
                      newActivities[index]["start_time"] = e.target.value;
                      return newActivities;
                    });
                  }}
                  value={activity.start_time}
                  type="time"
                  className="input  rounded-xl w-full max-w-lg"
                  required
                />
              </div>

              <div>
                <label htmlFor="end_time" className="label mb-1">
                  <span className="label-text text-[#A1A1A1]">End Time</span>
                </label>
                <input
                  onChange={(e) => {
                    setActivities((prev) => {
                      const newActivities = [...prev];
                      newActivities[index]["end_time"] = e.target.value;
                      return newActivities;
                    });
                  }}
                  value={activity.end_time}
                  type="time"
                  className="input  rounded-xl w-full max-w-lg"
                  required
                />
              </div>

              {activities.length !== 1 && (
                <div className="lg:col-span-3 col-span- flex justify-end gap-4">
                  <button
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      if (
                        // eslint-disable-next-line no-restricted-globals
                        !confirm("Are you sure you want to remove this activity?")
                      )
                        return;

                      setActivities((prev) => {
                        return prev.filter((_, i) => i !== index);
                      });
                    }}
                    type="button"
                    className="btn btn-sm btn-ghost rounded border-none bg-red-500 hover:bg-red-500 text-white no-animation"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          );
        })}

        {/* Add more / Remove activity buttons */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="btn btn-sm btn-ghost rounded border-none bg-[#8B96FF] text-white no-animation"
            onClick={() => {
              setActivities((prev) => [...prev, a_new_activity]);
            }}
          >
            Add Activity
          </button>
        </div>

        <button type="submit" className="btn btn-wide rounded border-none bg-[#8B96FF] text-white no-animation hover:bg-[#8B96FF]">
          Submit
        </button>
      </form>
    </div>
  );
});

import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import {patientReducer, init} from "../additional_files/reducer";
import {useSocket} from "../additional_files/custom";
import Layout from "../components/Layout";
import {Fade} from "@chakra-ui/react";
import MasterIntake from "../components/MasterIntake";

export default function Intake(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);

  const sessionStart = useRef(true);
  let room = dashState.patient?.pid;

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  const socket = useSocket("https://webserver.brightlight.ai/", room, dispatch);
  //const socket = useSocket('http://localhost:3000/', room, dispatch);

  useEffect(() => {
    if (dashState.patient && !sessionStart.current) {
      loadDataForPatientLogin(
        {
          ...dashState,
          destination: "../intake",
        },
        navigate
      );
    }
  }, []);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  return (
    <>
      <Layout state={{...dashState}}>
        <Fade in={dashState} transition={{enter: {duration: 0.5}}}>
          <MasterIntake dashState={dashState} />
        </Fade>
      </Layout>
    </>
  );
}

import { supplementaryCrud } from "../../../crudRequests";

export async function fetchDisorders(setDisorders, userId, toast) {
  try {
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "disorders",
        parameters: [
          {
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "find",
      },
    ]);
    setDisorders(res.data[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch disorder data",
      description: err?.message || "An error occurred while fetching disorder data.",
      status: "error",
      isClosable: true,
    });
  }
}

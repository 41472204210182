import {promptAI} from "./promptAI";

export async function acknowledge(context) {
  let prompt =
    'If a patient was asked "' +
    context.currentQuestion +
    '" and answered "' +
    context.currentResponse +
    '", generate one sentence that is an appropriate, empathetic and comforting acknowledgement for them along the lines of "I understand concentration issues must be troubling to deal with.".';

  const acknowledgementString = await promptAI(
    "You are a comforting healthcare provider",
    prompt
  );

  let acknowledgement = acknowledgementString.data.output.replace(
    /^['"]|['"]$/g,
    ""
  );

  acknowledgement = acknowledgement.includes("I'm unable to provide")
    ? ""
    : acknowledgement;

  return acknowledgement;
}

import React from "react";
import {useNavigate} from "react-router-dom";
import {
  IconButton,
  Image,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {FiHome, FiMenu, FiBell, FiChevronDown, FiUser} from "react-icons/fi";
import {HiOutlineDocumentDuplicate} from "react-icons/hi";
import {PiPrescriptionBold, PiMoonStars} from "react-icons/pi";
import {RxMoon} from "react-icons/rx";
import {BsJournalText} from "react-icons/bs";
import {AiOutlineMessage} from "react-icons/ai";
import {MdAttachMoney} from "react-icons/md";
import {AiOutlineRobot} from "react-icons/ai";
import {CiPillsBottle1} from "react-icons/ci";

import {logoutUser} from "../authRequests";

{
  /*{name: "Prescriptions", route: "../prescriptions", icon: PiPrescriptionBold},
  {name: "Sleep Diary", route: "../sleep", icon: RxMoon},
  {name: "Smart Intake", route: "../chatbot", icon: AiOutlineRobot},*/
}
const LinkItems = [
  {name: "Dashboard", route: "../", icon: FiHome},
  // {name: "Smart Intake", route: "../intake", icon: AiOutlineRobot},
  {name: "Profile", route: "../user", icon: FiUser},
  {name: "Forms", route: "../forms", icon: HiOutlineDocumentDuplicate},
  //{name: "Instant Messaging", route: "../inbox", icon: AiOutlineMessage},
  {name: "Sleep Diary", route: "../sleep", icon: AiOutlineMessage},
  {
    name: "Prescriptions",
    route: "../prescriptionCompliance",
    icon: CiPillsBottle1,
  },
  {name: "Smart Journal", route: "../journal", icon: BsJournalText},
  //{name: "Payments", route: "../payments", icon: MdAttachMoney},
];

const SidebarContent = ({state, onClose, ...rest}) => {
  const navigate = useNavigate();

  const isActive = (route, name) => {
    const isInPath = window.location.href.includes(route.split("/")[1]);
    return (
      (name !== "Dashboard" && isInPath) ||
      (name === "Dashboard" && window.location.href.split("/")[3]?.length < 2)
    );
  };

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{base: "full", md: 60}}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        mb="5"
        justifyContent="space-between"
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Image boxSize={"175px"} src="./images/brightlight-black-logo.svg" />
        </Box>
        <CloseButton display={{base: "flex", md: "none"}} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          bg={isActive(link.route, link.name) ? "gray.900" : undefined}
          color={isActive(link.route, link.name) ? "white" : undefined}
          onClick={() => {
            navigate(link.route, {
              state: {...state},
            });
          }}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({icon, children, ...rest}) => (
  <Box style={{textDecoration: "none"}} _focus={{boxShadow: "none"}}>
    <Flex
      align="center"
      p="3"
      mx="4"
      my="1"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: "gray.700",
        color: "white",
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </Box>
);

const MobileNav = ({state, onOpen, ...rest}) => {
  return (
    <Flex
      ml={{base: 0, md: 60}}
      px={{base: 4, md: 4}}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{base: "space-between", md: "flex-end"}}
      {...rest}
    >
      <IconButton
        display={{base: "flex", md: "none"}}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Box display={{base: "flex", md: "none"}} h="30px">
        <Image src="./images/brightlight-black-logo.svg" />
      </Box>

      <HStack spacing={{base: "0", md: "6"}}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{boxShadow: "none"}}
            >
              <HStack>
                <Avatar size={"sm"} name="Om Agarwal" bg="black" />
                <VStack
                  display={{base: "none", md: "flex"}}
                  alignItems="flex-start"
                  spacing="0.5px"
                  ml="2"
                >
                  <Text fontSize="sm">
                    {state.userType === "relationship"
                      ? state?.relationship?.name
                      : state?.patient?.fName
                      ? state?.patient?.fName + " " + state?.patient?.lName
                      : "No patient name"}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    {state.userType === "relationship"
                      ? state?.relationship?.email || state?.relationship?.phone
                      : state?.patient?.email ||
                        state?.patient?.phone ||
                        "No contact info"}
                  </Text>
                </VStack>
                <Box display={{base: "none", md: "flex"}}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={logoutUser}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Layout = ({state, children}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        state={state}
        onClose={() => onClose}
        display={{base: "none", md: "block"}}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent state={state} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav state={state} onOpen={onOpen} />
      <Box ml={{base: 0, md: 60}} p={10}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;

import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function Patient(props) {
  const fNameRef = useRef(null);
  const lNameRef = useRef(null);
  const genderRef = useRef(null);
  const dobRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);
  const pastMentalHealthConditionsRef = useRef(null);
  const pastFamilyMentalHealthConditionsRef = useRef(null);
  const pastHealthConditionsRef = useRef(null);
  const pastFamilyHealthConditionsRef = useRef(null);
  const medicationsRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  async function handleSubmit(e) {
    //validate if pwd and cpwd match first...& rest of fields are legit (not empty & correct format)
    e.preventDefault();

    function jwtDecode(t) {
      let token = {};
      token.raw = t;
      token.header = JSON.parse(window.atob(t.split(".")[0]));
      token.payload = JSON.parse(window.atob(t.split(".")[1]));
      return token;
    }

    const result = await fetch("https://authserver.brightlight.ai/registerPatient", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pid: props.pid,
        fName: fNameRef.current.value,
        lName: lNameRef.current.value,
        gender: genderRef.current.value,
        dob: dobRef.current.value,
        height: heightRef.current.value,
        weight: weightRef.current.value,
        pastMentalHealthConditions: pastMentalHealthConditionsRef.current.value,
        pastFamilyMentalHealthConditions: pastFamilyMentalHealthConditionsRef.current.value,
        pastHealthConditions: pastHealthConditionsRef.current.value,
        pastFamilyHealthConditions: pastFamilyHealthConditionsRef.current.value,
        medications: medicationsRef.current.value,
        address: addressRef.current.value,
        city: cityRef.current.value,
        state: stateRef.current.value,
        phone: phoneRef.current.value,
        password: passwordRef.current.value,
      }),
    }).then(async (res) => {
      if (res.status == 200) {
        const jwt = await res.json();
        window.location.href = window.location.origin + "/chatbot/" + jwtDecode(jwt).payload.pid;
      } else {
        console.log(res);
      }
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="text" value="test@test.com" />
        <input type="text" ref={fNameRef} placeholder="first name" />
        <input type="text" ref={lNameRef} placeholder="last name" />
        <input type="text" ref={genderRef} placeholder="gender" />
        <input type="text" ref={dobRef} placeholder="dob (mm/dd/yyyy)" />
        <input type="text" ref={heightRef} placeholder="height" />
        <input type="text" ref={weightRef} placeholder="weight" />
        <input
          type="text"
          ref={pastMentalHealthConditionsRef}
          placeholder="past mental health conditions"
        />
        <input
          type="text"
          ref={pastFamilyMentalHealthConditionsRef}
          placeholder="past family mental health conditions"
        />
        <input type="text" ref={pastHealthConditionsRef} placeholder="past health conditions" />
        <input
          type="text"
          ref={pastFamilyHealthConditionsRef}
          placeholder="past family health conditions"
        />
        <input type="text" ref={medicationsRef} placeholder="medications" />
        <input type="text" ref={addressRef} placeholder="street address" />
        <input type="text" ref={cityRef} placeholder="city" />
        <input type="text" ref={stateRef} placeholder="state" />
        <input type="text" ref={phoneRef} placeholder="phone" />
        <input type="password" ref={passwordRef} placeholder="password" />
        <input type="password" ref={confirmPasswordRef} placeholder="confirm password" />
        <input type="submit" className="p-2.5" value="submit" />
      </form>
    </div>
  );
}

import React, {useState, useEffect, useCallback, useRef} from "react";
import {nanoid} from "nanoid";
import {lightFormat} from "date-fns";
import PreviewForm from "./PreviewForm";
import {crud} from "../crudRequests";

export default function FormsStatus({state, patient, socket}) {
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [assignedForms, setAssignedForms] = useState([]);
  const [completedForms, setCompletedForms] = useState([]);
  const [STATE_previewForm, STATE_setPreviewForm] = useState(null);
  const abortControllerRef = useRef(new AbortController());

  const updatedFormValues = useCallback(() => {
    crud(
      state,
      [
        {
          db: state.db,
          collection: "form_submissions",
          parameters: [{pid: patient.pid}],
          method: "find",
        },
      ],
      null,
      abortControllerRef.current.signal
    )
      .then(async (fsub) => {
        let res = await crud(
          state,
          [
            {
              db: state.db,
              collection: "forms",
              parameters: [{}],
              method: "find",
            },
          ],
          null,
          abortControllerRef.current.signal
        );
        setForms(res.data[0]);

        let arr = fsub.data[0].reduce(
          (ac, e) => {
            if (e.completed) {
              ac[1].push(e);
            } else ac[0].push(e);
            return ac;
          },
          [[], []]
        );
        setAssignedForms(arr[0]);
        setCompletedForms(arr[1]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
      });
  }, [state]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    updatedFormValues();

    return () => {
      abortControllerRef.current.abort();
    };
  }, [state]);

  //console.log(assignedForms);
  //console.log(completedForms);

  return (
    <div className="relative min-w-[35rem] rounded-[10px] py-8  flex flex-col items-start  space-y-10 border overflow-hidden">
      {loading && (
        <div className="h-full w-full  inset-0 bg-white/80 z-[99999] absolute flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}

      <div className=" space-y-10 w-full overflow-y-scroll sbar2 ">
        <div className="space-y-1">
          <div className="flex justify-start items-start space-x-2 px-10">
            <h1 className="text-gray-600 font-semibold text-2xl leading-[25px]">
              Assigned Forms
            </h1>
            {/*<span className="rounded-full bg-[#E34848] text-xs text-white p-[2px] px-2  flex justify-center items-center">
              overdue
      </span>*/}
          </div>
          <div className="overflow-y-scroll sbar3 max-h-[16rem] px-10 mr-2">
            {assignedForms.length > 0 && (
              <ul className="flex  items-start  flex-wrap  py-2">
                {assignedForms.map((e, i) => {
                  return (
                    <FormsCards
                      key={i}
                      data={e}
                      STATE_setPreviewForm={STATE_setPreviewForm}
                    />
                  );
                })}
              </ul>
            )}
            {assignedForms.length === 0 && !loading && (
              <div className="text-gray-400 font-medium">No form assigned.</div>
            )}
          </div>
        </div>

        <div className="space-y-1">
          <div className="flex justify-start items-start space-x-2 px-10">
            <h1 className="text-gray-600 font-semibold text-2xl leading-[25px]">
              Completed Forms
            </h1>
          </div>
          <div className="overflow-y-scroll sbar3 max-h-[16rem] px-10 mr-2">
            {completedForms.length > 0 && (
              <ul className="flex  items-start  flex-wrap py-2">
                {completedForms.map((e, i) => {
                  return (
                    <FormsCards
                      key={i}
                      data={e}
                      STATE_setPreviewForm={STATE_setPreviewForm}
                    />
                  );
                })}
              </ul>
            )}
            {completedForms.length === 0 && !loading && (
              <div className="text-gray-400 font-medium">
                No form to complete.
              </div>
            )}
          </div>
        </div>
      </div>

      {STATE_previewForm && (
        <PreviewForm
          data={STATE_previewForm}
          type={STATE_previewForm.completed ? "completed" : "tocomplete"}
          close={STATE_setPreviewForm}
          state={state}
          updatedFormValues={updatedFormValues}
          setLoading={setLoading}
          socket={socket}
        />
      )}
    </div>
  );
}

function FormsCards({data, onClick, STATE_setPreviewForm}) {
  return (
    <li
      className={`${
        data.completed ? "bg-gray-200" : "bg-gray-100"
      } rounded-[10px] mt-4 mr-4 w-[15rem] min-h-[6rem] cursor-pointer`}
      onClick={() => {
        STATE_setPreviewForm(data);
      }}
    >
      <p className="rounded-full bg-gray-700 p-1"></p>
      <p className="text-gray-500 font-semibold p-4  px-10 text-center">
        {data.formTitle}
      </p>
      <p className="text-end pb-2 pr-4">
        <span className="text-gray-500 text-xs">assigned: </span>
        <span className="text-gray-500 text-xs font-bold">
          {lightFormat(new Date(data?.assignedDate), "dd/MM/yy")}
        </span>
      </p>
    </li>
  );
}

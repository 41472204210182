import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import Navbar from "../components/Patient.Navbar";
import Notifications from "../components/Patient.Notifications";
import FormsStatus from "../components/FormsStatus";
import {patientReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import Layout from "../components/Layout";

export default function Forms(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [tab, setTab] = useState("prs");
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const sessionStart = useRef(true);
  let room = dashState.patient?.pid;

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  const socket = useSocket("https://blh-server-2001610adcd9.herokuapp.com/", room, dispatch);
  //const socket = useSocket('http://localhost:3000/', room, dispatch);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  return (
    <>
      <Layout state={{...dashState}}>
        <div ref={loadedRef} style={{opacity: 0}} className=" h-[100vh]">
          <div className="relative h-full pb-12 px-10">
            <main className="flex  items-start  overflow-hidden">
              <section className="p-2 flex max-w-[40rem] justify-start items-start flex-col space-y-14 h-[calc(100vh-10rem)]  overflow-y-auto">
                <FormsStatus
                  state={dashState}
                  patient={dashState.patient}
                  socket={socket}
                />
              </section>
            </main>
          </div>
        </div>
      </Layout>
    </>
  );
}

import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {verifyUser, jwtDecode} from "../../authRequests";
import {crud} from "../../crudRequests";
import {patientReducer, init} from "../../additional_files/reducer";
import Layout from "../Layout";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/react";
import DateSelect from "../DateSelect";
import {AddIcon} from "@chakra-ui/icons";
import moment from "moment";

const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

function isoToDatetimeLocal(isoDate) {
  try {
    const date = new Date(isoDate);

    // Convert date to YYYY-MM-DDTHH:mm format
    const formattedDate = date.toISOString().slice(0, 16);

    return formattedDate;
  } catch (e) {
    return "";
  }
}

function datetimeLocalToISO(datetimeLocal) {
  try {
    const date = new Date(datetimeLocal);

    // Convert date to ISO format
    const isoDate = date.toISOString();

    return isoDate;
  } catch (e) {
    return "";
  }
}

const ActivityMapper = ({factor, getData, updateData, setData}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (getData("activities") === undefined) return;
    if (
      getData("activities").filter((item) => item.type === factor.name)
        ?.length > 0 &&
      !enabled
    ) {
      setEnabled(true);
    }
  }, [getData("activities")]);

  useEffect(() => {
    setEnabled(false);
  }, [getData("day")]);

  const toast = useToast();

  const updateActivity = (index, newData) => {
    setData((prev) => {
      let newActivities = [...prev.activities];
      const updatedObj = {...newActivities[index], ...newData};
      newActivities[index] = updatedObj;
      if (
        newActivities[index]?.start_time === null ||
        newActivities[index]?.end_time === null
      ) {
        return;
      }

      if (moment(updatedObj.start_time).isAfter(moment(updatedObj.end_time))) {
        toast({
          title: "Invalid time",
          description: "Start time cannot be after end time",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return {...prev, activities: newActivities};
    });
  };

  const currActivity = {
    start_time: getCurrentDateTime(),
    end_time: getCurrentDateTime(),
    type: factor?.name,
  };

  const handleToggle = () => {
    if (enabled) {
      const otherActivities = getData("activities").filter(
        (item) => item.type !== factor.name
      );
      // remove this activity when the toggle is disabled
      updateData("activities")([...otherActivities]);
    } else {
      // add the activity to the list
      updateData("activities")([...getData("activities"), currActivity]);
    }
    setEnabled((prev) => !prev);
  };

  return (
    <Grid placeItems="center">
      <FormControl isRequired>
        <Grid templateColumns="1fr auto" maxW="400px">
          <FormLabel>{factor.label}</FormLabel>
          <Button onClick={handleToggle}>
            <Switch onChange={handleToggle} isChecked={enabled} />
          </Button>
        </Grid>
      </FormControl>
      <Grid placeItems="center" justifySelf="start">
        {enabled &&
          getData("activities")?.map((item, i) => {
            if (item.type !== factor.name) return null;

            const isLast = i === getData("activities").length - 1;
            return (
              // <Grid justifyContent="start" placeItems="center">
              <Flex key={i} gap={2} m={4} alignItems="center">
                <Input
                  width="150px"
                  type="datetime-local"
                  placeholder="Start Time"
                  fontSize="sm"
                  onChange={(e) =>
                    updateActivity(i, {
                      start_time: e.target.value,
                    })
                  }
                  value={item?.start_time}
                />
                <Text fontSize="xl" fontWeight="bold">
                  {" "}
                  -{" "}
                </Text>
                <Input
                  width="150px"
                  type="datetime-local"
                  placeholder="End Time"
                  fontSize="sm"
                  onChange={(e) =>
                    updateActivity(i, {
                      end_time: e.target.value,
                    })
                  }
                  value={item?.end_time}
                />
              </Flex>
              // </Grid>
            );
          })}
        {enabled && (
          <Button
            size="sm"
            onClick={() =>
              updateData("activities")([...getData("activities"), currActivity])
            }
            bg="black"
            borderRadius="50%"
            p="0"
            mb={4}
          >
            <AddIcon w="10px" color="white" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const useFormData = (initialData) => {
  const [data, setData] = useState(initialData);

  const updateData = (name) => (value) => {
    setData((prev) => ({...prev, [name]: value}));
  };

  const getData = (name) => {
    return data[name];
  };

  return [getData, updateData, data, setData];
};

export default function SleepDiary(props) {
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const toast = useToast();
  const selectedAppointment = useRef(null);
  selectedAppointment.current = dashState.selectedAppointment;

  let room = dashState.patient?.pid;

  const loadedRef = useRef(null);
  const id = state && jwtDecode(state.jwt).id;

  const [validDates, setValidDates] = useState([new Date()]);

  const dateToString = (date) => {
    return date.toISOString().slice(0, 10);
  };

  const queryValidDates = (id) => {
    crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "sleep_diary",
        parameters: [{pid: id}],
        method: "find",
      },
    ]).then((res) => {
      const dates = res.data[0].map((entry) => entry.day);
      setValidDates([...dates, dateToString(new Date())]);
    });
  };

  useEffect(() => {
    verifyUser(state ? state.jwt : "", loadedRef);

    const date = new Date();

    updateData("day")(date);
    queryValidDates(id);
  }, []);

  const [getData, updateData, data, setData] = useFormData({
    day: new Date(),
    time_inbed: null,
    time_asleep: null,
    time_awake: null,
    time_outofbed: null,
    dayType: "work",
    activities: [],
    additionalInfo: "",
  });

  const upsertDiary = async (id, date) => {
    // convert everything to isotime
    let isoData = {...data};
    isoData.time_inbed = datetimeLocalToISO(data.time_inbed);
    isoData.time_asleep = datetimeLocalToISO(data.time_asleep);
    isoData.time_awake = datetimeLocalToISO(data.time_awake);
    isoData.time_outofbed = datetimeLocalToISO(data.time_outofbed);
    isoData.activities.map((item) => ({
      ...item,
      start_time: datetimeLocalToISO(item.start_time),
      end_time: datetimeLocalToISO(item.end_time),
    }));

    // Check if the current time is AM or PM
    const currentHour = new Date().getHours();
    let updatedDate = new Date(date);

    // If it's PM, set updatedDate as the next day
    if (currentHour >= 12) {
      updatedDate.setDate(updatedDate.getDate() + 1);
    }

    await crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "sleep_diary",
        parameters: [
          {pid: id, day: date},
          {
            $set: {
              ...data,
              day: date,
              pid: id,
            },
          },
          {upsert: true},
        ],
        method: "updateOne",
      },
    ]);
  };

  const handleSubmit = () => {
    console.log({data});
    if (
      !getData("time_inbed") ||
      !getData("time_asleep") ||
      !getData("time_awake") ||
      !getData("time_outofbed") ||
      !getData("dayType")
    ) {
      toast({
        title: "Form incomplete",
        description: "Please complete all the required fields",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    toast.promise(upsertDiary(id, dateToString(getData("day"))), {
      success: {title: "Success", description: "Entry recorded"},
      error: {title: "Please try again", description: "Something wrong"},
      loading: {title: "Loading...", description: "Please wait"},
    });
  };

  const baseFactors = [
    {
      label: "What time did you get in bed?",
      value: getData("time_inbed"),
      onChange: (e) => updateData("time_inbed")(e.target.value),
      type: "time",
    },
    {
      label: "What time did fall asleep?",
      value: getData("time_asleep"),
      onChange: (e) => updateData("time_asleep")(e.target.value),
      type: "time",
    },
    {
      label: "When did you wake up?",
      value: getData("time_awake"),
      onChange: (e) => updateData("time_awake")(e.target.value),
      type: "time",
    },
    {
      label: "When did you get out of bed?",
      value: getData("time_outofbed"),
      onChange: (e) => updateData("time_outofbed")(e.target.value),
      type: "time",
    },
    {
      label: "What type of day was it?",
      value: getData("dayType"),
      onChange: (e) => updateData("dayType")(e.target.value),
      type: "select",
      options: ["Work", "Day off", "Sick day", "Vacation", "Other"],
    },
  ];

  const additionalFactors = [
    {
      label: "Did you engage in exercise?",
      name: "exercise",
    },
    {
      label: "Did you take any naps?",
      name: "nap",
    },
    {
      label: "Did you have any awakenings?",
      name: "awakenings",
    },
  ];

  const riskFactors = [
    {
      label: "Did you consume any caffeine?",
      name: "caffeine",
    },
    {
      label: "Did you consume any alcohol?",
      name: "alchohol",
    },
    {
      label: "Did you consume any drugs?",
      name: "drugs",
    },
  ];

  const mapper = (items) => {
    return items.map((factor) => {
      return (
        <FormControl isRequired>
          <Grid templateColumns="1fr auto" maxW="400px">
            <FormLabel>{factor.label}</FormLabel>
            {factor.type === "time" && (
              <Input
                value={factor.value}
                onChange={factor.onChange}
                width="150px"
                type="datetime-local"
              />
            )}
            {factor.type === "select" && (
              <>
                <Select
                  onChange={factor.onChange}
                  value={factor?.value?.toLowerCase()?.replace(" ", "-")}
                  width="150px"
                  placeholder={factor?.placeholder || ""}
                >
                  {factor.options.map((option) => {
                    return (
                      <option value={option.toLowerCase().replace(" ", "-")}>
                        {option}
                      </option>
                    );
                  })}
                </Select>
              </>
            )}
            {factor.type === "switch" && <Switch />}
          </Grid>
        </FormControl>
      );
    });
  };

  const loadEntry = async (id, date) => {
    setData((prev) => ({
      day: prev.day,
      time_inbed: null,
      time_asleep: null,
      time_awake: null,
      time_outofbed: null,
      dayType: "work",
      activities: [],
      additionalInfo: "",
    }));
    let res = await crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "sleep_diary",
        parameters: [{pid: id, day: date}],
        method: "findOne",
      },
    ]);
    const entry = res?.data?.[0];
    if (entry) {
      updateData("time_inbed")(isoToDatetimeLocal(entry?.time_inbed) || "");
      updateData("time_asleep")(isoToDatetimeLocal(entry?.time_asleep) || "");
      updateData("time_awake")(isoToDatetimeLocal(entry?.time_awake) || "");
      updateData("time_outofbed")(
        isoToDatetimeLocal(entry?.time_outofbed) || ""
      );
      updateData("additionalInfo")(entry?.additionalInfo || "");
      updateData("dayType")(entry?.dayType || "");
      updateData("activities")(entry?.activities || []);
      if (entry?.activities) {
        const datetimeActivities = entry?.activities.map((item) => ({
          ...item,
          start_time: isoToDatetimeLocal(item.start_time),
          end_time: isoToDatetimeLocal(item.end_time),
        }));
        updateData("activities")(datetimeActivities || []);
      }

      console.log({entry});
    }
  };

  useEffect(() => {
    loadEntry(id, dateToString(getData("day")));
  }, []);

  return (
    <>
      <Layout state={{...dashState}}>
        <Heading>Sleep Diary</Heading>
        <DateSelect
          selectedDate={getData("day")}
          setSelectedDate={(date) => {
            updateData("day")(date);
            // load the data from that date and set it to state
            loadEntry(id, dateToString(date));
          }}
          validDates={validDates}
        />

        <Box
          pointerEvents={
            dateToString(getData("day")) === dateToString(new Date())
              ? "auto"
              : "none"
          }
          cursor={
            dateToString(getData("day")) === dateToString(new Date())
              ? "auto"
              : "not-allowed"
          }
          bg="white"
          p={8}
          borderRadius={4}
        >
          <Text fontSize="lg" mb={4}>
            Please fill out the fields below to the best of your knowledge and
            complete your sleep diary for {getData("day").toLocaleDateString()}
          </Text>
          <Heading mb={2} mt={4} as="h5" size="md">
            Base factors
          </Heading>
          {mapper(baseFactors)}
          <Heading mb={2} mt={4} as="h5" size="md">
            Additional factors
          </Heading>
          {additionalFactors.map((factor, index) => (
            <ActivityMapper
              factor={factor}
              key={index}
              getData={getData}
              updateData={updateData}
              setData={setData}
            />
          ))}
          <Heading mb={2} mt={4} as="h5" size="md">
            Risk factors
          </Heading>
          {riskFactors.map((factor, index) => (
            <ActivityMapper
              factor={factor}
              key={index}
              getData={getData}
              updateData={updateData}
              setData={setData}
            />
          ))}
          <Heading mb={2} mt={4} as="h3" size="md">
            Additional information
          </Heading>
          <FormControl>
            <Textarea
              value={getData("additionalInfo")}
              onChange={(e) => updateData("additionalInfo")(e.target.value)}
              placeholder="Additional information"
            />
          </FormControl>
          {dateToString(new Date()) === dateToString(getData("day")) && (
            <Button onClick={handleSubmit} mt={4}>
              Submit
            </Button>
          )}
        </Box>
      </Layout>
    </>
  );
}

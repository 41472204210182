import {useState, useEffect} from "react";
import {Box, Text, Button, IconButton, useToast} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";
import {processFlow} from "./helpers/processFlow";
import HelperText from "./HelperText";
import Input from "./Input";
import {ThreeDots} from "react-loader-spinner";

export default function MainForm({
  dashState,
  disorders,
  symptoms,
  qualifierQuestions,
  durationQuestions,
  followupQuestions,
  context,
  setContext,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    processFlow(
      dashState,
      setIsLoading,
      disorders,
      symptoms,
      qualifierQuestions,
      durationQuestions,
      followupQuestions,
      null,
      setContext,
      toast
    );
  }, []);

  const handleSubmit = (e) => {
    e && e.preventDefault();
    processFlow(
      dashState,
      setIsLoading,
      disorders,
      symptoms,
      qualifierQuestions,
      durationQuestions,
      followupQuestions,
      context,
      setContext,
      toast
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        mt="-4"
        bg="white"
        w={"600px"}
        h={"80vh"}
        p="12"
        boxShadow={"lg"}
        position="relative"
      >
        {context?.finished ? (
          <Text
            textAlign={"center"}
            fontSize={"20px"}
            lineHeight={"6"}
            fontWeight={"regular"}
            my="6"
            color="gray.700"
          >
            <b>Your intake has now been successfully concluded.</b>
            <br />
            <br />
            Thank you for going through this process as we understand it could
            bring up difficult emotions. Please wait for your healthcare
            provider's instructions.
          </Text>
        ) : (
          <>
            <Text fontSize={"20px"} lineHeight={"6"} fontWeight={"regular"}>
              {!isLoading && context?.currentPreface}
            </Text>
            <Text
              fontSize={
                context?.currentQuestion.split("").length > 120
                  ? "20px"
                  : "28px"
              }
              lineHeight={
                context?.currentQuestion.split("").length > 120 ? "6" : "10"
              }
              fontWeight={"medium"}
              my="5"
            >
              {isLoading ? (
                <Box mt="-12">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="black"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </Box>
              ) : (
                <>
                  {context?.currentQuestion}
                  <HelperText meaning={context?.currentMeaning} />
                </>
              )}
            </Text>
            {!isLoading && (
              <>
                <Input context={context} setContext={setContext} />
                <Box textAlign={"right"}>
                  <IconButton
                    aria-label="Next Question"
                    colorScheme="blue"
                    icon={<CheckIcon />}
                    type="submit"
                    rounded="full"
                    size="md"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </form>
  );
}

import {useReducer, useState, useEffect, useRef, useMemo} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {cardInfoReducer} from "../additional_files/cardInfoReducer";
import {setCardInfo} from "../authRequests";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {MdDeleteForever, MdModeEditOutline} from "react-icons/md";
import {
  crud,
  getPaymentMethods,
  setDefaultPaymentMethods,
  createPaymentMethod,
  detachPaymentMethods,
} from "../crudRequests";
import {nanoid} from "nanoid";
import Swal from "sweetalert2";

export default function CardsInfo({dashState, dashDispatch, cardsRef}) {
  const [cards, setCards] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  if (cardsRef) cardsRef.current = cards;

  let patient = dashState.patient;

  const location = useMemo(() => {
    let lid = Array.isArray(patient.lid) ? patient.lid[0] : patient.lid;
    return dashState.locations.find((l) => l.lid === lid);
  }, []);

  useEffect(function () {
    if (dashState.db) {
      getPaymentMethods(dashState, patient.pid, location?.lid, location.lid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
        })
        .catch((error) => {
          setErrorMessage(error.response?.data || error.message);
          console.log(error);
        });
    }
  }, []);

  const stripePromise = useMemo(() => {
    return loadStripe(location?.stpPublicKey || "pk_test_");
  }, []);

  const appearance = {
    theme: "stripe",
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".Label": {color: "#A7A7A7", fontSize: "13px"},
      ".Error": {color: "#f87171", fontSize: "12px"},
      ".Tab:hover": {
        color: "var(--colorText)",
      },

      // See all supported class names and selector syntax below
    },
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#A7A7A7",
      colorDanger: "#df1b41",
      borderRadius: "6px",
      // See all possible variables below
    },
  };
  return (
    <div className={"bg-[#FFF] rounded-lg"}>
      {
        <div className={"w-[33rem] h-full"}>
          <div
            className={
              "flex h-full  justify-center items-center align-middle py-8 px-8 mx-auto"
            }
          >
            <div className="flex flex-1  h-full flex-col overflow-y-hidden">
              {successMessage && (
                <div>
                  <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-green-500">
                      <svg
                        className="w-6 h-6 mx-2 text-white fill-current"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                      </svg>
                    </div>
                    <div className="px-4 py-2 -mx-3">
                      <div className="mx-3">
                        <span className="font-semibold text-green-500 dark:text-green-400">
                          Card Updated
                        </span>
                        <p className="text-sm text-gray-600 dark:text-gray-200 font-medium">
                          Card information has been updated successfully.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {errorMessage && (
                <div className>
                  <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-red-500">
                      <svg
                        className="w-6 h-6 mx-2 text-white fill-current"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                      </svg>
                    </div>
                    <div className="px-4 py-2 -mx-3">
                      <div className="mx-3">
                        <span className="font-semibold text-red-500 dark:text-red-400">
                          Error
                        </span>
                        <p className="text-sm text-gray-600 dark:text-gray-200 font-medium">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {
                <div className="flex  justify-between -mb-7">
                  <h3
                    className="text-3xl font-medium  text-gray-800  mb-8"
                    id="modal-title"
                  >
                    Payment Information
                  </h3>
                </div>
              }
              <CardsList
                setSuccessMessage={setSuccessMessage}
                patient={patient}
                setErrorMessage={setErrorMessage}
                cards={cards}
                setCards={setCards}
                dashState={dashState}
              />
              <div className="px-4 mt-6 overflow-y-scroll flex-1 w-full max-h-[18rem] pb-1 sbar2">
                <Elements
                  stripe={stripePromise}
                  options={{
                    mode: "setup",
                    currency: "cad",
                    paymentMethodCreation: "manual",
                    appearance,
                    locale: "en",
                  }}
                >
                  <CheckoutForm
                    setSuccessMessage={setSuccessMessage}
                    patient={patient}
                    setErrorMessage={setErrorMessage}
                    cards={cards}
                    setCards={setCards}
                    dashState={dashState}
                    location={location}
                  />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

function CheckoutForm({
  cards,
  setErrorMessage,
  setSuccessMessage,
  setCards,
  patient,
  dashState,
  location,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [addressReady, setAddressReady] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      var addressElementRef = elements.getElement("address");
      var paymentElement = elements.getElement("payment");
      // console.log(elements);
      setIsLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      await elements.submit();

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        elements,
      });

      if (paymentMethod) {
        createPaymentMethod(
          dashState,
          patient.pid,
          paymentMethod.id,
          location?.lid
        )
          .then((res) => {
            setErrorMessage("");
            setCards((prev) => {
              //console.log(res.data);
              return [{...paymentMethod, default: true}].concat(
                prev.map((card) => ({...card, default: false}))
              );
            });
            setIsLoading(false);
            addressElementRef.clear();
            paymentElement.clear();
          })
          .catch((e) => {
            setErrorMessage(e.response?.data || e.message);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      console.log(error);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    terms: {card: "never"},
  };

  return (
    <form id="payment-form sbar2" onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => {
          setPaymentReady(true);
        }}
        onLoadError={(e) => {
          setErrorMessage(e.error?.message);
        }}
        options={CARD_ELEMENT_OPTIONS}
        className="card-element flex flex-col justify-start py-4 items-center w-full "
      />
      {elements?.getElement("payment") && (
        <AddressElement
          onReady={() => {
            setAddressReady(true);
          }}
          onLoadError={(e) => {
            setErrorMessage(e.error.message);
            console.log(e);
          }}
          className="card-element flex flex-col justify-start py-4 items-center"
          options={{mode: "billing", autocomplete: {mode: "automatic"}}}
        />
      )}
      {paymentReady && addressReady && (
        <div className="flex justify-center items-center mt-6 w-full ">
          <button
            className={`${
              isLoading ||
              !stripe ||
              !elements ||
              !elements?.getElement("payment")
                ? "cursor-not-allowed"
                : ""
            } w-full bg-gray-800 flex justify-center items-center cursor-pointer text-white text-[1rem] rounded-lg py-3 px-2 text-center font-semibold disabled:bg-gray-500 disabled:cursor-not-allowed`}
            value="Submit"
            disabled={
              isLoading ||
              !stripe ||
              !elements ||
              !elements?.getElement("payment")
            }
          >
            Save Payment Method
            {isLoading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      )}
    </form>
  );
}

function CardsList({
  cards,
  setErrorMessage,
  setSuccessMessage,
  setCards,
  patient,
  dashState,
}) {
  const [updating, setUpdating] = useState(false);

  return (
    <div className="space-y-2 mt-4 w-full">
      <div className="rounded-[10px] py-2 bg-gray-800 text-background font-semibold text-center mr-2">
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Default
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Action
          </li>
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[10rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-white text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    backgroundColor: c.default ? "#6b7280" : "#d1d5db",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#5754FF] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          if (!updating) {
                            setSuccessMessage("");
                            setErrorMessage("");
                            setUpdating(true);
                            setDefaultPaymentMethods(
                              dashState,
                              patient.pid,
                              c.id
                            )
                              .then((res) => {
                                setErrorMessage("");
                                setCards((prev) => {
                                  // console.log(res.data);

                                  return prev.map((card) => {
                                    if (card.id === res.data) {
                                      return {...card, default: true};
                                    } else return {...card, default: false};
                                  });
                                });
                                setUpdating(false);
                              })
                              .catch((e) => {
                                setErrorMessage(e.response.data);
                                setUpdating(false);
                              });
                          }
                        }}
                        style={{
                          backgroundColor: c.default ? "#3f3f46" : "#FFFFFF",
                          boxShadow: !c.default && "0 0 0 2px inset #3f3f46",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform: c.default
                              ? "translateX(55px)"
                              : "translateX(0)",
                            backgroundColor: c.default ? "#FFFFFF" : "#3f3f46",
                          }}
                        ></p>
                      </div>
                    </li>

                    <li className=" flex justify-center text-gray-100 items-center flex-1  text-[16px] space-x-2">
                      <span
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          if (!updating) {
                            if (c.default) {
                              Swal.fire({
                                icon: "error",
                                title: "Removing your default payment method!",
                                text: "Please set another card as your default payment method before deleting this one!",
                              });
                            } else
                              Swal.fire({
                                title: `Are you sure you want to remove this card?`,
                                html: `This card will be removed from your payment methods.`,
                                icon: "warning",
                                showCancelButton: true,
                              }).then((value) => {
                                if (value.isConfirmed) {
                                  setSuccessMessage("");
                                  setErrorMessage("");
                                  setUpdating(true);
                                  detachPaymentMethods(
                                    dashState,
                                    patient.pid,
                                    c.id
                                  )
                                    .then((res) => {
                                      setErrorMessage("");
                                      setCards((prev) => {
                                        // console.log(res.data);
                                        return prev.filter(
                                          (card) => card.id !== res.data
                                        );
                                      });
                                      setUpdating(false);
                                    })
                                    .catch((e) => {
                                      setErrorMessage(
                                        e.response?.data || e.message
                                      );
                                      setUpdating(false);
                                    });
                                }
                              });
                          }
                        }}
                      >
                        <MdDeleteForever />
                      </span>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div className=" rounded-[10px] mt-0 py-2 bg-[#d4d4d8] text-sm text-gray-700 text-opacity-75 font-semibold text-center h-fit">
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import avatar from "./chat_logo.png";
import "./bot.css";

const BotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <img src={avatar} alt="Bot Avatar" width="40px" height="40px" className="bot-avatar" />
      </div>
    </div>
  );
};

export default BotAvatar;

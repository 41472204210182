import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import Navbar from "../components/Patient.Navbar";
import Notifications from "../components/Patient.Notifications";
import Chat from "../components/Chat";
import {patientReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import AppointmentsList from "../components/AppointmentsList";
import SelfSchedule from "../components/SelfSchedule";
import AppointmentModal from "../components/Clinic.AppointmentModal";
import swal from "sweetalert";
import Layout from "../components/Layout";
import {Heading, Text} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/react";

export default function Dashboard(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const [showSelfSch, setShowSelfSch] = useState(false);
  const toast = useToast();
  const selectedAppointment = useRef(null);
  selectedAppointment.current = dashState.selectedAppointment;

  const sessionStart = useRef(true);
  let room = dashState.patient?.pid;

  let unconfirmedCount = useMemo(() => {
    if (dashState.appointments && dashState.appointments.length > 0) {
      return dashState.appointments.filter((appt) => {
        return (
          !appt.pConfirmed &&
          (apptStatus(appt) === "Unconfirmed" ||
            (apptStatus(appt) === "Rescheduled" &&
              appt.rescheduled.split("|")[1] !== dashState.patient.pid))
        );
      }).length;
    }
    return 0;
  }, [dashState.appointments]);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  const socket = useSocket("https://webserver.brightlight.ai/", room, dispatch);
  //const socket = useSocket("http://localhost:3000/", room, dispatch);

  useEffect(() => {
    const f = (e) => {
      if (selectedAppointment.current)
        dispatch({type: "SELECT_APPOINTMENT", payload: null});
    };

    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowSelfSch(false);
      }
    };

    window.addEventListener("keydown", handleEscapeKeyDown);
    window.addEventListener("click", f);

    return () => {
      window.removeEventListener("click", f);
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  function selectAppointment(appt) {
    dispatch({type: "SELECT_APPOINTMENT", payload: appt});
  }

  useEffect(() => {
    if (dashState.patient && !sessionStart.current) {
      loadDataForPatientLogin(
        {
          ...dashState,
          destination: "../",
        },
        navigate
      );
    }
  }, []);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  useEffect(() => {
    if (state?.bookNow) {
      toast({
        title: "Booked appointment!",
        description:
          "Your appointment has been successfully scheduled, select it to see the details!",
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      dispatch({type: "SET_BOOKNOW", payload: false});
    }
  }, [state?.bookNow]);

  return (
    <>
      <Layout state={{...dashState}}>
        <Heading m="4">{`Welcome, ${
          dashState?.userType === "relationship"
            ? dashState?.relationship?.name || ""
            : dashState?.patient?.fName
        }`}</Heading>
        {dashState?.userType === "relationship" && (
          <Text m="4">
            <span className="text-gray-800 font-medium">
              {`You have accessed on behalf of
                ${
                  dashState?.patient?.fName + " " + dashState?.patient?.lName
                }.`}
            </span>
          </Text>
        )}
        <Text m="5">
          {dashState?.patient?.gender ? (
            "You have no pending tasks."
          ) : (
            <span>
              Please fill out your{" "}
              <span
                onClick={() => {
                  navigate("../user", {
                    state: {...dashState},
                  });
                }}
                className="text-blue-500 cursor-pointer"
              >
                patient information
              </span>
              .
            </span>
          )}
        </Text>
      </Layout>
    </>
  );

  function apptStatus(appt) {
    let statusMap = {
      noShow: "No Show",
      cancelled: "Cancelled",
      pConfirmed: "Confirmed",
      rescheduled: "Rescheduled",
    };

    for (let [key, val] of Object.entries(statusMap)) {
      if (appt[key]) return val;
    }
    return "Unconfirmed";
  }
}

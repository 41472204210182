import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  lightFormat,
  startOfMonth,
  endOfMonth,
} from "date-fns";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function filterConfirmed(appointments) {
  return appointments.filter((e) => e.pConfirmed);
}

function filterRescheduled(appointments) {
  let higherPriorityFilters = [filterConfirmed, filterCancelled, filterNoShow];
  return appointments.filter((e) => {
    for (let filter of higherPriorityFilters)
      if (filter([e]).length > 0) return false;
    return e.rescheduled;
  });
}
function filterCancelled(appointments) {
  return appointments.filter((e) => e.cancelled);
}
function filterNoShow(appointments) {
  return appointments.filter((e) => e.noShow);
}

function filterUnconfirmed(appointments) {
  let restOrFilters = [filterRescheduled, filterCancelled, filterNoShow];
  return appointments.filter((e) => {
    for (let filter of restOrFilters) if (filter([e]).length > 0) return false;
    return !e.pConfirmed;
  });
}

function filterByMonth(d, appointments) {
  let Mstart = startOfMonth(d);
  let Mend = endOfMonth(d);
  let Wstart = startOfWeek(Mstart, {weekStartsOn: 1});
  let Wend = endOfWeek(Mend, {weekStartsOn: 1});

  return appointments.filter((appt) => {
    let {date} = appt.ISOdate ? isoToApptDate(appt) : {date: appt.date};
    let month = date.split(",")[1].split(" ")[1];
    let day = parseInt(date.split(",")[1].split(" ")[2]);
    let year = parseInt(date.split(",")[2]);
    let apptDate = new Date(
      year,
      months.findIndex((m) => m === month),
      day
    );

    return isWithinInterval(apptDate, {start: Wstart, end: Wend});
  });
}

function filterByWeek(d, appointments) {
  let start = startOfWeek(d, {weekStartsOn: 1});
  let end = endOfWeek(d, {weekStartsOn: 1});

  return appointments.filter((appt) => {
    let {date} = appt.ISOdate ? isoToApptDate(appt) : {date: appt.date};
    let month = date.split(",")[1].split(" ")[1];
    let day = parseInt(date.split(",")[1].split(" ")[2]);
    let year = parseInt(date.split(",")[2]);
    let apptDate = new Date(
      year,
      months.findIndex((m) => m === month),
      day
    );

    return isWithinInterval(apptDate, {start, end});
  });
}

function filterByDay(d, appointments) {
  return appointments.filter((appt) => {
    let {date} = appt.ISOdate ? isoToApptDate(appt) : {date: appt.date};
    let month = date.split(",")[1].split(" ")[1];
    let day = parseInt(date.split(",")[1].split(" ")[2]);
    let year = parseInt(date.split(",")[2]);
    let apptDate = new Date(
      year,
      months.findIndex((m) => m === month),
      day
    );

    return isSameDay(apptDate, d);
  });
}

function isoToApptDate(appt) {
  try {
    let d = new Date(appt.ISOdate || appt);
    let date = `${weekday[d.getDay()]}, ${
      months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()}`;
    let time = lightFormat(d, "h:mm aa");
    return {date, time};
  } catch (error) {
    console.log(appt, "ISOdate Error");
    return {date: appt.date, time: appt.time};
  }
}

function appointmentStatus(appt) {
  let statusArr = [
    "noShow",
    "cancelled",
    "pConfirmed",
    "rescheduled",
    "unconfirmed",
  ];

  for (let st of statusArr) {
    if (appt[st]) return st;
  }
  return "unconfirmed";
}

function statusInDays(appointments) {
  let map = new Map();
  for (let appt of appointments) {
    let {date: dateString} = appt.ISOdate
      ? isoToApptDate(appt)
      : {date: appt.date};
    if (map.has(dateString)) {
      map.get(dateString).add(appointmentStatus(appt));
    } else {
      map.set(dateString, new Set([appointmentStatus(appt)]));
    }
  }
  return map;
}

function appointmentsInDays(appointments) {
  let map = new Map();
  for (let appt of appointments) {
    let {date: dateString} = appt.ISOdate
      ? isoToApptDate(appt)
      : {date: appt.date};
    if (map.has(dateString)) {
      map.get(dateString).push(appt);
    } else {
      map.set(dateString, [appt]);
    }
  }
  for (let [key, value] of map) {
    value.sort(
      (a, b) => new Date(a.ISOdate).getTime() - new Date(b.ISOdate).getTime()
    );
  }
  return map;
}

function generateAppointmentsIntervals(appointments) {
  let intervals = [];
  appointments.forEach((appt) => {
    let start = new Date(appt.ISOdate).getTime();
    let end = start + parseInt(appt.duration.split(" ")[0]) * 60000;
    intervals.push([start, end]);
  });
  intervals.sort((a, b) => a[0] - b[0]);
  // console.log(intervals.map((e)=>new Date(e[0])));
  return intervals;
}

function appointmentAvailable(i = [], appt) {
  let intervals = [...i].sort((a, b) => a[0] - b[0]);
  if (intervals.length === 0) return true;
  let start = new Date(appt.ISOdate).getTime();
  let end = start + parseInt(appt.duration.split(" ")[0]) * 60000;
  let index = intervals.findIndex((e) => {
    return start < e[0];
  });
  //console.log(intervals)
  //console.log(start,intervals[index][0])
  if (index !== -1) {
    if (index === 0) {
      return end <= intervals[index][0];
    }

    return start >= intervals[index - 1][1] && end <= intervals[index][0];
  } else {
    return start >= intervals[intervals.length - 1][1];
  }
}

function mergeIntervals(intervals) {
  intervals.sort((a, b) => {
    return a[0] - b[0];
  });
  let merged = [];
  let current = [...intervals[0]];
  //console.log(intervals)
  for (let i of intervals) {
    if (current[0] <= i[0] && current[1] >= i[0]) {
      current[1] = i[1] <= current[1] ? current[1] : i[1];
    } else {
      merged.push(current);
      current = [...i];
    }
  }
  merged.push(current);
  return merged;
}

export {
  filterConfirmed,
  filterUnconfirmed,
  filterRescheduled,
  filterCancelled,
  filterNoShow,
  filterByMonth,
  filterByWeek,
  filterByDay,
  isoToApptDate,
  statusInDays,
  appointmentAvailable,
  generateAppointmentsIntervals,
  appointmentsInDays,
  mergeIntervals,
};

export const convertMinutesToHM = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = Math.floor(value % 60);

  return {hours, minutes};
};

export const calculateTimeDifference = (startTime, endTime) => {
  const [hour1, hour2] = [startTime.split(":")[0], endTime.split(":")[0]];
  const [min1, min2] = [startTime.split(":")[1], endTime.split(":")[1]];

  let difference_in_hours = hour2 - hour1;
  let difference_in_mins = min2 - min1;

  if (difference_in_hours < 0) {
    difference_in_hours += 24;
  }

  if (difference_in_mins < 0) {
    difference_in_mins += 60;
    difference_in_hours--;
  } else if (difference_in_mins >= 60) {
    difference_in_mins -= 60;
    difference_in_hours++;
  }

  return [difference_in_hours, difference_in_mins];
};

export function getNextQuestion(
  context,
  symptomsToTriage,
  acknowledgement,
  qualifierQuestions,
  durationQuestions,
  followupQuestions,
  setContext
) {
  let questionObj, question, questionType, meaning, multipleChoice;

  const getNextSymptom = () => {
    if (symptomsToTriage && symptomsToTriage.length > 0) {
      return {
        symptom: symptomsToTriage[0].symptom,
        status: symptomsToTriage[0].status,
        followUpIndex: 0,
      };
    }
    return null;
  };

  if (!context.currentSymptom) {
    context.currentSymptom = getNextSymptom();
  }

  const symptomId = context.currentSymptom?.symptom.sid;

  if (context.currentSymptom) {
    if (context.currentResponse == "I'm not experiencing this symptom") {
      // Handling the case when the patient said uninterested to question below (bypass everything)
      context.currentSymptom.status = "followupAsked";
      context.currentSymptom.followUpIndex = 999;
      context.currentResponse = null;
    }
    if (context.currentSymptom.status === "nonQualified") {
      questionObj = qualifierQuestions.find((q) => q.sid === symptomId);
      question = questionObj?.question;
      meaning = questionObj?.meaning;
      multipleChoice = {
        options: [
          questionObj?.applicableButtonText,
          questionObj?.unapplicableButtonText,
          questionObj?.unsure && "Unsure",
        ].filter(Boolean),
        allowMultiple: false,
      };

      if (!question) {
        // Handling no qualifier question for the symptom below
      } else {
        context.currentSymptom.status = "qualified";
      }
    } else if (context.currentSymptom.status === "qualified") {
      let prevQuestionObj = qualifierQuestions.find((q) => q.sid === symptomId);
      if (
        context.currentResponse == prevQuestionObj?.unapplicableButtonText ||
        (!prevQuestionObj?.unsureApplicable &&
          context.currentResponse == "Unsure") //only proceed to duration & followups if the patient said yes to qualifier question
      ) {
        // Handling the case when the patient said no to qualifier question below (bypass everything)
        context.currentSymptom.status = "followupAsked";
        context.currentSymptom.followUpIndex = 999;
      } else {
        questionObj = durationQuestions.find((q) => q.sid === symptomId);
        question = questionObj?.question;
        meaning = questionObj?.meaning;
        multipleChoice = {
          options: questionObj?.selectOptions
            ?.filter(Boolean)
            .concat("I'm not experiencing this symptom"),
          allowMultiple: false,
        };

        if (!question) {
          context.currentSymptom.status = "followupAsked";
        } else {
          context.currentSymptom.status = "durationAsked";
        }
      }
    } else if (context.currentSymptom.status === "durationAsked") {
      context.currentSymptom.status = "followupAsked";
    }

    if (context.currentSymptom.status === "followupAsked") {
      const followupQuestionArray = followupQuestions.filter(
        (q) => q.sid === symptomId
      );
      const currentFollowUpQuestionObj =
        followupQuestionArray[context.currentSymptom.followUpIndex];

      if (currentFollowUpQuestionObj) {
        question = currentFollowUpQuestionObj.question;
        meaning = currentFollowUpQuestionObj.meaning;
        multipleChoice = currentFollowUpQuestionObj.multipleChoice && {
          options: currentFollowUpQuestionObj?.selectOptions
            ?.map((option) => option.name)
            ?.filter(Boolean)
            .concat({name: "I'm not experiencing this symptom"}),
          allowMultiple: currentFollowUpQuestionObj?.allowMultiple,
        };
        context.currentSymptom.followUpIndex += 1;
      } else {
        // Handling the case when all follow-up questions are exhausted for the current symptom below
      }
    }
  }

  if (!question) {
    // Remove the first symptom from symptomsToTriage, store it in triaged, and update context
    symptomsToTriage.shift();
    context.triaged = context.triaged
      ? [...context.triaged, context.currentSymptom]
      : [context.currentSymptom];
    console.log("Triaged Symptoms", context.triaged);

    context.currentSymptom = getNextSymptom();

    if (context.currentSymptom) {
      // Recursively call the function with the new symptom
      getNextQuestion(
        context,
        symptomsToTriage,
        acknowledgement,
        qualifierQuestions,
        durationQuestions,
        followupQuestions,
        setContext
      );
      return;
    } else {
      // Handling the case when all symptoms have been processed/or there were none to begin with
      question = "Is there anything else you'd like to share?";
    }
  }
  setContext({
    ...context,
    symptomsToTriage: symptomsToTriage,
    currentPreface: acknowledgement,
    currentQuestion: question,
    currentMeaning: meaning,
    currentMultipleChoice: multipleChoice,
    currentResponse: null,
  });
}

import {supplementaryCrud} from "../../../crudRequests";

export async function fetchQuestions(
  setQualifierQuestions,
  setDurationQuestions,
  setFollowupQuestions,
  userId,
  toast
) {
  try {
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "qualifier_questions",
        parameters: [
          {
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "find",
      },
      {
        db: "intake",
        collection: "duration_questions",
        parameters: [
          {
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "find",
      },
      {
        db: "intake",
        collection: "followup_questions",
        parameters: [
          {
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "find",
      },
    ]);
    console.log(res.data);
    setQualifierQuestions(res.data[0]);
    setDurationQuestions(res.data[1]);
    setFollowupQuestions(res.data[2]);
  } catch (err) {
    toast({
      title: "Failed to fetch duration question data",
      description:
        err?.message ||
        "An error occurred while fetching duration question data.",
      status: "error",
      isClosable: true,
    });
  }
}

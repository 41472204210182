import {useEffect, useRef, useCallback} from "react";
import io from "socket.io-client";
import {notificationsData} from "../additional_files/notifications";
import {crud} from "../crudRequests";

export function useSocket(url, room, dispatch) {
  const socketRef = useRef({});

  const updateAppt = useCallback(async function (data, {ntf, changingDoctor}) {
    dispatch({type: "UPDATE_APPOINTMENT", payload: data, changingDoctor});
    if (ntf) dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  }, []);

  const updatPt = useCallback(async function (pid, payload, options) {
    dispatch({type: "UPDATE_PATIENT", pid, payload, options});
  }, []);

  const newAppt = useCallback(async function (data, {ntf, updatePatient}) {
    // console.log(ntf)
    dispatch({
      type: "NEW_APPOINTMENT",
      payload: data,
      updatePatient,
    });

    dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  }, []);

  const newFormSubmission = useCallback(async function (data, {ntf}) {
    dispatch({
      type: "NEW_FORM_SUBMISSION",
      payload: data,
    });
    dispatch({type: "NEW_NOTIFICATION", payload: ntf});
  }, []);

  useEffect(() => {
    if (room) {
      socketRef.current = io(url, {
        auth: {
          room,
        },
      });

      socketRef.current.on("update_appt", updateAppt);
      socketRef.current.on("update_pt", updatPt);
      socketRef.current.on("new_appt", newAppt);
      socketRef.current.on("new_form_submission", newFormSubmission);

      return () => {
        socketRef.current.off("update_appt");
        socketRef.current.off("new_appt");
        socketRef.current.off("update_pt");
        socketRef.current.off("new_form_submission");
        socketRef.current.disconnect();
      };
    }
  }, [room, updateAppt]);

  return socketRef;
}

export function useChatSocket(
  url,
  rooms,
  patient,
  dispatch,
  dashDispatch,
  dashState
) {
  const socketRef = useRef({});

  const newMessge = useCallback(async function (data) {
    let {
      messageId,
      conversationId,
      senderId,
      messageType,
      message,
      attachment,
      timestamp,
    } = data;

    let msg = {
      messageId,
      conversationId,
      senderId,
      messageType,
      message,
      attachment,
      timestamp,
    };

    dispatch({
      type: "NEW_MESSAGE",
      payload: msg,
    });

    if (senderId !== patient.pid) {
      let ntf = notificationsData("patient", "New Message", msg);
      dashDispatch({type: "NEW_NOTIFICATION", payload: ntf});
      crud(dashState, [
        {
          db: dashState.db,
          collection: "notifications",
          parameters: [
            {userType: "patient"},
            {$push: {[patient.pid]: ntf}},
            {upsert: true},
          ],
          method: "findOneAndUpdate",
        },
      ]);
    }
  }, []);

  const userStatus = useCallback(async function ({userId, status}) {
    dispatch({
      type: "SET_USER_STATUS",
      payload: {userId, status},
    });
  }, []);

  const userCurrentStatus = useCallback(async function (status) {
    dispatch({
      type: "SET_USERS_CURRENT_STATUS",
      payload: status,
    });
  }, []);

  const newConversation = useCallback(async function (conversation) {
    dispatch({
      type: "NEW_CONVERSATION",
      payload: conversation,
    });

    dashDispatch({
      type: "UPDATE_PATIENT",
      payload: {
        chatsId: [
          ...new Set(
            [conversation.conversationId].concat(patient?.chatsId || [])
          ),
        ],
      },
    });
  }, []);

  const writingStatus = useCallback(async function ({chatId, userId}) {
    dispatch({
      type: "WRITING",
      payload: {chatId, userId},
    });
  }, []);

  useEffect(() => {
    if (rooms || patient?.pid) {
      socketRef.current = io(url, {
        auth: {
          rooms,
          userId: patient?.pid,
        },
      });

      socketRef.current.on("message", newMessge);
      socketRef.current.on("user-status", userStatus);
      socketRef.current.on("writing", writingStatus);
      socketRef.current.on("new-conversation", newConversation);
      socketRef.current.on("users-current-status", userCurrentStatus);

      return () => {
        socketRef.current.off("message", newMessge);
        socketRef.current.on("user-status", userStatus);
        socketRef.current.on("writing", writingStatus);
        socketRef.current.on("new-conversation", newConversation);
        socketRef.current.off("users-current-status", userCurrentStatus);
        socketRef.current.disconnect();
      };
    }
  }, [rooms]);

  return socketRef;
}

import axios from "axios";
const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";

export function promptGPT35Turbo(context, prompt) {
  let config = {
    method: "POST",
    url: url + "/promptGPT35Turbo",
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({context: context, prompt: prompt}),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data.output);
      return response.data.output;
    })
    .catch(function (error) {
      console.log(error);
      console.log("retrying request");
      setTimeout(() => promptGPT35Turbo(context, prompt), 1000);
    });
}

export function promptGPT35TurboMessages(messages) {
  let config = {
    method: "POST",
    url: url + "/promptGPT35Turbo",
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({messages: messages}),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data.output);
      return response.data.output;
    })
    .catch(function (error) {
      console.log(error);
      console.log("retrying request");
      setTimeout(() => promptGPT35TurboMessages(messages), 1000);
    });
}

import BotAvatar from "./BotAvatar.js"

import Welcome from "./Welcome.js";

const botName = "Brightlight Smart Journal";

const config = {
  botName: botName,
  widgets: [
    {
      widgetName: "welcomeOptions",
      widgetFunc: (props) => <Welcome {...props} />,
    }
  ],
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#5652ff",
    },
    chatButton: {
      backgroundColor: "#5652ff",
    },
  }
}

export default config;

export function deduplicateSymptomsToTriage(symptomsToTriage) {
  //Deduplicate & prioritize qualified symptoms
  return symptomsToTriage.reduce((acc, current) => {
    const existingIndex = acc.findIndex(
      (item) => item.symptom === current.symptom
    );
    if (existingIndex > -1) {
      if (
        acc[existingIndex].status === "nonQualified" &&
        current.status === "qualified"
      ) {
        acc[existingIndex] = current;
      }
    } else {
      acc.push(current);
    }
    return acc;
  }, []);
}

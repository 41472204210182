import {QuestionOutlineIcon} from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
} from "@chakra-ui/react";

export default function HelperText({meaning}) {
  return (
    meaning && (
      <Popover placement="right">
        <PopoverTrigger>
          <IconButton
            aria-label="What does this mean?"
            icon={<QuestionOutlineIcon />}
            size="sm"
            variant="ghost"
            rounded="full"
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize={"18px"}>
            What does this question mean?
          </PopoverHeader>
          <PopoverBody
            m="2"
            fontSize={"14px"}
            lineHeight={"5"}
            fontWeight={"light"}
          >
            {meaning}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  );
}

import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image, Link} from "@react-pdf/renderer";

export default function ({type, tpp, patient}) {
  let display = <></>;
  let infoStyle = {
    fontWeight: 500,
    marginTop: 1,
    fontSize: "11px",
  };

  let patientTppInfo = null;
  if (Array.isArray(patient?.tpp) && tpp) {
    patientTppInfo = patient?.tpp.find((ins) => ins.insurerId === tpp.iid);
  }

  switch (type) {
    case "FULL_INVOICE":
    case "FULL_RECEIPT":
      display = (
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            fontSize: 12,
            marginTop: 20,
            flexWrap: "wrap",
            paddingHorizontal: 15,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginBottom: 5,
              }}
            >
              {`Patient Billed To:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${patient?.fName || ""} ${patient?.lName || ""}`}
            </Text>

            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 5,
                fontSize: "11px",
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {`Auth No: `}
              </Text>
              <Text>{`${patientTppInfo?.authNo || "N/A"}`}</Text>
            </View>

            <Text style={infoStyle}>
              {`${patient?.address || "No address available"}`}
            </Text>
            <Text style={infoStyle}>
              {patient?.email ? (
                <Link style={infoStyle}>{`${patient?.email}`}</Link>
              ) : (
                <Text style={infoStyle}>{`${"No email available"}`}</Text>
              )}
            </Text>
            <Text style={infoStyle}>
              {`${patient?.phone || "No phone available"}`}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              paddingLeft: 60,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginBottom: 5,
              }}
            >
              {`Insurer Billed To:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${tpp?.name || "No Insurer"}`}
            </Text>

            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 5,
                fontSize: "11px",
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {`Attention To: `}
              </Text>
              <Text>{`${
                patientTppInfo?.attentionTo || tpp?.defaultAttentionTo || "N/A"
              }`}</Text>
            </View>

            <Text style={infoStyle}>
              {`${
                patientTppInfo?.insurerAddress ||
                tpp?.address ||
                "No address available"
              }`}
            </Text>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 8,
                marginTop: 10,
              }}
            >
              {`Notes:`}
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "col",
                alignItems: "flex-start",
                width: "100%",
                fontSize: 9,
                marginTop: 5,
              }}
            >
              {patientTppInfo?.invoiceNotes ? (
                <Text>{`${patientTppInfo?.invoiceNotes}`}</Text>
              ) : (
                <Text>{`No notes to show`}</Text>
              )}
            </View>
          </View>
        </View>
      );

      break;

    case "PATIENT_INVOICE":
    case "PATIENT_RECEIPT":
      display = (
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            fontSize: 12,
            marginTop: 20,
            flexWrap: "wrap",
            paddingHorizontal: 15,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              marginVertical: 15,
              paddingRight: 15,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
              }}
            >
              {`Patient Billed To:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${patient?.fName || ""} ${patient?.lName || ""}`}
            </Text>
            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 5,
                fontSize: "11px",
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {`Auth No: `}
              </Text>
              <Text>{`${patientTppInfo?.authNo || "N/A"}`}</Text>
            </View>
            <Text style={infoStyle}>
              {`${patient?.address || "No address available"}`}
            </Text>
            <Text style={infoStyle}>
              {patient?.email ? (
                <Link style={infoStyle}>{`${patient?.email}`}</Link>
              ) : (
                <Text style={infoStyle}>{`${"No email available"}`}</Text>
              )}
            </Text>
            <Text style={infoStyle}>
              {`${patient?.phone || "No phone available"}`}
            </Text>
          </View>
          {tpp && (
            <View
              style={{
                display: "flex",
                flexDirection: "col",
                alignItems: "flex-start",
                flex: 1,
                borderRadius: 15,
                backgroundColor: "#e2e8f0",

                padding: 15,
              }}
            >
              <Text
                style={{
                  fontWeight: 700,
                }}
              >
                {`Insurer Information:`}
              </Text>
              <Text style={{...infoStyle, color: "#075985"}}>
                {`${tpp?.name || "No Insurer"}`}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",

                  marginTop: 5,
                  fontSize: "11px",
                }}
              >
                <Text
                  style={{
                    fontWeight: 600,
                    textAlign: "start",
                  }}
                >
                  {`Attention To: `}
                </Text>
                <Text>{`${patientTppInfo?.attentionTo || "N/A"}`}</Text>
              </View>
              <Text style={infoStyle}>
                {`${patientTppInfo?.insurerAddress || "No address available"}`}
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 9,
                  marginTop: 10,
                }}
              >
                {`Notes:`}
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "col",
                  alignItems: "flex-start",
                  width: "100%",
                  fontSize: 9,
                  marginTop: 5,
                }}
              >
                {patientTppInfo?.invoiceNotes ? (
                  <Text>{`${patientTppInfo?.invoiceNotes}`}</Text>
                ) : (
                  <Text>{`No notes to show`}</Text>
                )}
              </View>
            </View>
          )}
        </View>
      );

      break;

    case "INSURER_INVOICE":
    case "INSURER_RECEIPT":
      display = (
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            fontSize: 12,
            marginTop: 20,
            flexWrap: "wrap",
            paddingHorizontal: 15,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              marginVertical: 15,
              paddingRight: 15,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginBottom: 5,
              }}
            >
              {`Insurer Billed To:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${tpp?.name || "No Insurer"}`}
            </Text>

            <View
              style={{
                flexDirection: "row",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 5,
                fontSize: "11px",
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  textAlign: "start",
                }}
              >
                {`Attention To: `}
              </Text>
              <Text>{`${patientTppInfo?.attentionTo || "N/A"}`}</Text>
            </View>
            <Text style={infoStyle}>
              {`${patientTppInfo?.insurerAddress || "No address available"}`}
            </Text>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 8,
                marginTop: 10,
              }}
            >
              {`Notes:`}
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "col",
                alignItems: "flex-start",
                width: "100%",
                fontSize: 9,
                marginTop: 5,
              }}
            >
              {patientTppInfo?.invoiceNotes ? (
                <Text>{`${patientTppInfo?.invoiceNotes}`}</Text>
              ) : (
                <Text>{`No notes to show`}</Text>
              )}
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-start",
              flex: 1,
              borderRadius: 15,
              backgroundColor: "#e2e8f0",

              padding: 15,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
              }}
            >
              {`Patient Information:`}
            </Text>
            <Text style={{...infoStyle, color: "#075985"}}>
              {`${patient?.fName || ""} ${patient?.lName || ""}`}
            </Text>

            <Text style={infoStyle}>
              {`${patient?.address || "No address available"}`}
            </Text>
            <Text style={infoStyle}>
              {patient?.email ? (
                <Link style={infoStyle}>{`${patient?.email}`}</Link>
              ) : (
                <Text style={infoStyle}>{`${"No email available"}`}</Text>
              )}
            </Text>
            <Text style={infoStyle}>
              {`${patient?.phone || "No phone available"}`}
            </Text>
          </View>
        </View>
      );

      break;
    default:
      break;
  }

  return display;
}

import { TiMessages } from "react-icons/ti";
import { MdNotifications, MdOutlineClose } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { GoCalendar } from "react-icons/go";
import { FaWpforms } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { crud } from "../crudRequests";
import { lightFormat } from "date-fns";

export default function Notification({ dashState, dispatch, absolute = true }) {
  const cont = useRef((dashState?.notifications || []).reduce((ac, e) => ac + (e.read ? 0 : 1), 0));
  cont.current = (dashState?.notifications || []).reduce((ac, e) => ac + (e.read ? 0 : 1), 0);

  const [showList, setShowList] = useState(false);
  const sl = useRef(showList);
  sl.current = showList;
  useEffect(() => {
    let f = (e) => {
      if (sl.current) {
        setShowList(false);
      }
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  return (
    <div className={`${absolute ? "absolute" : ""} top-3.5 z-[60] right-[8rem] flex justify-center items-center`}>
      <div
        className=" bg-light opacity-75 p-[4px] rounded-full cursor-pointer shrink-0"
        onClick={(e) => {
          e.stopPropagation();
          dashState.notifications.length > 0 && setShowList(!showList);
          showList && dispatch({ type: "READ_NOTIFICATION" });
        }}
      >
        <span className="flex justify-center items-center">
          <MdNotifications className="text-dark text-[32px]" />
        </span>
        {cont.current > 0 && (
          <span className="absolute top-[2px] -right-[1px] h-[2px] w-[2px] p-[7px] rounded-full flex justify-center items-center leading-[8px] font-medium bg-[#FF0000] text-[8px] text-white">
            {cont.current}
          </span>
        )}
      </div>
      {showList && dashState.notifications.length > 0 && <NotificationList {...{ dashState, dispatch, cont, setShowList }} />}
    </div>
  );
}

function NotificationList({ dashState, dispatch, cont, setShowList }) {
 //console.log(dashState)


  useEffect(() => {
    return () => {
      setShowList(false);
      if (cont.current > 0) {
        dispatch({ type: "READ_NOTIFICATIONS" });
        crud(dashState, [
          {
            db: dashState.db,
            collection: "notifications",
            parameters: [{ userType: "patient" }, { $set: { [`${dashState.patient.pid}.$[].read`]: true } }],
            method: "findOneAndUpdate",
          },
        ]);
      }
    };
  }, []);

  return (
    <div className="absolute show top-14 right-0  h-52 w-72  flex items-start flex-col space-y-2">
      <p
        className="rounded-full p-2 leading-[5px] text-[10px] text-[#DFDEFF] font-semibold bg-[#7977FF] cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();

          dispatch({ type: "CLEAR_NOTIFICATIONS" });
          setShowList(false);
          crud(dashState, [
            {
              db: dashState.db,
              collection: "notifications",
              parameters: [{ userType: "patient" }, { $set: { [dashState.patient.pid]: [] } }],
              method: "updateOne",
            },
          ]);
        }}
      >
        Clear All
      </p>
      <div className="w-full overflow-hidden max-h-40 px-1">
        <ul className="overflow-y-scroll board p-1 h-full space-y-2 rounded-lg  bg-white/50">
          {(dashState.notifications || []).map((ntf, i) => notificationsFrmatter(ntf, i, dashState, dispatch))}
        </ul>
      </div>
    </div>
  );
}

let colorsMap = {
  "No Show": "#000000",
  Cancelled: "#FF0000",
  Confirmed: "#5754FF",
  Rescheduled: "#FF9900",
  Unconfirmed: "#8F8CFF",
};

function notificationsFrmatter(ntf, idx, dashState, dispatch) {
  const onDelete = (e) => {
    e.stopPropagation();
    dispatch({ type: "DELETE_NOTIFICATION", payload: { ntfId: ntf.ntfId } });
    crud(dashState, [
      {
        db: dashState.db,
        collection: "notifications",
        parameters: [{ userType: "patient" }, { $pull: { [dashState.patient.pid]: { ntfId: ntf.ntfId } } }],
        method: "updateOne",
      },
    ]);
  };

  switch (ntf.type) {
    case "New appointment":
      return (
        <li
          key={idx}
          style={{ backgroundColor: !ntf.read ? "#c5c3f9" : null }}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">{lightFormat(new Date(ntf.creationDate), "h:mm aa")}</span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction with Dr. ${ntf.dName} `}</span>
              <span style={{ color: colorsMap[ntf.status] }}>{`${ntf.status} `}</span> for
              <span className="font-semibold">{` ${dateFormatter(ntf.apptDate)}`}</span>
            </p>
          </div>
        </li>
      );

    case "New Form Submission":
      return (
        <li
          key={idx}
          style={{ backgroundColor: !ntf.read ? "#c5c3f9" : null }}
          className="bg-[#DFDEFF] h-16 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">{lightFormat(new Date(ntf.creationDate), "h:mm aa")}</span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <FaWpforms />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`New form submitted by ${ntf.submittedBy}. `}</span>
              <div className="pb-1">
                <span>{`Form title:`}</span> <span className="font-semibold">{`${ntf.formTitle}.`}</span>
              </div>
            </p>
          </div>
        </li>
      );

    case "Updated appointment":
      return (
        <li
          key={idx}
          style={{ backgroundColor: !ntf.read ? "#c5c3f9" : null }}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">{lightFormat(new Date(ntf.creationDate), "h:mm aa")}</span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction with Dr. ${ntf.dName} `}</span>
              <span style={{ color: colorsMap[ntf.status] }}>{`${ntf.status} `}</span> for
              <span className="font-semibold">{` ${dateFormatter(ntf.apptDate)}`}</span>
            </p>
          </div>
        </li>
      );

    case "New chatbot intake":
      return (
        <li
          key={idx}
          style={{ backgroundColor: !ntf.read ? "#c5c3f9" : null }}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">{lightFormat(new Date(ntf.creationDate), "h:mm aa")}</span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <TiMessages />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction registered for Dr. ${ntf.dName}`}</span>
            </p>
          </div>
        </li>
      );

    case "New Message": {
      let { doctors, doctorsIndexMap } = dashState;
      let index = doctorsIndexMap[ntf.senderId];
      let doctor = doctors[index];

      return (
        <li
          key={idx}
          style={{ backgroundColor: !ntf.read ? "#c5c3f9" : null }}
          className="bg-[#DFDEFF] h-14 relative shadow-ntf rounded-[1.2rem] flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#5754FF]/75">{lightFormat(new Date(ntf.creationDate), "h:mm aa")}</span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#7977FF] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#7977FF] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <AiOutlineMessage />
          </div>

          <div className="text-[#5754FF]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Message from ${doctor?.name ? "Dr. " + doctor?.name : "chat"}`}</span>
              <span className="font-semibold">{` at ${dateFormatter(ntf.timestamp)}`}</span>
            </p>
          </div>
        </li>
      );
    }

    default:
      break;
  }
}
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function dateFormatter(date) {
  date = new Date(date);
  return `${months[date.getMonth()]} ${date.getDate()} at ${lightFormat(date, "h:mm aaa")}`;
}

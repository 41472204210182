import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({transaction, type, receiptAmount}) {
  let obj = {};

  switch (type) {
    case "FULL_INVOICE":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Patient": `$${transaction.patientAmount}`,
        "Cost to Insurer": `$${transaction.tppAmount}`,
      };
      break;
    case "FULL_RECEIPT":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Patient": `$${transaction.patientAmount}`,
        "Cost to Insurer": `$${transaction.tppAmount}`,
        "Patient Amount Paid": `$${parseFloat(
          transaction.amountPaidByPatient ?? 0
        )}`,
        "Insurer Amount Paid": `$${parseFloat(
          transaction.amountPaidByTpp ?? 0
        )}`,
      };
      break;

    case "PATIENT_INVOICE":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Patient": `$${transaction.patientAmount}`,
      };
      break;

    case "PATIENT_RECEIPT":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Patient": `$${transaction.patientAmount}`,
        "Cost to Insurer": `$${transaction.tppAmount}`,
        "Amount Paid": `$${receiptAmount ?? 0}`,
      };
      break;

    case "INSURER_INVOICE":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Insurer": `$${transaction.tppAmount}`,
      };
      break;

    case "INSURER_RECEIPT":
      obj = {
        "Service Name": transaction.serviceType || "",
        "Service Duration": transaction.time,
        "Service Date": new Date(transaction.serviceDate)
          .toLocaleString()
          .split(",")[0],
        "Cost to Patient": `$${transaction.patientAmount}`,
        "Cost to Insurer": `$${transaction.tppAmount}`,
        "Amount Paid": `$${receiptAmount ?? 0}`,
      };
      break;
    default:
      break;
  }

  return (
    <View
      style={{
        marginTop: 30,
        paddingHorizontal: 15,
        color: "#1f2937",
        fontSize: 11,
      }}
    >
      <View
        style={{
          flexDirection: "col",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            borderTop: "1px solid #1f2937",
            borderRight: "1px solid #1f2937",
          }}
        >
          {Object.entries(obj).map(([key, val], i) => (
            <View
              style={{
                flex: 1,
                padding: 5,
                flexWrap: "wrap",
                flexDirection: "row",
                display: "flex",
                fontWeight: 600,
                alignItems: "flex-start",
                borderLeft: "1px solid #1f2937",
              }}
            >
              {key.split(" ").map((e, i) => (
                <Text style={{margin: 1}} key={i}>
                  {e}
                </Text>
              ))}
            </View>
          ))}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            borderTop: "1px solid #1f2937",
            borderRight: "1px solid #1f2937",
            borderBottom: "1px solid #1f2937",
            marginTop: "-1px",
          }}
        >
          {Object.entries(obj).map(([key, val], i) => (
            <Text
              style={{
                flex: 1,
                padding: 6,
                paddingHorizontal: 8,
                justifyContent: "center",
                display: "flex",
                fontWeight: 500,
                textAlign: "start",
                borderLeft: "1px solid #1f2937",
              }}
            >
              {val}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
}

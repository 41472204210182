import axios from "axios";
const url = window.location.href.includes("localhost")
    ? "http://localhost:3000"
    : "https://blh-server-2001610adcd9.herokuapp.com";

const jwtDecode = (t) => {
    try {
        return JSON.parse(window.atob(t.split(".")[1]));
    } catch {
        window.location.href = "../login";
    }
};

const getBotResponse = async (state, date) => {
    const configService = {
        method: "POST",
        url: `${url}/getBotResponse`,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.jwt || "",
        },
        data: JSON.stringify({
            id: state.jwt ? jwtDecode(state.jwt).id : "",
            db: state.db,
            pid: state.patient.pid,
            date,
        }),
    };
    return axios(configService).then((res) => {
        return res.data;
    });
};

const saveUserMsg = async (state, date, text, timestamp) => {
    const configService = {
        method: "POST",
        url: `${url}/saveUserMsg`,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.jwt || "",
        },
        data: JSON.stringify({
            id: state.jwt ? jwtDecode(state.jwt).id : "",
            db: state.db,
            pid: state.patient.pid,
            date,
            text,
            timestamp,
        }),
    };
    return axios(configService).then((res) => {
        return res.data;
    });
};

const saveBotMsg = async (state, date, text, timestamp) => {
    const configService = {
        method: "POST",
        url: `${url}/saveBotMsg`,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.jwt || "",
        },
        data: JSON.stringify({
            id: state.jwt ? jwtDecode(state.jwt).id : "",
            db: state.db,
            pid: state.patient.pid,
            date,
            text,
            timestamp,
        })
    };
    return axios(configService).then((res) => {
        console.log(res.data);
        return res.data;
    });
};

export {
    getBotResponse,
    saveUserMsg,
    saveBotMsg,
};

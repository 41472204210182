import axios from "axios";
const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export async function loginUser(email, password, userType, db) {
  let config = {
    method: "POST",
    url: `${url}/loginUser`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      email: email,
      password: password,
      userType: userType,
      db,
    }),
  };
  return await axios(config);
}

export async function resetUserEmail(email, userType, org) {
  let config = {
    method: "POST",
    url: `${url}/resetUser`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({email: email, userType: userType, org}),
  };
  return await axios(config);
}

export async function checkResetParams(reset) {
  let config = {
    method: "POST",
    url: `${url}/checkResetParams`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({reset: reset}),
  };
  return await axios(config);
}

export async function resetPassword(reset, userId, password, userType, db) {
  let config = {
    method: "POST",
    url: `${url}/resetPassword`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      reset: reset,
      userId,
      password: password,
      userType,
      db,
    }),
  };
  return await axios(config);
}

export async function verifyUser(jwt, fadeInContent) {
  let config = {
    method: "POST",
    url: `${url}/verifyUser`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({id: jwt ? jwtDecode(jwt)?.id : ""}),
  };
  axios(config)
    .then(() => {
      var fc = 0;
      fadeInContent?.current &&
        (function fade() {
          if (fadeInContent?.current) {
            fadeInContent.current.style.opacity = fc = fc + 0.1;
            fadeInContent.current.style.opacity != 1 && setTimeout(fade, 30);
          }
        })();
      return;
    })
    .catch((e) => {
      console.log(e);
      window.location.href = "../login";
    });
}

export async function verifyLoginCode({jwt, code, userType, user, db}) {
  let config = {
    method: "POST",
    url: `${url}/verifyLoginCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({id: jwtDecode(jwt).id, code, userType, user, db}),
  };
  return axios(config);
}

export function setAuthCode({jwt, userType, user, db, organization}) {
  let config = {
    method: "POST",
    url: `${url}/setAuthCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(jwt).id,
      userType,
      user,
      db,
      organization,
    }),
  };
  return axios(config);
}

export async function changePassword(state, updateBody) {
  let configNotifications = {
    method: "POST",
    url: `${url}/changePassword`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state.jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(state.jwt).id,
      updateBody: updateBody,
    }),
  };

  return await axios(configNotifications);
}

export function logoutUser() {
  window.location.href = "../login";
}

export async function checkCardInfoParams(params, userType = "patient") {
  let config = {
    method: "POST",
    url: `${url}/checkCardInfoParams`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({params, userType}),
  };
  return await axios(config);
}

export async function setCardInfo(params, pid, cardInfo, db) {
  let config = {
    method: "POST",
    url: `${url}/setCardInfo`,
    headers: {"Content-Type": "application/json"},
    data: JSON.stringify({
      params,
      userId: pid,
      cardInfo,
      db,
    }),
  };
  return await axios(config);
}

export async function validateUrlTempForms(params) {
  let config = {
    method: "POST",
    url: `${url}/validateUrlTempForms`,
    headers: {"Content-Type": "application/json"},
    data: {params: params},
  };
  return await axios(config);
}

export async function submitForm(db, fsid, formData) {
  let config = {
    method: "POST",
    url: `${url}/submit-form`,
    headers: {"Content-Type": "application/json"},
    data: {db: db, fsid: fsid, formData: formData},
  };
  return await axios(config);
}

export async function getLocations(db, linkId) {
  let config = {
    method: "POST",
    url: `${url}/getLocations`,
    headers: {"Content-Type": "application/json"},
    data: {db: db, linkId: linkId},
  };
  return await axios(config);
}

export function crudStorageUploadTempForm(
  selectedFileUpload,
  orgShareName,
  directoryName,
  fsid,
  s3BucketName
) {
  const formData = new FormData();
  formData.append("file", selectedFileUpload);
  return axios({
    method: "POST",
    url: `${url}/crudStorageUploadTempForm`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    params: {
      s3BucketName: s3BucketName,
      fsid: fsid,
      orgShareName: orgShareName,
      directoryName: directoryName,
    },
  });
}

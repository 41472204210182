import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";

export default function ({transaction, type, receiptAmount}) {
  let display = <></>;
  let tax = parseFloat(transaction.tax || 0);

  let otbp =
    parseFloat(transaction.patientAmount ?? 0) -
    parseFloat(transaction.amountPaidByPatient ?? 0);
  let otbi =
    parseFloat(transaction.tppAmount ?? 0) -
    parseFloat(transaction.amountPaidByTpp ?? 0);

  let totalCost =
    parseFloat(transaction.patientAmount ?? 0) +
    parseFloat(transaction.tppAmount ?? 0);

  switch (type) {
    case "FULL_RECEIPT":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            justifyContent: "flex-end",

            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${(
              (parseFloat(transaction.amountPaidByPatient ?? 0) +
                parseFloat(transaction.amountPaidByTpp ?? 0)) /
              (1 + tax / 100)
            ).toFixed(2)}`}</Text>
          </View>
          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${
              parseFloat(transaction.amountPaidByPatient ?? 0) +
              parseFloat(transaction.amountPaidByTpp ?? 0)
            }`}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-end",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: otbi + otbp === 0 ? "#d1fae5" : "#fee2e2",
              fontSize: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Patient Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbp === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${otbp}`}</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Insurer Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbi === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${otbi}`}</Text>
            </View>
          </View>
        </View>
      );

      break;
    case "FULL_INVOICE":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",

            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",

              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${(totalCost / (1 + tax / 100)).toFixed(2)}`}</Text>
          </View>
          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${totalCost}`}</Text>
          </View>
        </View>
      );

      break;

    case "PATIENT_INVOICE":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",

            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",

              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${(otbp / (1 + tax / 100)).toFixed(2)}`}</Text>
          </View>

          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${otbp}`}</Text>
          </View>
        </View>
      );
      break;

    case "PATIENT_RECEIPT":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${((receiptAmount || 0) / (1 + tax / 100)).toFixed(2)}`}</Text>
          </View>
          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${receiptAmount || 0}`}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-end",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: otbp === 0 ? "#d1fae5" : "#fee2e2",
              fontSize: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Patient Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbp === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${otbp}`}</Text>
            </View>
          </View>
        </View>
      );

      break;

    case "INSURER_INVOICE":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",

            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",

              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${(otbi / (1 + tax / 100)).toFixed(2)}`}</Text>
          </View>
          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${otbi}`}</Text>
          </View>
        </View>
      );
      break;

    case "INSURER_RECEIPT":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",

            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 2,
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${((receiptAmount || 0) / (1 + tax / 100)).toFixed(2)}`}</Text>
          </View>
          {tax && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 2,
                lignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Tax:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                }}
              >{`${tax}%`}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${receiptAmount || 0}`}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-end",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: otbi === 0 ? "#d1fae5" : "#fee2e2",
              fontSize: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Insurer Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbi === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${otbi}`}</Text>
            </View>
          </View>
        </View>
      );

      break;
    default:
      break;
  }

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "row",
        marginTop: 20,
        paddingHorizontal: 15,
      }}
    >
      {display}
    </View>
  );
}

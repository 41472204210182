import React, {useState, useEffect, useRef} from "react";
import {crud} from "../crudRequests";
import Swal from "sweetalert2";

export function IntakeFunctions() {
  const [allDisorders, setAllDisorders] = useState();
  const [allSymptoms, setAllSymptoms] = useState();
  const [allDisorderQuestions, setAllDisorderQuestions] = useState();
  const [allQuestions, setAllQuestions] = useState();

  function logout(chatbotConversation) {
    if (chatbotConversation.length != 0) {
      Swal.fire({
        title: "Are you sure you want to exit this conversation?",
        text: "By logging out, you will lose all of your current conversation data with the AI intake and will have to start over again. Are you sure you want to do this?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = "../login";
        }
      });
    } else {
      window.location.href = "../login";
    }
  }

  function suicidalRisk(id, state, setHighRiskAsked) {
    function desire() {
      Swal.fire({
        title: "High Risk Assessment",
        html: `Something you mentioned in your chat intake was suggestive of potential suicidal intent.
      <br/><br/><b><span class='text-red-600'>*</span> Are you thinking about killing yourself?</b><br/>`,
        icon: "warning",
        input: "select",
        inputOptions: {
          Yes: "Yes",
          No: "No",
          Idk: "I don't know",
        },
        inputPlaceholder: "—",
        focusConfirm: false,
        allowOutsideClick: false,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value === "Yes" || value === "Idk") {
              helpline();
            } else if (value == "No") {
              resolve();
            } else {
              suicidalRisk();
            }
          });
        },
      });
    }
    function helpline() {
      Swal.fire({
        title: "Contact Helpline",
        html: `We strongly urge you to reach out to the <b>Canada Suicide Prevention Service</b> for help at <b class='text-red-600'>1-833-456-4566</b> immediately.
        <br/><br/>If you cannot control your mental state and you feel like this is may be an emergency, please call <b class='text-red-600'>911</b> immediately or <b>go to your local emergency department</b>.`,
        icon: "warning",
        allowOutsideClick: false,
      }).then((result) => {
        assessment();
      });
    }
    function assessment() {
      Swal.fire({
        title: "High Risk Assessment",
        html: `
        <div class='grid grid-cols-2 grid-flow-col gap-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>Do you have a plan for how you might kill yourself?</b></div>
          <div class="col-span-1"><input type="text" id="plan" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>
        <div class='grid grid-cols-2 grid-flow-col gap-3 mt-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>Have you thought about/acquired any methods (i.e. pills, rope, etc.) that might assist you in killing yourself?</b></div>
          <div class="col-span-1"><input type="text" id="methods" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>
        <div class='grid grid-cols-2 grid-flow-col gap-3 mt-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>Do you have any firearms or other weapons at home? If so, where are they?</b></div>
          <div class="col-span-1"><input type="text" id="firearms" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>
        <div class='grid grid-cols-2 grid-flow-col gap-3 mt-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>Have you ‘rehearsed’ or ‘gone through the motions’ of killing yourself?</b></div>
          <div class="col-span-1"><input type="text" id="rehearsed" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>
        <div class='grid grid-cols-2 grid-flow-col gap-3 mt-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>In the next 24–48 hours, how likely is it that you will act on your suicidal thoughts?</b></div>
          <div class="col-span-1"><input type="text" id="likely" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>
        <div class='grid grid-cols-2 grid-flow-col gap-3 mt-3'>
          <div class="col-span-2 text-left leading-[0.5rem]"><b class='font-medium text-sm'>Would you consider yourself an impulsive person? Have you recently felt out of control at times?</b></div>
          <div class="col-span-1"><input type="text" id="impulsive" class="p-2 m-0 text-sm h-7 rounded-md" autofocus placeholder=""></div>
        </div>`,
        icon: "warning",
        confirmButtonText: "Finish",
        focusConfirm: false,
        allowOutsideClick: false,
        preConfirm: () => {
          const plan = Swal.getPopup().querySelector("#plan").value;
          const methods = Swal.getPopup().querySelector("#methods").value;
          const firearms = Swal.getPopup().querySelector("#firearms").value;
          const rehearsed = Swal.getPopup().querySelector("#rehearsed").value;
          const likely = Swal.getPopup().querySelector("#likely").value;
          const impulsive = Swal.getPopup().querySelector("#impulsive").value;
          return {
            plan: plan,
            methods: methods,
            firearms: firearms,
            rehearsed: rehearsed,
            likely: likely,
            impulsive: impulsive,
          };
        },
      }).then((result) => {
        //self-scheduling
        let payload = {
          pid: id,
          highRisk: {
            "Do you have a plan for how you might kill yourself?":
              result.value.plan,
            "Have you thought about/acquired any methods (i.e. pills, rope, etc.) that might assist you in killing yourself?":
              result.value.methods,
            "Do you have any firearms or other weapons at home? If so, where are they?":
              result.value.firearms,
            "Have you ‘rehearsed’ or ‘gone through the motions’ of killing yourself?":
              result.value.rehearsed,
            "In the next 24–48 hours, how likely is it that you will act on your suicidal thoughts?":
              result.value.likely,
            "Would you consider yourself an impulsive person? Have you recently felt out of control at times?":
              result.value.impulsive,
          },
        };
        crud(state, [
          {
            db: state.db,
            collection: "patients",
            parameters: [{pid: id}, {$set: payload}],
            method: "updateOne",
          },
        ]);
        window.location.href = "https://talksuicide.ca/";
      });
    }
    desire();
  }

  async function populateSymptomsAndDisorders(state) {
    let res = await crud(state, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [{}],
        method: "find",
      },
      {
        db: "intake",
        collection: "disorders",
        parameters: [{}],
        method: "find",
      },
    ]);
    setAllSymptoms(res.data[0]);
    setAllDisorders(res.data[1]);
  }

  async function populateQuestionsAndDisorderQuestions(state) {
    let initialQuestions = await crud(state, [
      {
        db: "intake",
        collection: "symptom_questions",
        parameters: [{}],
        method: "find",
      },
      {
        db: "intake",
        collection: "disorder_questions",
        parameters: [{}],
        method: "find",
      },
    ]);
    setAllQuestions(initialQuestions.data[0]);
    setAllDisorderQuestions(initialQuestions.data[1]);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function endInstance(
    swal,
    state,
    navigate,
    chatbotConversation,
    symptomsQualified
  ) {
    swal(
      "Thank you",
      "Your AI chatbot session has now been successfully concluded. Thank you so much for going through this process as we understand it could bring up difficult emotions. Your practitioner's office will now reach out to you regarding next steps.",
      "success"
    ).then(async function () {
      crud(state, [
        {
          db: state.db,
          collection: "cases",
          parameters: [
            {
              cid: makeid(5),
              pid: state.patient.pid,
              chatbotConversation: chatbotConversation,
              predictedDiagnostics: symptomsQualified,
              predictedAxisV: 50,
              date: new Date(),
            },
          ],
          method: "insertOne",
        },
      ]);

      navigate("../", {
        state: {
          ...state,
        },
      });
    });
  }

  return [
    logout,
    suicidalRisk,
    populateSymptomsAndDisorders,
    populateQuestionsAndDisorderQuestions,
    allDisorders,
    allSymptoms,
    allDisorderQuestions,
    allQuestions,
    endInstance,
  ];
}

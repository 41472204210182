import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    const timestamp = Date.now();
    actions.saveUserMessage(message, timestamp).then(() => {
      // actions.awaitResponse();
      actions.triggerResponse();
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;

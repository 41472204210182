import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {verifyLoginCode, setAuthCode} from "../authRequests";
import {loadDataForPatientLogin, crud} from "../crudRequests";
import clientInfo from "../additional_files/loginInfo";
import axios from "axios";
export default function CodeForm({logingData, navigate, sendCodeData, org}) {
  const [code, setCode] = useState("");
  const [serverError, setServerError] = useState("");
  const [authError, setAuthError] = useState("");
  const [loading, setLoading] = useState("");
  const [sendingCode, setSendingCode] = useState("");
  const [loadingSuccses, setLoadingSuccses] = useState(false);
  function login(e) {
    e.preventDefault();
    setLoading(true);
    verifyLoginCode({
      code,
      ...sendCodeData,
    })
      .then(async (res) => {
        setLoading(false);
        setLoadingSuccses(true);
        let data = {...logingData, [logingData.userType]: res.data};
        let jsonIp = await axios({url: "https://api.ipify.org?format=json"});

        let userId =
          logingData.userType === "relationship"
            ? logingData.relationship?.rid
            : logingData.patient?.pid;

        crud({jwt: logingData.jwt}, [
          {
            db: logingData.db,
            collection: "security",
            parameters: [
              {userId},
              {
                $push: {
                  logins: {
                    ip: jsonIp.data.ip,
                    client: clientInfo(),
                    timestamp: new Date(),
                  },
                },
              },
              {upsert: true},
            ],
            method: "findOneAndUpdate",
          },
        ]);

        loadDataForPatientLogin(data, navigate);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setLoading(false);
          setAuthError(true);
        } else if (err.response.status === 404 || err.response.status === 500) {
          setServerError(true);
        }
      });
  }

  return (
    <div>
      {loadingSuccses ? (
        <div>
          <div className="border p-[6.5rem] rounded-md">
            <div className="flex justify-center align-middle">
              <img src="/images/loader.gif" className="h-36" />
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={login} className="border p-12 rounded-md">
          {org?.logo ? (
            <img src={org.logo} className="w-64 mx-auto mb-5" />
          ) : (
            <img src="/images/logo.png" className="w-64 mx-auto mb-5" />
          )}
          {authError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      Invalid code!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {serverError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      <span className="font-semibold">
                        The server has encountered an
                        <br />
                        internal error.
                      </span>
                      <br />
                      Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="input-group">
            <input
              type="text"
              id="code"
              required
              autoComplete="off"
              className="input w-72 py-3 px-6 rounded-[4px] my-2 border"
              onChange={(e) => {
                setAuthError(false);
                setCode(e.target.value);
              }}
            />
            <label htmlFor="code" className="input-label">
              Authentication code
            </label>
          </div>
          <div className="flex justify-between">
            <span
              className="text-dark font-medium text-xs mt-4 cursor-pointer flex"
              onClick={() => {
                if (!sendingCode) {
                  setSendingCode(true);
                  setAuthCode(sendCodeData)
                    .then(() => {
                      setSendingCode(false);
                    })
                    .catch((e) => {
                      console.log(e.message);
                      setSendingCode(false);
                    });
                }
              }}
            >
              {sendingCode ? "Sending email..." : "Resend email."}
              {sendingCode && (
                <span className="">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </span>

            <button
              type="submit"
              className="p-2.5 px-5 rounded-[4px] my-2 flex justify-center items-center bg-dark text-background font-medium text-medium right cursor-pointer"
            >
              Confirm{" "}
              {loading && (
                <span className="">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

import Options from "./Options.js";

const WelcomeOptions = (props) => {
  const options = [
    {
      text: "How are you feeling?",
      handler: props.actionProvider.handleWelcome,
      id: 1,
    },
    {
      text: "Tell me about your day.",
      handler: props.actionProvider.handleWelcome,
      id: 2,
    },
    {
      text: "What are you grateful for?",
      handler: props.actionProvider.handleWelcome,
      id: 3,
    },
    {
      text: "Tell me about a goal you are working towards.",
      handler: props.actionProvider.handleWelcome,
      id: 4,
    },
  ];

  return <Options options={options} />
}

const Welcome = (props) => {
  return (
    <WelcomeOptions actionProvider={props.actionProvider} />
  );
};

export default Welcome;

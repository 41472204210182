import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import Navbar from "../components/Patient.Navbar";
import Notifications from "../components/Patient.Notifications";
import Chat from "../components/Chat";
import {patientReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import SelfSchedule from "../components/SelfSchedule";
import AppointmentModal from "../components/Clinic.AppointmentModal";
import Layout from "../components/Layout";
import {Fade} from "@chakra-ui/react";

export default function Inbox(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const location = useLocation();
  const state = location.state;

  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const [showSelfSch, setShowSelfSch] = useState(false);

  const selectedAppointment = useRef(null);
  selectedAppointment.current = dashState.selectedAppointment;

  const sessionStart = useRef(true);
  let room = dashState.patient?.pid;

  let unconfirmedCount = useMemo(() => {
    if (dashState.appointments && dashState.appointments.length > 0) {
      return dashState.appointments.filter((appt) => {
        return (
          !appt.pConfirmed &&
          (apptStatus(appt) === "Unconfirmed" ||
            (apptStatus(appt) === "Rescheduled" &&
              appt.rescheduled.split("|")[1] !== dashState.patient.pid))
        );
      }).length;
    }
    return 0;
  }, [dashState.appointments]);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  const socket = useSocket("https://webserver.brightlight.ai/", room, dispatch);
  //const socket = useSocket('http://localhost:3000/', room, dispatch);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  useEffect(() => {
    let f = (e) => {
      if (selectedAppointment.current)
        dispatch({type: "SELECT_APPOINTMENT", payload: null});
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  function selectAppointment(appt) {
    dispatch({type: "SELECT_APPOINTMENT", payload: appt});
  }

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  useEffect(() => {
    if (dashState.patient) {
      const abortController = new AbortController();
      loadDataForPatientLogin(
        {
          ...dashState,
          destination: "../inbox",
        },
        navigate,
        null,
        abortController.signal
      );
      return () => {
        abortController.abort();
      };
    }
  }, []);

  return (
    <>
      <Layout state={{...dashState}}>
        <Fade in={dashState} transition={{enter: {duration: 0.5}}}>
          <Chat dashState={dashState} dashDispatch={dispatch} />
        </Fade>
      </Layout>
    </>
  );
}

function apptStatus(appt) {
  let statusMap = {
    noShow: "No Show",
    cancelled: "Cancelled",
    pConfirmed: "Confirmed",
    rescheduled: "Rescheduled",
  };

  for (let [key, val] of Object.entries(statusMap)) {
    if (appt[key]) return val;
  }
  return "Unconfirmed";
}

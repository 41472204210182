import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser, jwtDecode} from "../../authRequests";
import {crud, loadDataForPatientLogin} from "../../crudRequests";
import {patientReducer, init} from "../../additional_files/reducer";
import {useSocket} from "../../additional_files/custom";
import Layout from "../Layout";
import {Box, Button, Grid, Heading, Input, Text} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/react";
import DateSelect from "../DateSelect";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import moment from "moment";

const Prescription = ({item, setPrescriptions, index}) => {
  const updateItem = (key, value) => {
    setPrescriptions((prev) => {
      let thisItem = prev.filter((entry) => entry.presId === item.presId)[0];
      thisItem[key] = value;
      let prevCopy = [...prev];
      prevCopy[index] = thisItem;

      return prevCopy;
    });
  };

  return (
    <Grid gap={2} maxW="450px" mt={4}>
      <Grid gridTemplateColumns="1fr auto">
        <Text>
          {item?.drugName || ""} - {item?.dosage}
        </Text>
        <Box>
          <Button
            bg={item.taken ? "lightBlue" : "lightGrey"}
            onClick={() => {
              updateItem("taken", true);
              updateItem("reasoning", false);
            }}
            mr={2}
          >
            <CheckIcon />
          </Button>
          <Button
            bg={!item.taken ? "lightBlue" : "lightGrey"}
            onClick={() => updateItem("taken", false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Grid>
      {!item.taken && (
        <>
          <Text>Why did you decide to skip today?</Text>
          <Input
            value={item?.reasoning || ""}
            onChange={(e) => updateItem("reasoning", e.target.value)}
          />
        </>
      )}
    </Grid>
  );
};

const useFormData = (initialData) => {
  const [data, setData] = useState(initialData);

  const updateData = (name) => (value) => {
    setData((prev) => ({...prev, [name]: value}));
  };

  const getData = (name) => {
    return data[name];
  };

  return [getData, updateData, data, setData];
};

export default function PrescriptionCompliance() {
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const toast = useToast();
  const selectedAppointment = useRef(null);
  selectedAppointment.current = dashState.selectedAppointment;

  const loadedRef = useRef(null);
  const id = state && jwtDecode(state.jwt).id;

  const [validDates, setValidDates] = useState([new Date()]);

  const dateToString = (date) => {
    return date.toISOString(true).slice(0, 10);
  };

  const queryValidDates = (id) => {
    crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "prescription_compliance",
        parameters: [{pid: id}],
        method: "find",
      },
    ]).then((res) => {
      const dates = res.data[0].map((entry) => entry.date);
      console.log({dates});
      setValidDates([...dates, dateToString(new Date())]);
    });
  };

  // const upsertEmptyJournal = async (id, date) => {
  //   await crud(state, [
  //     {
  //       db: dashState?.db || "blh",
  //       collection: "prescription_compliance",
  //       parameters: [
  //         {pid: id, date: date},
  //         {
  //           $setOnInsert: {complianceEntries: []},
  //         },
  //         {upsert: true},
  //       ],
  //       method: "updateOne",
  //     },
  //   ]);
  // };

  useEffect(() => {
    verifyUser(state ? state.jwt : "", loadedRef);

    const date = new Date();

    // upsertEmptyJournal(id, dateToString(date)).then(() => {
    //   setDate(date);
    //   queryValidDates(id);
    // });
    setDate(date);
    queryValidDates(id);
  }, []);

  const [activeDate, setDate] = useState(new Date());

  const [getData, updateData, data, setData] = useFormData([]);

  const upsertEntry = async (id, date) => {
    await crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "prescription_compliance",
        parameters: [
          {pid: id, date: date},
          {
            $set: {
              complianceEntries: prescriptions,
              date: date,
              pid: id,
            },
          },
          {upsert: true},
        ],
        method: "updateOne",
      },
    ]);
  };

  const handleSubmit = () => {
    toast.promise(upsertEntry(id, dateToString(moment(activeDate))), {
      success: {title: "Success", description: "Entry recorded"},
      error: {title: "Please try again", description: "Something wrong"},
      loading: {title: "Loading...", description: "Please wait"},
    });
  };

  const loadPrescriptions = async () => {
    let res = await crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "prescriptions",
        parameters: [{pid: id}],
        method: "find",
      },
    ]);
    const entry = res?.data?.[0];

    console.log({prescriptions: entry});
    if (entry.length > 0) {
      const notExpired = entry?.filter((item) => !item.expired);
      // if (dateToString(activeDate) === dateToString(new Date())) {
      setPrescriptions(
        notExpired?.map((item) => ({
          presId: item?.presId,
          drugName: item?.drugName,
          dosage: item?.dosage,
          taken: true,
          reasoning: "",
        }))
      );
      // }
    }
  };

  const loadEntry = async (id, date) => {
    let res = await crud(state, [
      {
        db: dashState?.db || "blh",
        collection: "prescription_compliance",
        parameters: [{pid: id, date: date}],
        method: "findOne",
      },
    ]);
    const entry = res?.data?.[0];
    console.log({entry, date, res: res.data});
    if (entry) {
      setPrescriptions(entry?.complianceEntries || []);
      console.log({p: entry?.complianceEntries});
      if (!entry?.complianceEntries || entry?.complianceEntries.length === 0) {
        loadPrescriptions();
      }
    }
    if (!entry) {
      loadPrescriptions();
    }
  };
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    loadEntry(id, dateToString(activeDate));
  }, []);

  return (
    <>
      <Layout state={{...dashState}}>
        <Heading>Prescription Compliance</Heading>
        <DateSelect
          selectedDate={activeDate}
          setSelectedDate={(date) => {
            setDate(date);

            loadEntry(id, dateToString(date));
          }}
          validDates={validDates}
        />

        <Box
          pointerEvents={
            dateToString(activeDate) === dateToString(new Date())
              ? "auto"
              : "none"
          }
          cursor={
            dateToString(activeDate) === dateToString(new Date())
              ? "auto"
              : "not-allowed"
          }
          bg="white"
          p={8}
          borderRadius={4}
        >
          <Text fontSize="lg" mb={4}>
            Please indicate whether you have adhered to your prescribed
            medications and/or therapies for {activeDate.toLocaleDateString()}
          </Text>
          <Heading mb={2} mt={4} as="h5" size="md">
            Medications
          </Heading>
          {/* {mapper(baseFactors)} */}
          {prescriptions?.map((item, i) => (
            <Prescription
              item={item}
              key={i}
              setPrescriptions={setPrescriptions}
              index={i}
            />
          ))}

          {/* only display submit button if current day is on display */}
          {dateToString(new Date()) === dateToString(activeDate) && (
            <Button onClick={handleSubmit} mt={4}>
              Submit
            </Button>
          )}
        </Box>
      </Layout>
    </>
  );
}

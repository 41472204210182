import {
  lightFormat,
  differenceInMinutes,
  startOfDay,
  endOfDay,
  isSameDay,
  setDay,
} from "date-fns";
import axios from "axios";
import { waitFor } from "@testing-library/react";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export  function  getAvailable(selectedDate, data, elapse=45) {
  let date = new Date(selectedDate);
  let doctor = data?.doctor||{}
  let apptBydate = data?.apptBydate||[];
 
  let apptIntervals = [];

  if (apptBydate.length > 0) {
    apptIntervals = generateAppointmentsIntervals(
      filterByDay(date, apptBydate)
    );
  }
  let a = [];
  let u = (doctor.unavailable?.untilRemoved || []).map(([start, end]) => {
    return [setToCurrentWeek(date, start), setToCurrentWeek(date, end)];
  });
  (doctor.unavailable?.intervals || []).concat(u).forEach((interval) => {
    if (isSameDay(interval[0], interval[1])) a.push(interval);
    else {
      a.push([interval[0], endOfDay(interval[0]).getTime()]);
      a.push([startOfDay(interval[1]).getTime(), interval[1]]);
    }
  });
  
  a = filterByDay(date, a);

  let resp = [];
  let unavailableIntervals = mergeIntervals(a.concat(apptIntervals));


  unavailableIntervals.unshift([0, startOfDay(date).getTime()]);
  unavailableIntervals.push([endOfDay(date).getTime()+1000, 0]);
  

  let min = 60000 * elapse; // 45 min
  for (let i = 0; i < unavailableIntervals.length; i++) {
    let current = unavailableIntervals[i];
    let next = unavailableIntervals[i + 1];
    if (next) {
      let range = next[0] - current[1];
      if (range >= min) {
        let n = Math.floor(range / min);
        let left = current[1];

        /*for (let j = 0; j < n; j++) {
          resp.push([left, left + min]);
          left += min;
        }*/
        
        while (left+min<=next[0]) {
          resp.push([left, left + min]);
          left += 15*60*1000;

        }

      }
    }
  }
  
  return resp;
}



function setToCurrentWeek(currentDate, oldDate) {
  let old = new Date(oldDate);
  let diff = differenceInMinutes(oldDate, startOfDay(oldDate));
  let newDate = setDay(currentDate, old.getDay(), {weekStartsOn: 1});
  newDate = startOfDay(newDate);
  newDate.setMinutes(diff);
  return newDate.getTime();
}

function filterByDay(d, appointments) {
  return appointments.filter((appt) => {
    let apptDate = new Date(appt.ISOdate || appt[0]);
    return isSameDay(apptDate, d);
  });
}

function generateAppointmentsIntervals(appointments) {
  let intervals = [];
  appointments.forEach((appt) => {
    let start = new Date(appt.ISOdate).getTime();
    let end = start + parseInt(appt.duration.split(" ")[0]) * 60000;
    intervals.push([start, end]);
  });

  return intervals;
}

function mergeIntervals(intervals) {
  if (intervals.length === 0) return intervals;
  intervals.sort((a, b) => {
    return a[0] - b[0];
  });
  let merged = [];
  let current = intervals[0];
  for (let i of intervals) {
    if (current[1] >= i[0]) {
      current[1] = i[1] <= current[1] ? current[1] : i[1];
    } else {
      merged.push(current);
      current = i;
    }
  }
  merged.push(current);
  return merged;
}
const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";
export async function getDoctorSchedulesByDisorderAndInsurance(state,{
  date,
  disorders,
  insurances,
  lid,
  db
},signal) {

  let configNotifications = {
    method: "POST",
    url: `${url}/getDoctorSchedulesByDisorderAndInsurance`,
    headers: {
      "Content-Type": "application/json",
       Authorization: "Bearer " + state.jwt,
    },
    signal,
    data: JSON.stringify({date, disorders, insurances,lid,db,id: state?.jwt ? jwtDecode(state.jwt).id : "",}),
  };
  return axios(configNotifications);
}

import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({location}) {
  let infoStyle = {
    fontSize: "10px",
    marginTop: 2,
  };
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        display: "flex",
        paddingHorizontal: 15,
      }}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",

          display: "flex",
        }}
      >
        {location?.logo && (
          <Image
            style={{
              height: "30px",
            }}
            src={location.logo}
          ></Image>
        )}
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "col",
          fontSize: 9,
          display: "flex",
        }}
      >
        <Text style={infoStyle}>{`${location?.name || "N/A"}`}</Text>
        <Text style={infoStyle}>{`${location?.address || "N/A"}`}</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              display: "flex",
              marginRight: 3,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 1,
              }}
            >
              {"Ph:"}
            </Text>
            <Text>{`${location?.phone || "N/A"}`}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 1,
              }}
            >
              {"Fx:"}
            </Text>
            <Text>{`${location?.fax || "N/A"}`}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}

import React, {useState, useEffect, useRef, useReducer} from "react";
import {crud} from "../crudRequests";
import {lightFormat} from "date-fns";

export default function LoginHistory({dashState, dashDispatch}) {
  const [logins, setLogins] = useState([]);
  const {patient, relationship} = dashState;
  useEffect(() => {
    crud(dashState, [
      {
        db: dashState.db,
        collection: "security",
        parameters: [
          {
            userId:
              dashState?.userType === "patient"
                ? patient?.pid
                : relationship?.rid,
          },
        ],
        method: "findOne",
      },
    ])
      .then((res) => {
        setLogins((res.data[0]?.logins || []).reverse());
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  return (
    <div className="space-y-4">
      <h1 className="text-gray-800 text-2xl font-semibold">Login History</h1>
      <p className="text-gray-600 text-sm">
        {`If you don't recognize any of these logins, please change your password and `}
        <span className="cursor-pointer hover:text-gray-800 decoration-solid underline">
          {"contact us."}
        </span>
      </p>

      <div
        className="rounded-[10px]  mt-2 py-2 text-background font-semibold text-center mr-2"
        style={{backgroundColor: "black"}}
      >
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Date/Time
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            IP Address
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Device Browser
          </li>
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[10rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2">
          {logins.map((log, i) => {
            return (
              <div
                key={i}
                className=" rounded-[10px] mt-0 py-3 text-sm text-white font-semibold text-center h-fit"
                style={{backgroundColor: "gray"}}
              >
                <ul className="flex justify-center items-center md:text-sm w-full">
                  <li className=" flex justify-center items-center flex-1  text-[16px] ">
                    {lightFormat(new Date(log.timestamp), "dd/MM/yy HH:mm")}
                  </li>
                  <li className=" flex justify-center items-center flex-1  text-[16px] ">
                    {log.ip?.toString() || ""}
                  </li>
                  <li className=" flex justify-center items-center flex-1  text-[16px] ">
                    {log.client?.toString() || ""}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

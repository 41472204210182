import React, {useState} from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";
import {crud} from "../crudRequests";
import {crudStorage, crudStorageUpload} from "../storageRequests";
import {notificationsData} from "../additional_files/notifications";
import FormsPDF from "./FormsPDF";
import {pdf} from "@react-pdf/renderer";
export default function PreviewForm(props) {
  const {data, type, close, state, updatedFormValues, setLoading, socket} =
    props;
  const form = JSON.parse(data.formFields);
  const [formValues, setFormValues] = useState(form);

  function renderElement(obj) {
    const {element, key, props = {}, children} = obj;

    if (
      element === "input" &&
      props.type === "file" &&
      props.fileFields &&
      props.fileFields.length > 0
    ) {
      const fileNames = props.fileFields
        .map((fileField) => fileField.fileName)
        .join(", ");
      return React.createElement("div", {key}, fileNames);
    }

    if (children && !Array.isArray(children)) {
      return React.createElement(element, {key, ...props}, children);
    }

    const childElements = children
      ? children.map((child, index) =>
          renderElement({...child, key: child.key || index})
        )
      : null;

    return React.createElement(element, {key, ...props}, childElements);
  }

  function handleInputChange(
    rowIdx,
    colIdx,
    value,
    isCheckbox = false,
    checkboxIndex = -1,
    file
  ) {
    setFormValues((prevFormValues) => {
      const updatedFormValues = {...prevFormValues};
      const formElement = updatedFormValues[rowIdx][colIdx];

      if (
        formElement.element === "input" &&
        formElement.props.type !== "checkbox" &&
        formElement.props.type !== "file"
      ) {
        formElement.props.value = value;
      } else if (formElement.element === "textarea") {
        formElement.props.value = value;
      } else if (formElement.element === "select") {
        formElement.props.value = value;
      } else if (formElement.element === "div" && formElement.children) {
        formElement.children.forEach((child) => {
          if (
            child.element === "input" &&
            child.props.type !== "checkbox" &&
            child.props.type !== "file"
          ) {
            child.props.value = value;
          } else if (child.element === "textarea") {
            child.props.value = value;
          } else if (child.element === "select") {
            child.props.value = value;
          } else if (child.element === "input" && child.props.type === "file") {
            // Handle file upload field
            // Access the uploaded file using `event.target.files[0]`
            if (file) {
              const reader = new FileReader();
              reader.onload = function (event) {
                const base64Content = event.target.result.split(",")[1];
                const fileFields = {
                  fieldId: uuidv4(),
                  fileName: file.name,
                  content: base64Content,
                };
                child.props.fileFields = [fileFields];
              };
              reader.readAsDataURL(file);
            }
          } else if (child.element === "div" && child.children) {
            child.children.forEach((grandchild) => {
              if (
                grandchild.element === "input" &&
                grandchild.props.type !== "checkbox" &&
                grandchild.props.type !== "file"
              ) {
                grandchild.props.value = value;
              } else if (grandchild.element === "textarea") {
                grandchild.props.value = value;
              } else if (grandchild.element === "select") {
                grandchild.props.value = value;
              }
            });
          }
        });
        if (isCheckbox && formElement.children) {
          const checkboxElement =
            formElement.children[1].children[checkboxIndex].children[0];
          checkboxElement.props.checked = !checkboxElement.props.checked;
        }
      }

      return updatedFormValues;
    });
  }

  async function completeForm() {
    console.log(formValues);
    let payload = {
      fsid: data.fsid,
      pid: data.pid,
      fid: data.fid,
      formTitle: data.formTitle,
      createdDate: data.createdDate,
      assignedDate: data.assignedDate,
      createdBy: data.createdBy,
      assignedBy: data.assignedBy,
      formFields: JSON.stringify(formValues),
      completed: true,
      completedDate: new Date(),
    };

    if (state.relationship) {
      payload.rid = state.relationship.rid;
    }

    let ntf = notificationsData(
      "patient",
      "Form Completed",
      {...payload, pName: state.patient.fName + " " + state.patient.lName},
      state
    );

    crud(
      state,
      [
        {
          db: state.db,
          collection: "form_submissions",
          parameters: [{fsid: payload.fsid}, {$set: payload}],
          method: "updateOne",
        },
      ],
      {
        socket: {
          rooms: [data.senderId],
          arguments: ["completed_form", {...data, ...payload}, {ntf}],
        },
      }
    ).then(() => {
      uploadFile();
      Swal.fire(
        "Form Completed!",
        "Thank you for completing your form.",
        "success"
      ).then(() => {
        close(false);
        setLoading(true);
        updatedFormValues();
      });
    });
  }

  async function uploadFile() {
    try {
      //  console.log(formValues);
      let blob = await pdf(
        <FormsPDF data={data} form={formValues} state={state} returnDoc />
      ).toBlob();

      await crudStorageUpload(
        state,
        new File(
          [blob],
          "prefix_" +
            new Date().getTime() +
            "-" +
            blob.size / 1000 +
            "-" +
            data.formTitle +
            ".pdf"
        ),
        "brightlighthealth",
        state.patient["pid"] + "/Form_Submissions/"
      );
    } catch (error) {
      console.log(error);
    }
  }

  function getCheckboxIndex(event) {
    let parentDiv = event.target.parentElement;
    while (parentDiv && parentDiv.tagName.toLowerCase() !== "div") {
      parentDiv = parentDiv.parentElement;
    }

    if (parentDiv) {
      const children = parentDiv.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.contains(event.target)) {
          return i;
        }
      }
    }

    return -1;
  }
  return (
    <>
      <div
        className="fixed z-[70] -top-10 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-24 text-center sm:block sm:p-0">
          <div className="bg-[#EFEFEF] relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
            <div className="pt-1">
              <div className="absolute right-12 top-6">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => close(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="h-[30rem] overflow-y-scroll">
              {Object.keys(form).map((rowIdx) => {
                return (
                  <div
                    key={rowIdx}
                    className={`grid space-x-4 mb-2 px-4 grid-cols-${
                      Object.keys(form[rowIdx]).length
                    }`}
                  >
                    {Object.keys(form[rowIdx]).map((colIdx) => {
                      const element = form[rowIdx][colIdx];
                      const {key, props = {}} = element;

                      return (
                        <div key={colIdx}>
                          {element &&
                            renderElement({
                              ...element,
                              props: {
                                ...props,
                                onChange: (e) => {
                                  const isCheckbox =
                                    e.target.type === "checkbox";
                                  const checkboxIndex = isCheckbox
                                    ? getCheckboxIndex(e)
                                    : -1;
                                  handleInputChange(
                                    rowIdx,
                                    colIdx,
                                    e.target.value,
                                    isCheckbox,
                                    checkboxIndex,
                                    e.target.files?.[0]
                                  );
                                },
                              },
                            })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {type !== "completed" ? (
              <button
                type="button"
                className="focus:ring-0 w-[20rem] py-2 rounded-lg font-medium bg-off text-white my-5 mt-7 block mx-auto"
                onClick={completeForm}
              >
                Complete Form{" "}
                {type === "view" && (
                  <span className="font-thin">(sample button)</span>
                )}
              </button>
            ) : (
              <div className="mb-8">&nbsp;</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, {useState, useEffect, useRef, useReducer} from "react";
import {crud} from "../crudRequests";
export default function TFA({dashState, dashDispatch}) {
  const {patient, relationship} = dashState;
  const [ac, setAc] = useState(false);

  useEffect(() => {
    crud(dashState, [
      {
        db: dashState.db,
        collection: "security",
        parameters: [
          {
            userId:
              dashState?.userType === "patient"
                ? patient?.pid
                : relationship?.rid,
          },
        ],
        method: "findOne",
      },
    ]).then((res) => setAc(res.data[0].tfa));
  }, []);

  return (
    <div className=" space-y-5">
      <div className="flex items-center space-x-4">
        <h1 className="text-gray-800 text-2xl font-semibold">
          Two-Factor Authentication
        </h1>
        <span className="bg-[#5754FF] rounded-full p-1 px-2 text-white text-xs">
          Recomended
        </span>
      </div>
      <p className="text-sm text-gray-600 max-w-[32rem]">
        By turning 2FA on you consent to receiving identity confirmation emails
        from Brightlight after every login attempt to secure your account
        accessibility.
      </p>
      <div className="space-y-2">
        <p className="text-gray-800 text-sm">
          Two-factor authentication is currently
          <span className="font-bold">
            {ac ? " active" : " disabled"}{" "}
          </span>{" "}
        </p>

        <div
          className="bg-gray-800 rounded-full p-2 w-40 cursor-pointer transition duration-500"
          onClick={() => {
            crud(dashState, [
              {
                db: dashState.db,
                collection: "security",
                parameters: [
                  {
                    userId:
                      dashState?.userType === "patient"
                        ? patient?.pid
                        : relationship?.rid,
                  },
                  {$set: {tfa: ac ? false : true}},
                ],
                method: "updateOne",
              },
            ]);
            setAc((e) => !e);
          }}
          style={{
            backgroundColor: ac ? "black" : "#FFFFFF",
            boxShadow: !ac && "0 0 0 2px inset black",
          }}
        >
          <p
            className="w-7 h-7 rounded-full bg-white transition duration-500"
            style={{
              transform: ac ? "translateX(118px)" : "translateX(0)",
              backgroundColor: ac ? "#FFFFFF" : "black",
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}

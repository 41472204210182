import {promptAI} from "./promptAI";

export async function predictDisorders(context, disorders) {
  let prompt =
    'If a patient said "' +
    context.currentResponse +
    '" in response to "' +
    context.currentQuestion +
    '", which of the following possible disorders would a clinician potentially screen them for? Provide an or multiple answers from the following list: ' +
    disorders
      .map((d, i) => d.name.trim())
      .filter((n) => n)
      .join(", ") +
    '. Only list the most relevant disorder(s) based on what was said. If the patient has said something positive, neutral or unrelated then answer "This patient doesnt need to be screened for any of the listed disorders".';

  const predictedDisordersString = await promptAI(
    "You are a clinical decision support system",
    prompt
  );

  const predictedDisorders = disorders.filter((d) =>
    predictedDisordersString.data.output.includes(d.name.trim())
  );

  return predictedDisorders;
}

import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef} from "react";
import {checkResetParams, resetPassword} from "../authRequests";
import {useParams, useNavigate} from "react-router-dom";
import {loadDataForPatientLogin, crud, viewConsentForms} from "../crudRequests";
import clientInfo from "../additional_files/loginInfo";
import {HiShieldCheck} from "react-icons/hi";
import {RiDeleteBin5Line} from "react-icons/ri";
import {MdClose} from "react-icons/md";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
export default function ResetPwdForm() {
  const [pid, setPid] = useState("");
  const [rid, setRid] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [userType, setUserType] = useState("");
  const [consentForms, setConsentForms] = useState([]);

  const [serverError, setServerError] = useState("");
  const [authError, setAuthError] = useState("");
  const [loading, setLoading] = useState(false);

  const loadedRef = useRef(null);
  const loaderRef = useRef(null);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [signature, setSignature] = useState(false);

  useEffect(() => {
    const checkPasswords = async () => {
      if (password === confirmPassword) {
        setPasswordsMatch(true);
      } else {
        setPasswordsMatch(false);
      }
    };

    checkPasswords();
  }, [password, confirmPassword]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  function reset(e) {
    if (org?.db) {
      e.preventDefault();
      if (passwordsMatch && password.length >= 8) {
        setLoading(true);

        const action = () => {
          let reset = window.location.href.split("?reset=")[1];
          setLoading(true);
          resetPassword(
            reset,
            userType === "patient" ? pid : rid,
            password,
            userType,
            org.db
          )
            .then(async (res) => {
              loaderRef.current.className = "block";
              loadedRef.current.className = "hidden";

              loadDataForPatientLogin(
                {
                  jwt: res.data.token,
                  patient: res.data.patient,
                  relationship: res.data.relationship,
                  destination: "../",
                  db: org?.db,
                  userType,
                },
                navigate
              );

              let jsonIp = await axios({
                url: "https://api.ipify.org?format=json",
              });
              let userId = userType === "patient" ? pid : rid;

              crud({jwt: res.data.token}, [
                {
                  db: org?.db,
                  collection: "security",
                  parameters: [
                    {userId},
                    {
                      $push: {
                        logins: {
                          ip: jsonIp.data.ip,
                          client: clientInfo(),
                          timestamp: new Date(),
                        },
                      },
                    },
                    {upsert: true},
                  ],
                  method: "findOneAndUpdate",
                },
              ]);
            })
            .catch((err) => {
              console.log(err);
            })
            .then(() => {
              setLoading(false);
            });
        };

        if (signature) {
          action();
        } else {
          viewConsentForms(org.db).then((res) => {
            if ((res?.data || []).length > 0) {
              setConsentForms(res?.data || []);
              setLoading(false);
            } else action();
          });
        }
      }
    }
  }

  useEffect(
    function () {
      let reset = window.location.href.split("?reset=")[1];
      checkResetParams(reset)
        .then((response) => {
          setEmail(response.data.email);
          setPid(response.data.pid);
          setRid(response.data.rid);
          setOrg(response.data.org);
          setSignature(response.data.consentSignature);
          setUserType(response.data.userType);
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "../login";
        });
    },

    []
  );

  return !(consentForms?.length > 0) ? (
    <div className="h-screen grid place-items-center">
      <div ref={loaderRef} className="hidden">
        <div className="border p-[6.5rem] rounded-md">
          <div className="flex justify-center align-middle">
            <img src="/images/loader.gif" className="h-36" />
          </div>
        </div>
      </div>

      <div ref={loadedRef}>
        <form onSubmit={reset} className="border p-12 rounded-md">
          {org?.logo ? (
            <img src={org.logo} className="w-64 mx-auto mb-5" />
          ) : (
            <img src="/images/logo.png" className="w-64 mx-auto mb-5" />
          )}
          {authError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      Invalid link!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {serverError && (
            <div className="">
              <div className="mb-5 flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      <span className="font-semibold">
                        The server has encountered an
                        <br />
                        internal error.
                      </span>
                      <br />
                      Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="input-group">
            <div className="input-label text-xs -ml-3 mr-4 -mt-3">
              Please choose a new password (min. 8 letters) for your Brightlight
              Account associated with <b>{email}</b>.
            </div>
            <input
              type="password"
              id="create_password"
              onChange={handlePasswordChange}
              required
              autoComplete="off"
              className="input mt-[4.5rem] w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
            />
            <label htmlFor="password" className="input-label mt-[4rem]">
              Create Password
            </label>
          </div>
          <div className="input-group">
            <input
              type="password"
              id="confirm_password"
              onChange={handleConfirmPasswordChange}
              required
              autoComplete="off"
              className="input  w-72 py-3 px-6 rounded-[4px] my-2 focus:border-[#3871e0] focus:ring-1 focus:ring-[#3871e0] border"
            />
            <label htmlFor="password2" className="input-label">
              Confirm Password
            </label>
          </div>
          {password && confirmPassword ? (
            passwordsMatch ? (
              <div className="text-green-500 text-xs mb-1">Passwords match</div>
            ) : (
              <div className="text-red-500 text-xs mb-2">
                Passwords do not match
              </div>
            )
          ) : (
            <span></span>
          )}
          {password ? (
            password.length >= 8 ? (
              <div className="text-green-500 text-xs mb-3">
                Password is satisfactory character length
              </div>
            ) : (
              <div className="text-red-500 text-xs mb-3">
                Password must be min. 8 letters
              </div>
            )
          ) : (
            <span></span>
          )}

          <div className="justify-center">
            <button
              type="submit"
              className="w-72 p-2.5 px-5 rounded-[4px] my-2 flex justify-center items-center bg-dark text-background font-medium text-medium right cursor-pointer"
              disabled={loading}
            >
              Continue
              {loading && (
                <span className="">
                  <svg
                    className="animate-spin ml-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <ConsentForm
      {...{org, userType, rid, pid, password, consentForms, setConsentForms}}
    />
  );
}

function ConsentForm({
  org,
  userType,
  rid,
  pid,
  password,
  consentForms,
  setConsentForms,
}) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [showPdf, setShowPdf] = useState(null);
  const [imageData, setImageData] = useState(null);
  const sigRef = useRef(null);

  const saveSignature = (signature) => {
    setImageData(signature);
  };

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowPdf(null);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  function disabled() {
    return org?.db && !consentForms.find((f) => !f.confirmed) && imageData;
  }

  function reset(e) {
    e.preventDefault();
    if (org?.db && !consentForms.find((f) => !f.confirmed)) {
      setLoader(true);
      let reset = window.location.href.split("?reset=")[1];
      resetPassword(
        reset,
        userType === "patient" ? pid : rid,
        password,
        userType,
        org.db
      )
        .then(async (res) => {
          loadDataForPatientLogin(
            {
              jwt: res.data.token,
              patient: res.data.patient,
              relationship: res.data.relationship,
              destination: "../",
              db: org?.db,
              userType,
            },
            navigate
          );

          let jsonIp = await axios({url: "https://api.ipify.org?format=json"});
          let userId = res.data.patient.pid;

          crud({jwt: res.data.token}, [
            {
              db: org?.db,
              collection: "security",
              parameters: [
                {userId},
                {
                  $push: {
                    logins: {
                      ip: jsonIp.data.ip,
                      client: clientInfo(),
                      timestamp: new Date(),
                    },
                  },
                },
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: org?.db,
              collection: "patients",
              parameters: [
                {pid: userId},
                {$set: {consentSignature: imageData}},
              ],
              method: "findOneAndUpdate",
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="h-screen grid place-items-center">
      {showPdf && (
        <PDFModal
          form={showPdf}
          setConsentForms={setConsentForms}
          showModal={setShowPdf}
        />
      )}
      {loader ? (
        <div>
          <div className="border p-[6.5rem] rounded-md">
            <div className="flex justify-center align-middle">
              <img src="/images/loader.gif" className="h-36" />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form
            onSubmit={reset}
            className="border p-12 rounded-md flex items-center flex-col"
          >
            {org?.logo ? (
              <img src={org.logo} className="w-64 mx-auto mb-5" />
            ) : (
              <img src="/images/logo.png" className="w-64 mx-auto mb-5" />
            )}

            <div className="">
              <div className="input-label text-xs text-[#8F8CFF]">
                Please read and confirm the consent forms to continue.
              </div>
              <div className="max-h-[10rem] overflow-hidden min-h-[5rem] p-1 bg-white  my-4 rounded-md border">
                <ul className="h-full overflow-y-scroll sbar">
                  {consentForms.map((f, i) => (
                    <li
                      key={i}
                      className="flex items-center p-2 w-[22rem] space-x-1"
                    >
                      <span
                        className="text-slate-300 text-[2rem] "
                        style={{color: f.confirmed ? "#16a34a" : null}}
                      >
                        <HiShieldCheck />
                      </span>
                      <span
                        className="text-xs cursor-pointer font-medium text-slate-500 hover:text-indigo-600"
                        style={{color: f.confirmed ? "#4f46e5" : null}}
                        onClick={() => {
                          setShowPdf(f);
                        }}
                      >{`${org.name} ${f.formTitle?.replace(
                        /(\.\w+)$/,
                        ""
                      )}`}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Signature sigRef={sigRef} saveSignature={saveSignature} />
            <div className="justify-center">
              <button
                className={`${
                  !disabled() ? "cursor-not-allowed" : "cursor-pointer"
                } w-72 p-2.5 disabled:opacity-50  px-5 rounded-[4px] my-2 flex justify-center items-center bg-dark text-background font-medium text-medium right`}
                disabled={!disabled()}
              >
                Continue to account
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

function Signature({sigRef, saveSignature}) {
  return (
    <div className="relative rounded-lg border w-full my-3">
      <span className="absolute text-xs -top-[9px] left-3 text-[#8F8CFF]  bg-white px-[2px]">
        Signature
      </span>
      <span
        className="flex absolute cursor-pointer hover:text-indigo-600 -top-[15px] right-3 justify-center items-center text-[#a6a3f8] text-[1.4rem] bg-white"
        onClick={(e) => {
          e.stopPropagation();
          sigRef.current.clear();
          saveSignature("");
        }}
      >
        <RiDeleteBin5Line />
      </span>
      <SignatureCanvas
        penColor="blue"
        maxWidth={2}
        ref={sigRef}
        onEnd={() => saveSignature(sigRef.current.toDataURL("image/png"))}
        canvasProps={{height: 150}}
      />
    </div>
  );
}

function PDFModal({form, setConsentForms, showModal}) {
  let iframeStyle = {
    height: "100%",
    width: "100%",
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-[9999] flex justify-center items-center">
      <div className="h-[90%] w-[50rem] relative flex flex-col justify-center items-center p-10 pb-6  bg-white rounded-lg">
        {
          <MdClose
            className="text-black cursor-pointer text-lg absolute right-4 top-4 "
            onClick={() => {
              showModal(null);
            }}
          />
        }
        <iframe
          src={form.data}
          style={iframeStyle}
          className="w-full hfull  rounded-lg sbar2 border"
        ></iframe>
        <div className="flex items-center justify-center w-full space-x-1 mt-5">
          <div
            className="flex items-center justify-center space-x-1 cursor-pointer"
            onClick={() => {
              setConsentForms((prev) => {
                return prev.map((e) => {
                  return e.fid === form.fid
                    ? {...e, confirmed: !e.confirmed}
                    : e;
                });
              });
              showModal((prev) => ({...prev, confirmed: !prev.confirmed}));
            }}
          >
            <span
              className="text-slate-300 text-[2rem]"
              style={{color: form.confirmed ? "#16a34a" : null}}
            >
              <HiShieldCheck />
            </span>
            <span
              className="text-xs font-medium text-slate-500"
              style={{color: form.confirmed ? "#4f46e5" : null}}
            >
              I accept the terms mentioned in this form.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

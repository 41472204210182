import axios from "axios";

const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://blh-server-2001610adcd9.herokuapp.com";

export function finishIntake(dashState, disorders, context, setContext) {
  setContext({
    ...context,
    finished: true,
  });

  let disordersDetected = [];
  let detectedDisorderIds = new Set();

  context?.symptomsDetected?.forEach((symptom) => {
    symptom?.symptom?.associatedDisorders?.forEach((aD) => {
      if (!detectedDisorderIds.has(aD)) {
        let disorder = disorders.find((d) => d.did === aD);
        if (disorder) {
          disordersDetected.push(disorder);
          detectedDisorderIds.add(aD);
        }
      }
    });
  });

  return axios({
    method: "POST",
    url: `${url}/processIntake`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + dashState.jwt,
    },
    data: JSON.stringify({
      db: dashState.db,
      id: dashState.patient.pid,
      disorders: disordersDetected,
      symptomsDetected: context?.symptomsDetected,
      conversation: context?.conversation,
    }),
  });
}

import React, {useState, useEffect, useRef} from "react";

import {
  PDFDownloadLink,
  Document,
  Page,
  PDFViewer,
  View,
  Text,
  StyleSheet,
  Note,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import {lightFormat} from "date-fns";
import InterRegular from "../additional_files/Inter/static/Inter-Regular.ttf";
import InterMedium from "../additional_files/Inter/static/Inter-Medium.ttf";
import InterSemibold from "../additional_files/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../additional_files/Inter/static/Inter-Bold.ttf";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
    {
      src: InterSemibold,
      fontWeight: 600,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
  ],
});

export default function ({
  transaction,
  state,
  invoice,
  returnDoc,
  doctor,
  tpp,
}) {
  let locSrc = state.locations?.find((l) => l.lid === transaction.lid);
  let orgSrc = state.organization;
  //console.log(transaction);

  const styles = StyleSheet.create({
    page: {padding: 30, paddingTop: 20, fontFamily: "Inter"},
  });

  let obj = {
    DESCRIPTION: transaction.serviceType || "",
    "DOCTOR NAME": transaction.dName || "",
    "PATIENT NAME": transaction.pName || "",
    DURATION: transaction.time || "",
  };

  let statusObj = {
    [invoice ? "INVOICE TOTAL" : "RECEIPT TOTAL"]:
      (tpp ? transaction.tppAmount ?? 0 : transaction.patientAmount ?? 0) +
      " (CAD)",
    "Amount Paid":
      (tpp
        ? transaction.amountPaidByTpp ?? 0
        : transaction.amountPaidByPatient ?? 0) + " (CAD)",
    "Outstanding Balance":
      (tpp
        ? (transaction.tppAmount ?? 0) - (transaction.amountPaidByTpp ?? 0)
        : (transaction.patientAmount ?? 0) -
          (transaction.amountPaidByPatient ?? 0)) + " (CAD)",
  };

  let Doc = (
    <Document>
      <Page style={styles.page} size="A4" wrap={true}>
        <View
          style={{
            border: "2px solid #64748b",
            borderRadius: "10px",
            padding: "10px",
            paddingTop: "0px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginBottom: "20px",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",

              marginTop: "10px",
            }}
          >
            <View
              style={{
                flexDirection: "col",
                marginBottom: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              {orgSrc?.logo && (
                <Image
                  style={{
                    height: "60px",
                    padding: "4px",
                  }}
                  src={orgSrc.logo}
                ></Image>
              )}
            </View>

            <View
              style={{
                flexDirection: "col",
                marginBottom: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "flex-end",
                marginTop: "5px",
                paddingBottom: 2,
                paddingLeft: 50,
                borderBottom: "1px solid #64748b",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: 2,
                }}
              >
                {`${orgSrc?.name || "-"}`}
              </Text>
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: 2,
                }}
              >
                {`${orgSrc?.address || "-"}`}
              </Text>
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: 2,
                }}
              >
                {`${orgSrc?.phone || "-"}`}
              </Text>
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: 2,
                }}
              >
                {`${orgSrc?.phone || "-"}`}
              </Text>
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: 2,
                }}
              >
                {`${orgSrc?.url || "-"}`}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginBottom: "30px",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              borderBottom: "1px solid #64748b",
            }}
          >
            <View
              style={{
                flexDirection: "col",
                marginBottom: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  fontSize: "16px",

                  marginTop: 2,
                }}
              >
                {`Billed to:`}
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  marginTop: 2,
                  color: "#4f46e5",
                }}
              >
                {`${tpp ? tpp?.name : transaction?.pName}`}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  marginTop: 2,
                  color: "#4f46e5",
                }}
              >
                {`Email: ${
                  tpp ? tpp?.email || "-" : transaction?.pEmail || "-"
                }`}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "col",
                marginBottom: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: 2,
                }}
              >
                {invoice ? `INVOICE` : `RECEIPT`}
              </Text>
              <Text
                style={{
                  fontSize: "12px",

                  marginTop: 2,
                }}
              >
                {`Date of Issue: ${lightFormat(
                  new Date(),
                  "dd/MM/yyyy h:mm aa"
                )}`}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginBottom: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {Object.entries(obj).map(([key, val], i) => (
              <View
                style={{
                  flexDirection: "col",
                  flexWrap: "wrap",
                  alignItems: "center",
                  flex: 1,
                }}
                key={i}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: 2,
                    borderBottom: "1px solid #64748b",
                    padding: 5,
                    textAlign: "center",
                    width: "100%",
                    fontWeight: 600,
                  }}
                >
                  {key}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: 2,
                    padding: 5,
                    textAlign: "center",
                  }}
                >
                  {val}
                </Text>
              </View>
            ))}
          </View>

          <View
            style={{
              flexDirection: "row",
              marginBottom: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start ",
                alignItems: "end",
                flex: 1,
                position: "relative",
              }}
            ></View>
            <View
              style={{
                flexDirection: "col",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                borderTop: "1px solid #64748b",
                borderBottom: "1px solid #64748b",
              }}
            >
              {Object.entries(statusObj).map(([key, val], i) => (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  key={i}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      marginTop: 2,
                      padding: 5,
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    {key}
                  </Text>
                  <Text
                    style={{
                      fontSize: "12px",
                      marginTop: 2,
                      padding: 5,
                      textAlign: "center",
                    }}
                  >
                    {val}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginBottom: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                position: "relative",
              }}
            >
              <View
                style={{
                  flexDirection: "col",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {(doctor?.signature || doctor?.signatureBlob) && (
                  <View
                    style={{
                      flexDirection: "col",
                      position: "relative",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    {
                      <Image
                        style={{
                          height: "50px",
                          padding: "4px",
                        }}
                        src={doctor.signature || doctor.signatureBlob}
                      ></Image>
                    }
                  </View>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    position: "relative",
                    justifyContent: "flex-start",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: 600,
                      textAlign: "start",
                    }}
                  >
                    Signed by:
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    {" Dr. " + doctor.name}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: 600,
                      textAlign: "start",
                    }}
                  >
                    {"Registration Number: "}
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    {doctor.registrationId || "-"}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "col",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "60px",
            }}
          >
            {locSrc?.logo && (
              <Image
                style={{
                  height: "50px",
                  padding: "4px",
                }}
                src={locSrc.logo}
              ></Image>
            )}

            <Text
              style={{
                fontSize: "10px",
                marginTop: 2,
                padding: 5,
                textAlign: "center",
              }}
            >
              {locSrc.name}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  if (returnDoc) return Doc;

  return (
    <PDFViewer width={"100%"} height={"100%"}>
      {Doc}
    </PDFViewer>
  );
}

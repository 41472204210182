import {
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  Tag,
  useToast,
} from "@chakra-ui/react";
import {useState, useEffect} from "react";
import {fetchDisorders} from "./helpers/fetchDisorders";
import {fetchSymptoms} from "./helpers/fetchSymptoms";
import {fetchQuestions} from "./helpers/fetchQuestions";
import MainForm from "./MainForm";
import Conversation from "./Conversation";

export default function MasterIntake({dashState}) {
  const userId = "000";
  const [context, setContext] = useState();
  const [disorders, setDisorders] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [qualifierQuestions, setQualifierQuestions] = useState([]);
  const [durationQuestions, setDurationQuestions] = useState([]);
  const [followupQuestions, setFollowupQuestions] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchDisorders(setDisorders, userId, toast);
    fetchSymptoms(setSymptoms, userId, toast);
    fetchQuestions(
      setQualifierQuestions,
      setDurationQuestions,
      setFollowupQuestions,
      userId,
      toast
    );
  }, []);

  return (
    <>
      {/*<Image src="../images/black_logo.png" h="72px" />*/}
      <Tabs orientation="vertical" h={"88px"}>
        <TabList>
          <Tab>
            <Tag
              bg="white"
              px="2"
              py="1"
              rounded="md"
              w="86px"
              justifyContent={"center"}
            >
              Current Q.
            </Tag>
          </Tab>
          <Tab>
            <Tag
              bg="black"
              color="white"
              px="2"
              py="1"
              rounded="md"
              w="86px"
              justifyContent={"center"}
            >
              Past QAs
            </Tag>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MainForm
              dashState={dashState}
              disorders={disorders}
              symptoms={symptoms}
              qualifierQuestions={qualifierQuestions}
              durationQuestions={durationQuestions}
              followupQuestions={followupQuestions}
              context={context}
              setContext={setContext}
            />
          </TabPanel>
          <TabPanel>
            <Conversation context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

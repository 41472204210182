import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Switch,
  useToast,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {crud, updatePreferences} from "../crudRequests";

const Preferences = ({dashState}) => {
  const [email, setEmail] = useState(
    dashState?.patient?.emailPermission || false
  );
  const [text, setText] = useState(dashState?.patient?.textPermission || false);

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    crud(dashState, [
      {
        db: dashState.db,
        collection: "patients",
        parameters: [{pid: dashState?.patient?.pid}],
        method: "findOne",
      },
    ]).then((res) => {
      if (
        res?.data?.[0]?.emailPermission !== undefined &&
        res?.data?.[0]?.textPermission !== undefined
      ) {
        setEmail(res?.data?.[0]?.emailPermission);
        setText(res?.data?.[0]?.textPermission);
      }
    });
  }, [dashState]);

  // const []

  return (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0">
          Enable email alerts
        </FormLabel>
        <Button
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            setEmail(!email);
            toast.promise(
              updatePreferences(
                dashState,
                dashState?.patient?.pid || "",
                !email,
                text
              )
                .then(() => setLoading(false))
                .catch(() => setLoading(false)),
              {
                success: {
                  title: "Success",
                  description: "Preference updated",
                },
                error: {
                  title: "Error",
                  description: "Something wrong",
                },
                loading: {title: "Loading...", description: "Please wait"},
              }
            );
          }}
        >
          <Switch id="email-alerts" isChecked={email} />
        </Button>
      </FormControl>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="text-alerts" mb="0">
          Enable text notifications
        </FormLabel>
        <Button
          isDisabled={loading}
          onClick={() => {
            setLoading(true);
            setText(!text);
            toast.promise(
              updatePreferences(
                dashState,
                dashState?.patient?.pid || "",
                email,
                !text
              )
                .then(() => setLoading(false))
                .catch(() => setLoading(false)),
              {
                success: {
                  title: "Success",
                  description: "Preference updated",
                },
                error: {
                  title: "Error",
                  description: "Something wrong",
                },
                loading: {title: "Loading...", description: "Please wait"},
              }
            );
          }}
        >
          <Switch id="text-alerts" isChecked={text} isDisabled={loading} />
        </Button>
      </FormControl>
    </Box>
  );
};

export default Preferences;

import React, {useState, useEffect, useRef} from "react";

import {View, Text, Image} from "@react-pdf/renderer";

export default function ({organization}) {
  return (
    <View
      wrap={false}
      style={{
        flexDirection: "col",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
      }}
    >
      {organization?.logo && (
        <Image
          style={{
            height: "30px",
          }}
          src={organization.logo}
        ></Image>
      )}
    </View>
  );
}

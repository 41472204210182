import React, { useState } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import "./dateselect.css"

const DateSelect = (props) => {
  const [activeDate, setActiveDate] = useState(new Date());
  const {
    selectedDate,
    setSelectedDate,
    validDates,
  } = props;

  const handlePreviousWeek = () => {
    const newDate = new Date(activeDate);
    newDate.setDate(activeDate.getDate() - 7);
    setActiveDate(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(activeDate);
    newDate.setDate(activeDate.getDate() + 7);
    setActiveDate(newDate);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const renderWeekdays = () => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekdays.map((weekday) => <div key={weekday}>{weekday}</div>);
  };

  const renderDates = () => {
    const startDate = new Date(activeDate);
    startDate.setDate(activeDate.getDate() - activeDate.getDay());

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      const isSelected = currentDate.getDate() === selectedDate.getDate() && currentDate.getMonth() === selectedDate.getMonth() && currentDate.getFullYear() === selectedDate.getFullYear();
      const isValid = validDates.includes(currentDate.toISOString().slice(0, 10));
      dates.push(
        <div
          key={currentDate}
          className={
            `text-center flex items-center justify-center w-10 h-10 rounded-full
              ${isSelected ? 'bg-[#5652ff] text-white' : (isValid ? 'text-gray-700' : 'text-gray-300')}  
              ${isValid && !isSelected ? 'bg-gray-200 hover:bg-gray-300 cursor-pointer' : ''}`
          }
          onClick={() => isValid && handleDateClick(currentDate)}
        >
          {currentDate.getDate()}
        </div>
      );
    }
    return dates;
  };

  return (
    <div className="date-selection-container flex justify-center">
      <div className="date-selection flex-col">
        <div className="text-lg flex justify-center">
          <button onClick={handlePreviousWeek} className="mr-8">
            <IoIosArrowBack />
          </button>
          {activeDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
          <button onClick={handleNextWeek} className="ml-8">
            <IoIosArrowForward />
          </button>
        </div>
        <div className="weekdays mt-2 mb-4 grid grid-cols-7 gap-4 justify-items-center">{renderWeekdays()}</div>
        <div className="dates mb-8 grid grid-cols-7 gap-4 justify-items-center">{renderDates()}</div>
      </div>
    </div>
  );
};

export default DateSelect;

import {checkCardInfoParams, setCardInfo} from "../authRequests";
import {useReducer, useState, useEffect, useRef, useMemo} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {MdDeleteForever, MdModeEditOutline} from "react-icons/md";
import {
  crud,
  getPaymentMethods,
  setDefaultPaymentMethods,
  createPaymentMethod,
  detachPaymentMethods,
} from "../crudRequests";

export default function CardInfoFormLink() {
  const [pid, setPid] = useState('""');
  const [db, setDb] = useState("");
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(true);
  const [jwt, setJwt] = useState("");
  const [pk, setPk] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(function () {
    let params = window.location.href.split("?params=")[1];
    checkCardInfoParams(params, "patient")
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setPid(response.data.pid);
        setDb(response.data.db);
        setLogo(response.data.logo);
        setJwt(response.data.token);
        setPk(loadStripe(response.data.stpPublicKey || "pk_test_"));
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "../login";
      });
  }, []);

  const appearance = {
    theme: "stripe",
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".Label": {color: "#A7A7A7", fontSize: "13px"},
      ".Error": {color: "#f87171", fontSize: "12px"},
      ".Tab:hover": {
        color: "var(--colorText)",
      },

      // See all supported class names and selector syntax below
    },
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#A7A7A7",
      colorDanger: "#df1b41",
      borderRadius: "6px",
      // See all possible variables below
    },
  };
  return (
    <div
      className={
        "bg-[#FFF] relative h-full w-full overflow-y-hidden flex justify-center items-center"
      }
    >
      {loading && (
        <div className="absolute z-10 inset-0 flex justify-center items-center bg-white">
          <div className="flex justify-center align-middle">
            <img src="/images/loader.gif" className="h-36" />
          </div>
        </div>
      )}

      <div
        className={
          "w-[33rem] bg-gray-100 shadow-lg border rounded-lg h-[90%] overflow-hidden flex justify-center items-center flex-col"
        }
      >
        <div
          className={
            "flex h-full overflow-y-hidden sbar2   flex-col justify-center items-center align-middle py-8 px-8 mx-auto"
          }
        >
          <div className="flex flex-1 justify-center items-center  h-full flex-col overflow-y-hidden">
            {successMessage && (
              <div className="mx-auto flex justify-center items-center w-full">
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Card Updated
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200 font-medium">
                        Card information has been updated successfully.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {errorMessage && (
              <div className="mx-auto flex justify-center items-center w-full">
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-red-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-red-500 dark:text-red-400">
                        Error
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200 font-medium">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {
              <div>
                <div>
                  <img
                    src={logo || "/images/blhlogo.png"}
                    className=" w-[15rem] mx-auto relative"
                    alt="BrightLight Logo"
                  />
                </div>

                <div class="text-[14px] mb-2 text-[#A7A7A7] text-justify space-y-2 px-5 mt-4">
                  <p className="font-normal">
                    Please enter in a credit card as a payment method prior to
                    your medical appointment for security purposes.
                  </p>
                  <p className="font-semibold">
                    You won’t be charged anything until after you have your
                    appointment.
                  </p>
                </div>
              </div>
            }
            <div className="flex-1 sbar2 overflow-y-scroll w-full">
              <div className="px-4 mt-4 p-2 h-full w-full ">
                {pk && (
                  <Elements
                    stripe={pk}
                    options={{
                      mode: "setup",
                      currency: "cad",
                      paymentMethodCreation: "manual",
                      appearance,
                      locale: "en",
                    }}
                  >
                    <CheckoutForm
                      setSuccessMessage={setSuccessMessage}
                      patient={{pid}}
                      setErrorMessage={setErrorMessage}
                      dashState={{jwt, db}}
                    />
                  </Elements>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckoutForm({
  setErrorMessage,
  setSuccessMessage,
  patient,
  dashState,
  location,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [addressReady, setAddressReady] = useState(false);
  const [paymentReady, setPaymentReady] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      var addressElementRef = elements.getElement("address");
      var paymentElement = elements.getElement("payment");
      // console.log(elements);
      setIsLoading(true);
      setSuccessMessage("");
      setErrorMessage("");
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      await elements.submit();

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        elements,
      });
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      } else if (paymentMethod) {
        createPaymentMethod(
          dashState,
          patient.pid,
          paymentMethod.id,
          location?.lid
        )
          .then((res) => {
            setErrorMessage("");
            setIsLoading(false);
            addressElementRef.clear();
            paymentElement.clear();
            setSuccessMessage(true);
          })
          .catch((e) => {
            setErrorMessage(e.response?.data || e.message);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      console.log(error);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    terms: {card: "never"},
  };

  return (
    <form id="payment-form sbar2 h-full" onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => {
          setPaymentReady(true);
        }}
        onLoadError={(e) => {
          setErrorMessage(e.error?.message);
        }}
        options={CARD_ELEMENT_OPTIONS}
        className="card-element flex flex-col justify-start py-4 items-center w-full "
      />
      {elements?.getElement("payment") && (
        <AddressElement
          onReady={() => {
            setAddressReady(true);
          }}
          onLoadError={(e) => {
            setErrorMessage(e.error.message);
            console.log(e);
          }}
          className="card-element flex flex-col justify-start py-4 items-center"
          options={{mode: "billing", autocomplete: {mode: "automatic"}}}
        />
      )}
      {paymentReady && addressReady && (
        <div className="flex justify-center items-center mt-6 w-full ">
          <button
            className={`${
              isLoading || !stripe || !elements ? "cursor-not-allowed" : ""
            } w-full bg-gray-800 flex justify-center items-center cursor-pointer text-white text-[1rem] rounded-lg py-3 px-2 text-center font-semibold disabled:bg-gray-500 disabled:cursor-not-allowed`}
            value="Submit"
            disabled={isLoading || !stripe || !elements}
          >
            Save Payment Method
            {isLoading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      )}
    </form>
  );
}

import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import FormsPDF from "./FormsPDF";
import {pdf} from "@react-pdf/renderer";
import {Box, Button, Center, Flex, Heading, Spinner, useToast} from "@chakra-ui/react";
import {crudStorageUploadTempForm, submitForm, validateUrlTempForms} from "../authRequests";


export default function TempForm() {
  const [data, setData] = useState(null);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)

  const toast = useToast();

  const validateUrlAndGetData = async(params) => {
    try {
      const response = await validateUrlTempForms(params)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  useEffect(() => {
    const fetchFormData = async () => {
      setLoading(true)
      const params = window.location.href.split("?params=")[1]
      const data = await validateUrlAndGetData(params)
      if (data?.formData) {
        setData(data);
        setForm(JSON.parse(data.formData.formFields))
        setFormValues(JSON.parse(data.formData.formFields))
      } else {
        setErrorMessage(data)
      }
      setLoading(false)
    };

    fetchFormData();
  }, []);

  const renderElement = (obj) => {
    const {element, key, props = {}, children} = obj;

    if (children && !Array.isArray(children)) {
      return React.createElement(element, {key, ...props}, children);
    }

    const childElements = children
      ? children.map((child, index) =>
          renderElement({...child, key: child.key || index})
        )
      : null;

    return React.createElement(element, {key, ...props}, childElements);
  }

  function handleInputChange(
    rowIdx,
    colIdx,
    value,
    isCheckbox = false,
    checkboxIndex = -1,
    file
  ) {
    setFormValues((prevFormValues) => {
      const updatedFormValues = {...prevFormValues};
      const formElement = updatedFormValues[rowIdx][colIdx];

      if (
        formElement.element === "input" &&
        formElement.props.type !== "checkbox" &&
        formElement.props.type !== "file"
      ) {
        formElement.props.value = value;
      } else if (formElement.element === "textarea") {
        formElement.props.value = value;
      } else if (formElement.element === "select") {
        formElement.props.value = value;
      } else if (formElement.element === "div" && formElement.children) {
        formElement.children.forEach((child) => {
          if (
            child.element === "input" &&
            child.props.type !== "checkbox" &&
            child.props.type !== "file"
          ) {
            child.props.value = value;
          } else if (child.element === "textarea") {
            child.props.value = value;
          } else if (child.element === "select") {
            child.props.value = value;
          } else if (child.element === "input" && child.props.type === "file") {

            if (file) {
              const reader = new FileReader();
              reader.onload = function (event) {
                const base64Content = event.target.result.split(",")[1];
                const fileFields = {
                  fieldId: uuidv4(),
                  fileName: file.name,
                  content: base64Content,
                };
                child.props.fileFields = [fileFields];
              };
              reader.readAsDataURL(file);
            }
          } else if (child.element === "div" && child.children) {
            child.children.forEach((grandchild) => {
              if (
                grandchild.element === "input" &&
                grandchild.props.type !== "checkbox" &&
                grandchild.props.type !== "file"
              ) {
                grandchild.props.value = value;
              } else if (grandchild.element === "textarea") {
                grandchild.props.value = value;
              } else if (grandchild.element === "select") {
                grandchild.props.value = value;
              }
            });
          }
        });
        if (isCheckbox && formElement.children) {
          const checkboxElement =
            formElement.children[1].children[checkboxIndex].children[0];
          checkboxElement.props.checked = !checkboxElement.props.checked;
        }
      }
      return updatedFormValues;
    });
  }

  async function completeForm() {
    setIsSubmitting(true)
    let payload = {
      fsid: data.formData.fsid,
      pid: data.formData.pid,
      fid: data.formData.fid,
      formTitle: data.formData.formTitle,
      createdDate: data.formData.createdDate,
      assignedDate: data.formData.assignedDate,
      createdBy: data.formData.createdBy,
      assignedBy: data.formData.assignedBy,
      formFields: JSON.stringify(formValues),
      completed: true,
      completedDate: new Date(),
    };

    toast.promise(submitForm(data.db, data.formData.fsid, payload).then(() => {
      uploadFile()
      setIsSubmitting(false);
      setIsDisabled(true);
    }), {
      success: {
        title: "Success",
        description: "Form submitted successfully!",
      },
      error: {
        title: "Error",
        description: "Error submitting form",
      },
      loading: {title: "Submitting form...", description: "Please wait"},
    })
  }

  async function uploadFile() {
    try {
      let blob = await pdf(
        <FormsPDF data={data.formData} form={formValues} state={{locations: data.locations, selectedLocation: data.location}} returnDoc />
      ).toBlob();
      await crudStorageUploadTempForm(
        new File(
          [blob],
          "prefix_" +
            new Date().getTime() +
            "-" +
            blob.size / 1000 +
            "-" +
            data.formData.formTitle +
            ".pdf"
        ),
        "brightlighthealth",
        data.formData.pid + "/Form_Submissions",
        data.fsid,
        data.org
      );

    } catch (error) {
      console.log(error);
    }
  }

  function getCheckboxIndex(event) {
    let parentDiv = event.target.parentElement;
    while (parentDiv && parentDiv.tagName.toLowerCase() !== "div") {
      parentDiv = parentDiv.parentElement;
    }

    if (parentDiv) {
      const children = parentDiv.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.contains(event.target)) {
          return i;
        }
      }
    }

    return -1;
  }
  return (
    <Box>
      {loading
        ?
          <Flex
            position="absolute"
            top="0"
            left="240px"
            right="0"
            bottom="0"
            justifyContent="center"
            alignItems="center"
            bg="rgba(255, 255, 255, 0.8)"
            zIndex="1"
          >
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Flex>
        :
        errorMessage
          ?
            <Box w={'100vw'} h={'100vh'}>
              <Center w={'100vw'} h={'100vh'}>
                <Heading as='h2' size='3xl' noOfLines={1}>
                  {errorMessage}
                </Heading>
              </Center>
            </Box>
          : 
            <Box w={'100%'} height={'100%'} p={20} bg={'#f6f6f6'}>
              <Box>
                {Object.keys(form)?.map((rowIdx) => {
                  return (
                    <div
                      key={rowIdx}
                      className={`grid space-x-4 mb-2 px-4 grid-cols-${
                        Object.keys(form[rowIdx]).length
                      }`}
                    >
                      {Object.keys(form[rowIdx])?.map((colIdx) => {
                        const element = form[rowIdx][colIdx];
                        const {key, props = {}} = element;

                        return (
                          <Box
                            key={colIdx}
                            className={`grid space-x-4 mb-2 px-4 grid-cols-${Object.keys(form[rowIdx]).length}`}
                          >
                            {element &&
                              renderElement({
                                ...element,
                                props: {
                                  ...props,
                                  onChange: (e) => {
                                    const isCheckbox =
                                      e.target.type === "checkbox";
                                    const checkboxIndex = isCheckbox
                                      ? getCheckboxIndex(e)
                                      : -1;
                                    handleInputChange(
                                      rowIdx,
                                      colIdx,
                                      e.target.value,
                                      isCheckbox,
                                      checkboxIndex,
                                      e.target.files?.[0]
                                    );
                                  },
                                },
                              })}
                          </Box>
                        );
                      })}
                    </div>
                  );
                })}
              </Box>
              <Center>
                <Button
                isLoading={isSubmitting}
                isDisabled={isDisabled}
                type="button"
                className="focus:ring-0 w-[20rem] py-2 rounded-lg font-medium bg-off text-white my-5 mt-7 block mx-auto"
                onClick={completeForm}
                >
                  Complete Form{" "}
                </Button>
              </Center>
            </Box>
      }
    </Box>
  );
}

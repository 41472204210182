import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import Navbar from "../components/Patient.Navbar";
import Notifications from "../components/Patient.Notifications";
import Chat from "../components/Chat";
import {patientReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import AppointmentsList from "../components/AppointmentsList";
import SelfSchedule from "../components/SelfSchedule";
import PaymentModal from "../components/payment/PaymentModal";
import swal from "sweetalert";
import Layout from "../components/Layout";
import {crud} from "../crudRequests";
import InvRecPDF from "../components/InvRecPDF";
import {MdClose, MdPayment} from "react-icons/md";

import {
  Heading,
  Text,
  Box,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  Icon,
  TableCaption,
  Fade,
} from "@chakra-ui/react";
import {IoIosCheckmarkCircle} from "react-icons/io";

export default function Payments(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const [showSelfSch, setShowSelfSch] = useState(false);
  const [printInvoiceOrReciept, setPrintInvoiceOrReciept] = useState("");
  const [executePayment, setExecutePayment] = useState("");
  const [totalOutstanding, setTotalOutstanding] = useState(0);

  const selectedAppointment = useRef(null);
  selectedAppointment.current = dashState.selectedAppointment;

  const sessionStart = useRef(true);
  let room = dashState.patient?.pid;

  const [payments, setPayments] = useState([]);

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  const socket = useSocket("https://blh-server-2001610adcd9.herokuapp.com/", room, dispatch);
  //const socket = useSocket("http://localhost:3000/", room, dispatch);

  useEffect(() => {
    if (dashState.patient && !sessionStart.current) {
      loadDataForPatientLogin(
        {
          ...dashState,
          destination: "../payments",
        },
        navigate
      );
    }
  }, []);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  useEffect(function () {
    crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: [{pid: dashState?.patient?.pid}],
        method: "find",
      },
    ])
      .then((res) => {
        setPayments(res?.data[0].reverse());
        //console.log(res?.data[0]);
      })
      .catch(function (error) {
        console.log("client error", error.message);
        if (error.code !== "ERR_CANCELED") window.location.href = "../login";
      });
  }, []);

  useEffect(
    function () {
      setTotalOutstanding(
        payments.reduce((acc, payment) => {
          const difference = payment.amount - payment.paid;
          return acc + difference;
        }, 0)
      );
    },
    [payments]
  );

  let doctorBytransaction = null;

  if (printInvoiceOrReciept && dashState) {
    let index = dashState?.doctorsIndexMap?.[printInvoiceOrReciept.did];
    doctorBytransaction = dashState?.doctors[index];
  }

  return (
    <>
      <Layout state={{...dashState}}>
        <Fade in={dashState} transition={{enter: {duration: 0.5}}}>
          <Heading>
            {dashState?.patient?.fName}, your outstanding balance is{" "}
            <span>${totalOutstanding.toFixed(2)}</span>
          </Heading>
          <Text mt="3">
            {totalOutstanding > 0
              ? "Please pay all pending invoices at the earliest."
              : "You are all set."}
          </Text>
          <TableContainer
            p="4"
            w="5xl"
            mt="8"
            border="1px solid"
            borderColor="gray.300"
            bg="gray.50"
          >
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Service Name</Th>
                  <Th>Date</Th>
                  <Th>Clinician</Th>
                  <Th>Total Amount</Th>
                  <Th>Patient Amount</Th>
                  <Th>Outstanding Balance</Th>
                  <Th>Paid</Th>
                  <Th>Print</Th>
                  <Th>Pay</Th>
                </Tr>
              </Thead>
              <Tbody>
                {payments.map((payment, i) => {
                  return (
                    <Tr key={i}>
                      <Td maxW="100px" isTruncated>
                        {payment.serviceType}
                      </Td>
                      <Td>{new Date(payment.serviceDate).toDateString()}</Td>
                      <Td>{payment.dName}</Td>
                      <Td fontWeight="medium">
                        ${parseFloat(payment.amount).toFixed(2)}
                      </Td>
                      <Td fontWeight="medium">
                        ${parseFloat(payment?.patientAmount).toFixed(2)}
                      </Td>
                      <Td fontWeight="medium">
                        $
                        {parseFloat(
                          payment?.patientAmount - payment?.amountPaidByPatient
                        ).toFixed(2)}
                      </Td>
                      <Td>
                        {payment?.patientPaymentStatus === "paid" ? (
                          <Icon as={IoIosCheckmarkCircle} fontSize="20px" />
                        ) : (
                          <Text color="red.500">Pending</Text>
                        )}
                      </Td>
                      <Td>
                        <a
                          onClick={() => setPrintInvoiceOrReciept(payment)}
                          className="text-blue-500 cursor-pointer font-medium"
                        >
                          {payment?.patientPaymentStatus === "paid"
                            ? `Print Reciept`
                            : `Print Invoice`}
                        </a>
                      </Td>
                      <Td>
                        {payment?.patientAmount -
                          payment?.amountPaidByPatient <=
                        0 ? (
                          <Icon as={IoIosCheckmarkCircle} fontSize="20px" />
                        ) : (
                          <a
                            onClick={() => {
                              setExecutePayment(payment);
                            }}
                            className="text-blue-500 cursor-pointer font-medium"
                          >
                            <MdPayment className="inline text-lg" />
                          </a>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              {payments.length == 0 && (
                <TableCaption>
                  You have no transactions to display.
                </TableCaption>
              )}
            </Table>
          </TableContainer>
        </Fade>
      </Layout>
      {printInvoiceOrReciept && (
        <div
          className="fixed z-[70] inset-0 w-full h-screen outline-none overflow-x-hidden overflow-y-auto"
          style={{backgroundColor: "rgb(0,0,0,0.5)"}}
        >
          <div className="relative flex items-end justify-center min-h-screen px-4 pt-4 pb-24 text-center sm:block sm:p-0">
            (
            <div className="absolute inset-0 realtive bg-white/80 flex z-10 items-center justify-center min-h-screen">
              <div className="h-[95%] w-[50rem] relative flex justify-center items-center p-10 bg-white rounded-lg">
                {
                  <MdClose
                    className="text-black cursor-pointer text-lg absolute z-20 right-4 top-4 "
                    onClick={() => {
                      setPrintInvoiceOrReciept(null);
                    }}
                  />
                }
                <InvRecPDF
                  state={dashState}
                  transaction={printInvoiceOrReciept}
                  invoice={
                    printInvoiceOrReciept?.patientPaymentStatus !== "paid"
                  }
                  doctor={doctorBytransaction}
                />
              </div>
            </div>
            )
          </div>
        </div>
      )}
      {executePayment && (
        <PaymentModal
          {...{
            socket,
            dashState,
            dispatch,
            transactionData: executePayment,
            setExecutePayment,
            setPayments,
          }}
        />
      )}
    </>
  );
}

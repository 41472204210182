export function updateConversation(context, setContext) {
  const newConversationEntry = {
    preface: context.currentPreface,
    question: context.currentQuestion,
    response: context.currentResponse,
    time: new Date(),
  };

  context.conversation = context.conversation
    ? [...context.conversation, newConversationEntry]
    : [newConversationEntry];

  setContext({...context});
}

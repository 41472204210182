import {Container, Box, Text, Flex, Image, Avatar} from "@chakra-ui/react";
import {getCurrentTime} from "./helpers/timeFormatters";

export default function Conversation({context}) {
  return (
    <Box
      mt="-4"
      w={"600px"}
      h={"80vh"}
      bg="white"
      overflowY={"scroll"}
      px="4"
      py="6"
      boxShadow={"lg"}
      position="relative"
    >
      {context?.conversation ? (
        context?.conversation?.map((conversation, key) => (
          <Container key={key}>
            <Flex alignItems={"end"}>
              <Avatar
                size={"sm"}
                name={"Question"}
                bg="gray.700"
                color="white"
              />
              <Text
                m={"10px"}
                ml={"7px"}
                mb={"-4px"}
                py={"6px"}
                px={"6px"}
                fontSize={"sm"}
                bg={"gray.200"}
                borderRadius={"xl"}
                maxW={"75%"}
              >
                <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                  {conversation.preface ? (
                    <>
                      {conversation.preface}
                      <br />
                      <br />
                      {conversation.question}
                    </>
                  ) : (
                    conversation.question
                  )}
                </Text>
                <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                  {getCurrentTime(conversation.time)}
                </Text>
              </Text>
            </Flex>

            <Flex justifyContent={"flex-end"} mt="2">
              <Text
                mb={"-2.5px"}
                m={"3px"}
                py={"6px"}
                px={"6px"}
                fontSize={"sm"}
                bg={"#4185F0"}
                color={"white"}
                borderRadius={"xl"}
                maxW={"75%"}
              >
                <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                  {conversation.response}
                </Text>

                <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                  {getCurrentTime(conversation.time)}
                </Text>
              </Text>
            </Flex>
          </Container>
        ))
      ) : (
        <Text
          textAlign={"center"}
          fontSize={"20px"}
          lineHeight={"6"}
          fontWeight={"regular"}
          my="6"
          color="gray.700"
        >
          No answered questions to display
        </Text>
      )}
    </Box>
  );
}

export function updateSymptomsDetected(context, predictedSymptoms, setContext) {
  // Create a new array based on predictedSymptoms and updates existing ones
  const updatedSymptoms = predictedSymptoms.map((pS) => {
    const existingSymptom = context?.symptomsDetected?.find(
      (s) => s.symptom === pS
    );

    const conversationEntry = {
      preface: context.currentPreface,
      question: context.currentQuestion,
      response: context.currentResponse,
      time: new Date(),
    };

    if (existingSymptom) {
      // Update existing symptom with new conversation
      return {
        ...existingSymptom,
        conversation: [...existingSymptom.conversation, conversationEntry],
      };
    } else {
      // Add new symptom
      return {
        symptom: pS,
        conversation: [conversationEntry],
      };
    }
  });

  // Append symptoms that were in the context but not in predictedSymptoms
  const oldSymptomsNotUpdated =
    context?.symptomsDetected?.filter(
      (s) => !predictedSymptoms.some((pS) => pS === s.symptom)
    ) || [];

  context.symptomsDetected = [...updatedSymptoms, ...oldSymptomsNotUpdated];

  setContext({...context});
}

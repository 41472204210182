import {FaUserAlt} from "react-icons/fa";
import {FaPlus} from "react-icons/fa";
import {IoMdSend} from "react-icons/io";
import {BsViewList} from "react-icons/bs";
import {lightFormat, isSameDay} from "date-fns";
import {chatReducer, initialChatState} from "../additional_files/chatReducer";

import {
  Text,
  Tabs,
  TabList,
  Tab,
  Avatar,
  AvatarBadge,
  Input,
  Box,
  Flex,
  Icon,
  Container,
  Tooltip,
} from "@chakra-ui/react";

import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
} from "react";

import {useChatSocket} from "../additional_files/custom";
import {crud} from "../crudRequests";

const monthsNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const shortWeekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function Chat({dashState, dashDispatch}) {
  const [state, dispatch] = useReducer(chatReducer, initialChatState);
  let {conversations, rooms, selectedChat, usersStatus, writing} = state;
  let {doctors, doctorsIndexMap, patient} = dashState;

  const sendRef = useRef(null);
  const docListRef = useRef(null);
  const writingRef = useRef(null);
  const chatEndRef = useRef(null);
  let dateMarkgRef = 0;

  docListRef.current = state.docList;

  const socket = useChatSocket(
    "https://blh-server-2001610adcd9.herokuapp.com/chat",
    //"http://localhost:3000/chat",
    rooms,
    dashState.patient,
    dispatch,
    dashDispatch,
    dashState
  );

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    if (dashState.patient) {
      crud(
        dashState,
        [
          {
            db: dashState.db,
            collection: "conversations",
            parameters: [
              {conversationId: {$in: dashState.patient?.chatsId || []}},
            ],
            method: "find",
          },
        ],
        null,
        abortController.signal
      )
        .then((res) => {
          dispatch({type: "UPDATE_STATE", payload: res.data[0] || []});
        })
        .catch((error) => {
          console.log(error.message);
          // if (error.code !== "ERR_CANCELED") window.location.href = "../login";
        });
    }
  }, [dashState.patient]);

  useLayoutEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"}); // Scroll to bottom of chat history
  }, [selectedChat]);

  useLayoutEffect(() => {
    chatEndRef.current?.scrollIntoView({behavior: "smooth"}); // Scroll to bottom of chat history

    if (writing) {
      writingRef.current = setTimeout(() => {
        dispatch({
          type: "WRITING",
          payload: null,
        });
      }, 4000);
    }

    return () => {
      clearTimeout(writingRef.current);
    };
  }, [writing]);

  useEffect(() => {
    const closeList = () => {
      docListRef.current && dispatch({type: "SHOW_DOCTORS_LIST"});
    };

    const send = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter")
        sendRef.current.click();
    };
    window.addEventListener("keydown", send);
    window.addEventListener("click", closeList);
    return () => {
      window.removeEventListener("keydown", send);
      window.removeEventListener("click", closeList);
    };
  }, []);

  return (
    <>
      {conversations.length > 0 && (
        <Tabs>
          <TabList>
            {conversations.map((c, i) => (
              <Tab
                key={c.usersId[1]}
                onClick={() => {
                  dispatch({
                    type: "CHANGE_CHAT",
                    payload: conversations[i],
                  });
                }}
              >
                {" "}
                <Tooltip
                  label={doctors[doctorsIndexMap[c.usersId[1]]].name}
                  placement="right"
                >
                  <Avatar
                    src={doctors[doctorsIndexMap[c.usersId[1]]].photo}
                    name={doctors[doctorsIndexMap[c.usersId[1]]].name}
                  >
                    <AvatarBadge
                      boxSize="0.75em"
                      bg={
                        usersStatus[c.usersId[1]] === "connected"
                          ? "green.300"
                          : "gray.400"
                      }
                    />
                  </Avatar>
                </Tooltip>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )}
      {conversations.length > 0 ? (
        <Box h="60vh" bg="white" p="2" overflowY="scroll" pb={"5"}>
          {selectedChat.messages.map((m, i) => {
            let cDate = new Date(m.timestamp);
            let mark = null;

            if (!isSameDay(cDate, dateMarkgRef)) {
              dateMarkgRef = new Date(m.timestamp);
              mark = (
                <Box key={conversations.length + i}>
                  <Text textAlign={"center"} fontSize={"10px"} py={"35px"}>
                    {`${
                      new Date().getFullYear() == cDate.getFullYear()
                        ? shortWeekday[cDate.getDay()] + ", "
                        : ""
                    } ${monthsNames[cDate.getMonth()]} ${cDate.getDate()}${
                      new Date().getFullYear() != cDate.getFullYear()
                        ? ", " + cDate.getFullYear()
                        : ""
                    }`}
                  </Text>
                </Box>
              );
            }

            return (
              <Container maxW={"100%"}>
                {mark}
                {m.senderId === dashState.patient.pid ? (
                  <Flex key={i} justifyContent={"flex-end"}>
                    <Text
                      mb={"-2.5px"}
                      m={"3px"}
                      py={"6px"}
                      px={"6px"}
                      fontSize={"sm"}
                      bg={"#4185F0"}
                      color={"white"}
                      borderRadius={"xl"}
                      maxW={"50%"}
                    >
                      <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                        {m.message}
                      </Text>

                      <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                        {lightFormat(new Date(m.timestamp), "HH:mm")}
                      </Text>
                    </Text>
                  </Flex>
                ) : (
                  <Flex key={i} alignItems={"end"} spa>
                    <Avatar
                      size={"sm"}
                      src={
                        doctors[doctorsIndexMap[selectedChat.usersId[1]]].photo
                      }
                      name={
                        doctors[doctorsIndexMap[selectedChat.usersId[1]]].name
                      }
                    >
                      <AvatarBadge
                        boxSize="0.75em"
                        bg={
                          usersStatus[selectedChat.usersId[1]] === "connected"
                            ? "green.300"
                            : "gray.400"
                        }
                      />
                    </Avatar>
                    <Text
                      m={"10px"}
                      ml={"7px"}
                      mb={"-4px"}
                      py={"6px"}
                      px={"6px"}
                      fontSize={"sm"}
                      bg={"gray.200"}
                      borderRadius={"xl"}
                      maxW={"50%"}
                    >
                      <Text pt={"4px"} pr={"30px"} pl={"6px"}>
                        {m.message}
                      </Text>
                      <Text fontSize={"8px"} mt={"-6px"} textAlign={"right"}>
                        {lightFormat(new Date(m.timestamp), "HH:mm")}
                      </Text>
                    </Text>
                  </Flex>
                )}
              </Container>
            );
          })}
          {writing && (
            <Box ml={"16px"} mt={"20px"} fontSize={"xs"} color={"grey.300"}>
              <Text as={"i"}>
                {writing.chatId === selectedChat?.conversationId &&
                  writing.userId !== patient.pid &&
                  (doctors[doctorsIndexMap[selectedChat.usersId[1]]]?.name ||
                    "The clinician ") + " is typing..."}
              </Text>
            </Box>
          )}
          <Box ref={chatEndRef} />
        </Box>
      ) : (
        "No clinician has initiated a chat session with you yet."
      )}
      {conversations.length > 0 && (
        <Flex
          alignItems="center"
          bg={"white"}
          borderTop={"1px"}
          borderColor={"gray.100"}
        >
          <Box w="full">
            <Input
              variant="unstyled"
              placeholder="Type Message"
              p="5"
              onChange={(e) => {
                setInputValue(e.target.value);
                socket.current.emit("writing", {
                  chatId: selectedChat.conversationId,
                  userId: dashState.patient?.pid,
                });
              }}
              value={inputValue}
            />
          </Box>
          <Box
            ref={sendRef}
            onClick={() => {
              if (conversations.length > 0 && inputValue.trim()) {
                chatEndRef.current?.scrollIntoView({behavior: "smooth"}); // Scroll to bottom of chat history

                let conversation = selectedChat;
                let newMessage = {
                  db: dashState.db,
                  conversationId: conversation.conversationId,
                  senderId: dashState.patient.pid,
                  messageType: "text",
                  message: inputValue,
                  timestamp: new Date().toISOString(),
                };
                socket.current.emit("message", newMessage);
                setInputValue("");
                dispatch({
                  type: "NEW_MESSAGE",
                  payload: newMessage,
                });
              }
            }}
          >
            <Icon
              as={IoMdSend}
              mr="4"
              fontSize="xl"
              cursor={inputValue != "" ? "pointer" : ""}
              color={inputValue != "" ? "black" : "gray.300"}
            />
          </Box>
        </Flex>
      )}
    </>
  );
}

import {updateConversation} from "./updateConversation";
import {acknowledge} from "./acknowledge";
import {predictDisorders} from "./predictDisorders";
import {predictSymptoms} from "./predictSymptoms";
import {updateSymptomsDetected} from "./updateSymptomsDetected";
import {updateSymptomsToTriage} from "./updateSymptomsToTriage";
import {finishIntake} from "./finishIntake";
import {getNextQuestion} from "./getNextQuestion";

export async function processFlow(
  dashState,
  setIsLoading,
  disorders,
  symptoms,
  qualifierQuestions,
  durationQuestions,
  followupQuestions,
  context,
  setContext,
  toast
) {
  if (context) {
    if (context.currentResponse) {
      setIsLoading(true);

      updateConversation(context, setContext);

      const acknowledgement = await acknowledge(context);
      const predictedDisorders = await predictDisorders(context, disorders);
      console.log("Qualified Differentials", predictedDisorders);
      const [predictedSymptoms, potentialSymptoms] = await predictSymptoms(
        context,
        symptoms,
        predictedDisorders
      );
      console.log("Qualified Symptoms", predictedSymptoms);

      updateSymptomsDetected(context, predictedSymptoms, setContext);

      let symptomsToTriage = updateSymptomsToTriage(
        context,
        predictedSymptoms,
        potentialSymptoms
      );
      console.log("Symptoms To Triage", symptomsToTriage);

      if (
        context.currentQuestion ===
          "Is there anything else you'd like to share?" &&
        symptomsToTriage.length == 0
      ) {
        // No more symptoms to triage & nothing new detected from last fully open question
        finishIntake(dashState, disorders, context, setContext);
      } else {
        getNextQuestion(
          context,
          symptomsToTriage,
          acknowledgement,
          qualifierQuestions,
          durationQuestions,
          followupQuestions,
          setContext
        );
        console.log("New Symptoms Detected", context.symptomsDetected);
      }
      setIsLoading(false);
    } else {
      toast({
        title: "Please enter a response.",
        description: "You must respond in order to move to a new question",
        status: "error",
        isClosable: true,
      });
    }
  } else {
    // Fresh Start
    setContext({
      ...context,
      currentPreface:
        "Welcome to the Brightlight Intake. Our intelligent algorithms and workflows gather information on your issues to better assist the clinicians with an evaluation.",
      currentQuestion:
        "In your own words, please tell us your chief complaint.",
    });
  }
}

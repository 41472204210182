import {promptAI} from "./promptAI";

export async function predictSymptoms(context, symptoms, predictedDisorders) {
  let potentialSymptoms = predictedDisorders.flatMap((pd) =>
    symptoms.filter((s) => s.associatedDisorders.includes(pd.did))
  );

  let prompt =
    'If a patient said "' +
    context.currentResponse +
    '" in response to "' +
    context.currentQuestion +
    '", which of the following symptoms are they experiencing? Provide one or multiple answers from the following list: ' +
    potentialSymptoms
      .map((s, i) => s.name.trim())
      .filter((n) => n)
      .join(", ") +
    '. Only list the most relevant symptom(s) based on what was said that they are DIRECTLY experiencing/going through. Do not make anything up if you are uncertain. If the patient has said something positive, neutral or unrelated then answer "This patient doesnt need to be screened for any of the listed symptoms".';

  const predictedSymptomsString = await promptAI(
    "You are a clinical decision support system",
    prompt
  );

  const predictedSymptoms = potentialSymptoms.filter((s) =>
    predictedSymptomsString.data.output.includes(s.name.trim())
  );

  return [
    predictedSymptoms.sort((a, b) => a.priority - b.priority),
    potentialSymptoms.sort((a, b) => a.priority - b.priority),
  ];
}
